import React from 'react';
import List from '../component/list';
import { componentName} from '../component/Constant';

const WTCoreSubjectives = () => {
    return (
        <List componentName={componentName.wtCoreSubjective}/>
    )
}

export default WTCoreSubjectives
