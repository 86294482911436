import { combineReducers, configureStore } from '@reduxjs/toolkit'
import appReducer from './slices/appSlice'
import userReducer from './slices/userSlice'
import authReducer from './slices/authSlice'
import classReducer from './slices/classSlice'
import pupilReducer from './slices/pupilSlice'
import teacherReducer from './slices/teacherSlice'
import moderateReducer from './slices/moderateSlice'
import createSagaMiddleware from 'redux-saga'
import rootSagas from '../sagas/rootSagas'
import schoolReducer from './slices/schoolSlice'
import taxonomieReducer from './slices/taxonomieSlice'
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from 'connected-react-router'
import history from "../history";
import { connectRouter } from 'connected-react-router'
import lessonReducer from './slices/lessonSlice'
import lessonSubmissionReducer from './slices/lessonSubmissionSlice'

const sagaMidddleware = createSagaMiddleware()

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

const reducers = combineReducers({
    app: appReducer,
    user: userReducer,
    auth: authReducer,
    school: schoolReducer,
    pupil: pupilReducer,
    teacher: teacherReducer,
    class: classReducer,
    lesson: lessonReducer,
    lessonSubmission: lessonSubmissionReducer,
    taxonomie: taxonomieReducer,
    moderate: moderateReducer,
    router: connectRouter(history)
})

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMidddleware, routerMiddleware(history)]
})
sagaMidddleware.run(rootSagas)
