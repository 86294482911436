import React, {useEffect, useState} from "react";
import Nav from "../../component/Nav/index";
import Routes from "../../routes";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import sideMenu from "../../sideMenu.json";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";

function AdminDashboard() {
  const [t] = useTranslation();
  const {sideMenuAdmin, sideMenuSchoolAdmin} = sideMenu;

  const role = useSelector((state) => state.auth?.user?.roles?.[0]?.name);
// const menuData =(role === "admin" ?sideMenuAdmin:sideMenuSchoolAdmin).map((menu, index) => (
//      <Link to={menu.path} key={menu.id}>{t(menu.name)}</Link>))
  const [subMenuOpen, setSubMenuOpen] = useState('');
  const url = useLocation().pathname;
  const activeMenu = (role === "admin" ? sideMenuAdmin : sideMenuSchoolAdmin)

  useEffect(() => {
    for (let menu of activeMenu) {
      if (menu.dropdown) {
        for (let submenu of menu.dropdown) {
          if (submenu.path === url) {
            setSubMenuOpen(menu.id)
          }
        }
      }
    }
  }, [url, activeMenu])

  const menuData = activeMenu.map(
    (menu, index) => {
      return menu.dropdown ? (
        <>
          <div className="link" key={menu.id} onClick={() => subMenuOpen === menu.id ? setSubMenuOpen('') : setSubMenuOpen(menu.id)}>
            {t(menu.name)}{" "}
            <FontAwesomeIcon icon={subMenuOpen === menu.id ? faChevronUp : faChevronDown} className="float-right pt-1" />
            <div className={subMenuOpen === menu.id ? "d-block inner-menu" : "d-none"}>
              {menu.dropdown.map((dropdownlist, i) => {
                return (
                  <Link to={dropdownlist.path} key={dropdownlist.id} className={dropdownlist.path === url ? 'active': ''}>
                    {t(dropdownlist.name)}{" "}
                  </Link>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <Link to={menu.path} key={menu.id} className={menu.path === url ? 'active': ''}>
          {t(menu.name)}{" "}
        </Link>
      );
    }
  );

  return (
    <div className="container-fluid">
      <Nav/>
      <div className="row">
        <div className="col-4 sidebar-col">
          <div className="container padding-80">
            {menuData}
          </div>
        </div>
        <div className="col-8">
          <Routes/>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard;
