import {Link} from "react-router-dom";
import {React} from "react";
import TeacherCreateForm from "../../component/Forms/TeacherCreateForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {createTeacher} from "../../redux/slices/teacherSlice";
import {useTranslation} from "react-i18next";

const TeacherCreate = ({id}) => {
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const [t, ] = useTranslation()
  const dispatch = useDispatch()

  const submit = (first_name,last_name, email, password, passwordConfirmation) => {
    dispatch(createTeacher({
      first_name,last_name, email, password, password_confirmation: passwordConfirmation, school_id: id
    }))
  }
  
  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
              <Link to={`/schools/${id}`}>
                <button className="btn btn-primary">{arrow}</button>
              </Link>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t('Create teacher')}</h4>
            </div>
            <div className="col-3 "></div>
          </div>
        </div>
        <div className="card-body">
          <TeacherCreateForm submit={submit} schoolId={id} submitTitle={t('Create')} />
        </div>
      </div>
    </div>
  )
}

export default TeacherCreate;
