import { toast } from 'react-toastify';
/*SHOWING NOTIFY MESSAGES: START */
let toastId;
function closeToast() {
    toastId = null;
}
export function notify(notifyType, message, autoClose) {
    autoClose = autoClose ? true : false;
    if (toastId) {
        if (notifyType === "error") {
            toast.update(toastId, { render: message, type: toast.TYPE.ERROR, onClose: closeToast });
        }
        else {
            toast.update(toastId, { render: message, type: toast.TYPE.SUCCESS, onClose: closeToast });
        }
    }
    else {
        if (notifyType === "error") {
            if (autoClose) {
                toastId = toast.error(message, { onClose: closeToast, autoClose: 5000 });

            } else {
                toastId = toast.error(message, { onClose: closeToast });
            }
        }
        else {
            toastId = toast.success(message, { onClose: closeToast });
        }
    }
}
/*SHOWING NOTIFY MESSAGES: END */