import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye , faEyeSlash} from "@fortawesome/free-solid-svg-icons";

const CreateTeacherForm = ({ submit, submitTitle }) => {
  const [t] = useTranslation();
  const selectedUser = useSelector((state) => state.user.selectedUser);

  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  
  useEffect(() => {
    if (selectedUser) {
      setfirst_name(selectedUser.first_name || "");
      setlast_name(selectedUser.last_name || "");
      setEmail(selectedUser.email || "");
    }
  }, [selectedUser]);

  const submitState = (e) => {
    e.preventDefault();
    submit(first_name,last_name, email, password, passwordConfirm );
  };

  return (
    <form onSubmit={submitState}>
      <div className="row">
        <div className="col-12">
          {/* <Errors /> */}
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="first_name" className="col-form-label">
            {t("First Name")}
          </label>
          <input
            id="first_name"
            type="text"
            className="form-control "
            name="first_name"
            required
            value={first_name}
            onChange={(e) => setfirst_name(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="last_name" className="col-form-label">
            {t("Last Name")}
          </label>
          <input
            id="last_name"
            type="text"
            className="form-control "
            name="last_name"
            required
            value={last_name}
            onChange={(e) => setlast_name(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="email" className="col-form-label">
            {t("Email")}
          </label>
          <input
            id="email"
            type="text"
            className="form-control "
            name="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="password" className="col-form-label">
            {t("Password")}
          </label>
          <div className="input-group mb-3">
          <input
            id="password"
            type={showPassword ? 'text' : 'password'}
            className="form-control "
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
             <div className="input-group-append">
              <span className="input-group-text"><FontAwesomeIcon  icon={showPassword ? faEyeSlash : faEye} onClick={()=>setshowPassword(!showPassword)}/></span>
            </div>
          </div>
          
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="password_confirm" className="col-form-label">
            {t("Confirm password")}
          </label>
          <div className="input-group mb-3">
          <input
            id="password_confirm"
            type={showConfirmPassword ? 'text' : 'password'}
            className="form-control "
            name="password_confirm"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
           <div className="input-group-append">
              <span className="input-group-text"><FontAwesomeIcon  icon={showConfirmPassword ? faEyeSlash : faEye} onClick={()=>setshowConfirmPassword(!showConfirmPassword)}/></span>
            </div>
          </div>
        </div>
        <div className="col-12 text-right">
          <button type="submit" className="btn btn-secondary">
            {submitTitle}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateTeacherForm;
