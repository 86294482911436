import React from "react";
import { Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { ProgressBar, Step } from "react-step-progress-bar";
import { GalleryImageContainer } from "../../component"
import {faDownload, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CreateLessonStep1({ lessonName, setLessonName, description, setDescription, image, setImage, setStep, isEdit=false, imageUrl,ErrorName,files,setFiles}) {
  const [t,] = useTranslation();
  const download = <FontAwesomeIcon icon={faDownload} />;
  const deletIcon=<FontAwesomeIcon icon={faTrashAlt} />;
  const fileSelectedHandler = (e) => {
    setFiles([...files, e.target.files[0]])
  }
  const RemoveFile=(name)=>{
   let newFiles=files.filter((data)=> data.name!==name)
    setFiles(newFiles)
  }
  
  return (
    <div className="mt-5">
        <>
          <h4 className="modal-title d-flex justify-content-center mb-4">{t('Nieuwe les maken')}</h4>
          <ProgressBar percent={0}>
            <Step>{({ accomplished, index }) => (<div className={`indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</div>)}</Step>
            <Step>{({ accomplished, index }) => (<div className={`indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</div>)}</Step>
            <Step>{({ accomplished, index }) => (<div className={`indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</div>)}</Step>
          </ProgressBar>
        </>
        
      <div className="row mt-4">
      {ErrorName && <div className="alert alert-danger w-100" role="alert">{ErrorName}</div>}
        <div className="col-12 mb-2">
          <label htmlFor="lessonName" className="col-form-label">
            {t('Lesson Name')}
          </label>
          <input
            id="lessonName"
            type="text"
            className="form-control "
            name="lessonName"
            required
            value={lessonName}
            onChange={(e) => setLessonName(e.target.value)}
          />
        </div>
        <div className="col-12 mb-2">
          <label htmlFor="description" className="col-form-label">
            {t('Description')}
          </label>
          <textarea
            id="description"
            type="text"
            className="form-control "
            name="description"
            required
            value={description || ""}
            rows="4"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        {
             isEdit && imageUrl ?             
            <>
              <div className="col-12 mb-2"> 
                <GalleryImageContainer imgUrl={imageUrl} />
              </div>         
            </> : null
          }
        <div className="col-12 mb-2">
          <div><label  className="col-form-label">
            {t('Image')}
          </label>
          </div>
          <label htmlFor="image" className="col-form-label btn btn-primary mr-4">
            {t('Image')}
          </label>{image?.name}
          <input
            id="image"
            type="file"
            className="d-none"
            name="image"
            accept="image/jpg, image/bmp, image/png, image/gif, image/jpeg"
            required
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>

        <div className="col-12 mb-2">
          <div>
            <label htmlFor="Attachments" className="col-form-label">
              {t('Attachments')}
            </label>
          </div>
          <label htmlFor="files" className="col-form-label btn btn-primary mr-4">
            {t('Upload file')}
          </label>
          <input
            id="files"
            type="file"
            className=" d-none"
            name="files"
            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            multiple
            onChange={fileSelectedHandler}
          />
          {files.map((data,i)=> <div key={i} className="m-2"> <span  className="color-grayish text-underline">{download} {data.name || "geen naam"} </span><sup role="button" onClick={()=>RemoveFile(data.name)}>{deletIcon}</sup></div> )}
        </div>
      </div>
      <div className="d-flex justify-content-around mb-5 mt-3">
        <Button variant="outline-primary" disabled={ !lessonName ? true : false} onClick={() => setStep(2)} data-toggle="tooltip" data-placement="top" title={ !lessonName ? t('disable_step') : "" }  >{t('Next')}</Button>
      </div>
    </div>
  )
}
export default CreateLessonStep1;
