import {createAction, createSlice} from '@reduxjs/toolkit'
const initialState = {
    taxonomies: [],
    allTaxonomies: [],
    selectedTaxonomie: {},
    lessonsWithTaxonomies: {},
    lessonsWithTaxonomy: {},
    lessonsWithTaxonomySearch: { taxonomyType: false, startDate: '', endDate: '' },
    page: 1,
    totalItems: 0,
}

export const taxonomieSlice = createSlice({
    name: 'taxonomie',
    initialState,
    reducers: {
        taxonomieSelected: (state, action) => {
            state.selectedTaxonomie = action.payload
        },
        selectionCleared: (state) => {
            state.selectedTaxonomie = {}
        },
        pageChanged: (state, action) => {
            state.page = action.payload
        },
        totalItemsChanged: (state, action) => {
            state.totalItems = action.payload
        },
        taxonomieDeleted: (state, action) => {
            state.taxonomies = state.taxonomies.filter(taxonomie => taxonomie.id !== action.payload)
        },
        taxonomiesFetched: (state, action) => {
            state.taxonomies = action.payload
        },
        allTaxonomiesFetched: (state, action) => {
            state.allTaxonomies = action.payload
        },
        lessonsWithTaxonomies: (state, action) => {
            state.lessonsWithTaxonomies = action.payload
        },
        lessonsWithTaxonomy: (state, action) => {
            state.lessonsWithTaxonomy = action.payload
        },
        lessonsWithTaxonomySearch: (state, action) => {
            state.lessonsWithTaxonomySearch = action.payload
        }
    }
})

export const { 
    taxonomieSelected,
    selectionCleared,
    taxonomiesFetched,
    allTaxonomiesFetched,
    pageChanged,
    totalItemsChanged,
    taxonomieDeleted,
    lessonsWithTaxonomies,
    lessonsWithTaxonomy,
    lessonsWithTaxonomySearch
} = taxonomieSlice.actions
export const createTaxonomie = createAction('taxonomie/create')
export const updateTaxonomie = createAction('taxonomie/update')
export const fetchTaxonomies = createAction('taxonomie/fetchPaged')
export const fetchAllTaxonomies = createAction('taxonomie/fetchAll')
export const selectTaxonomie = createAction('taxonomie/select')
export const deleteTaxonomie = createAction('taxonomie/delete')
export const fetchLessonsWithTaxonomies = createAction('taxonomie/fetchLessons')
export const fetchLessonsWithTaxonomy = createAction('taxonomie/fetchLessonsForTaxonomy')
export const setLessonsWithTaxonomySearch = createAction('taxonomie/setLessonsForTaxonomySearch')


export default taxonomieSlice.reducer
