import React, {useState,useEffect,useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {logout} from "../../redux/slices/authSlice";
import logoImage from './img/logo.png'
import logoImagestyle from './img/logo2.png'
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faSearch} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import navMenu from "./navMenu.json"
import { LogoHexagonalContainer } from "../../component";
if(process.env.REACT_APP_STYLE === "style-1"){
  require("./style.scss");
}else{
  require("./style2.scss");
}


const Nav = () => {
  const loggedIn = useSelector(state => state.auth.loggedIn)
  const userName = useSelector(state => state.auth.user?.name)
  const role = useSelector((state) => state.auth?.user?.roles?.[0]?.name);
  const user = useSelector((state) => state.auth.user);
  const [dropDown, setDropDown] = useState(false)
  const dispatch = useDispatch()
  const [t,] = useTranslation()

  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClickOutside=(event)=> {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setDropDown(false)
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [wrapperRef, dropDown]);

  const {
    // menuForAdminOrSchoolAdmin,
    menuForTeacher} = navMenu;

  // const Search= <FontAwesomeIcon icon={faSearch}/>;
  const logoutClicked = (e) => {
    e.preventDefault();
    dispatch(logout())
  }

  const showDropdownMenu = () =>{
    setDropDown(!dropDown)
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sto-navbar">
      <div className={(role==="admin"?"padding-80 container-fluid":"px-0 container")}>
        <a className="logo logo-link" href="/">
          <LogoHexagonalContainer width="150px" height="200px" imgUrl={process.env.REACT_APP_STYLE !== "style-1" ? logoImagestyle : logoImage} />
        </a>
        { loggedIn && role === "pupil" &&
          <div className="profile-card bg-primary">
            <p className="username"><strong>{userName}</strong></p>
            <p className="classname">{user?.pupil?.reference} | {user?.pupil?.classes && user.pupil.classes[0] ? user.pupil.classes[0].name : ""}</p>
          </div>
        }

        { loggedIn && role === "teacher" &&
          <div className="profile-card">
            <p className="username"><strong>{userName}</strong></p>
            <p className="classname">{t('lecturer')}</p>
          </div>
        }

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={wrapperRef}>
          <ul className="navbar-nav ml-auto">
            {loggedIn ?
               (<ul className="navbar-nav ml-auto">
                    <li className={dropDown?"nav-item dropdown show":"nav-item dropdown"}>
                        <div  id="navbarDropdown" className="nav-link dropdown-toggle font-weight-bold" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={dropDown?"true":"false"} v-pre="" onClick={showDropdownMenu}>
                           {userName}
                        </div>

                        <div className={dropDown?"dropdown-menu dropdown-menu-right show":"dropdown-menu dropdown-menu-right"} aria-labelledby="navbarDropdown">
                          {role==="teacher"?
                            menuForTeacher.map((menu,i)=>(
                              <Link key={menu.path} className="dropdown-item " to={menu.path?menu.path:"/lessons"}>{t(menu.name)}</Link>
                            ))
                            :""
                            }
                            <a href="/profile" className="dropdown-item">{t('Profile')}</a>
                            {role ==="pupil" &&
                              <Link className="dropdown-item " to={"/grant-access"}>{t("Grant access")}</Link>
                            }
                            <a role="button" href="/" className="dropdown-item" onClick={logoutClicked}>
                              {t('Logout')}
                            </a>
                        </div>
                    </li>
              </ul>
            ) : (
              <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  {t('Login')}
                </a>
              </li>
              </ul>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
