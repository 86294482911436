import { createAction, createSlice } from '@reduxjs/toolkit'
const initialState = {
    schools: [],
    allSchools: [],
    selectedSchool: {},
    page: 1,
    totalItems: 0,
}

export const schoolSlice = createSlice({
    name: 'school',
    initialState,
    reducers: {
        schoolSelected: (state, action) => {
            state.selectedSchool = action.payload
        },
        selectionCleared: (state) => {
            state.selectedSchool = {}
        },
        pageChanged: (state, action) => {
            state.page = action.payload
        },
        totalItemsChanged: (state, action) => {
            state.totalItems = action.payload
        },
        schoolDeleted: (state, action) => {
            state.schools = state.schools.filter(school => school.id !== action.payload)
        },
        schoolsFetched: (state, action) => {
            state.schools = action.payload
        },
        allSchoolsFetched: (state, action) => {
            state.allSchools = action.payload
        }
    }
})

export const { schoolSelected, selectionCleared, schoolsFetched, allSchoolsFetched, pageChanged, totalItemsChanged, schoolDeleted } = schoolSlice.actions
export const createSchool = createAction('school/create')
export const updateSchool = createAction('school/update')
export const fetchSchools = createAction('school/fetchPaged')
export const fetchAllSchools = createAction('school/fetchAll')
export const selectSchool = createAction('school/select')
export const deleteSchool = createAction('school/delete')


export default schoolSlice.reducer