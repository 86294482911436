import { Fragment, React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMapMarker,
  faTrash,
  faArrowLeft,
  faUnlink,
  faLink,
  faExternalLinkSquareAlt,
  faDownload,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { selectSchool } from "../../redux/slices/schoolSlice";
import { Link } from "react-router-dom";
import {
  deletePupil,
  fetchPupils,
  uploadPupils,
} from "../../redux/slices/pupilSlice";
import {
  fetchClasses,
  deleteClass,
  switchArchive,
} from "../../redux/slices/classSlice";
import {
  fetchTeachers,
  deleteTeacher,
  uploadTeachers,
} from "../../redux/slices/teacherSlice";
import Api from "../../lib/Api";
import { formatDate } from "../../i18n/i18n";
import { Dialog } from "../../component";
import EditPupilAndUser from "../pupil/EditPupilAndUser";

const SchoolInfo = ({ id }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const plus = <FontAwesomeIcon icon={faPlus} />;
  const map = <FontAwesomeIcon icon={faMapMarker} />;
  const trash = <FontAwesomeIcon icon={faTrash} />;
  const move = <FontAwesomeIcon icon={faExternalLinkSquareAlt} />;
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const unlink = <FontAwesomeIcon icon={faUnlink} />;
  const link = <FontAwesomeIcon icon={faLink} />;
  const pupils = useSelector((state) => state.pupil.pupils);
  const classes = useSelector((state) => state.class.classes);
  const teachers = useSelector((state) => state.teacher.teachers);
  const download = <FontAwesomeIcon icon={faDownload} />;
  const upload = <FontAwesomeIcon icon={faUpload} />;

  const selectedSchool = useSelector((state) => state.school.selectedSchool);
  const archive = useSelector((state) => state.class.archive);
  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [houseNumberSuffix, setHouseNumberSuffix] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [ShowEditPupilAndUser, setShowEditPupilAndUser] = useState("");
  const [selectedPupil, setSelectedPupil] = useState("");

  useEffect(() => {
    dispatch(selectSchool(id));
    dispatch(fetchClasses(id));
    dispatch(fetchTeachers(id));
    dispatch(fetchPupils(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (selectedSchool) {
      setName(selectedSchool.name || "");
      setStreet(selectedSchool.street || "");
      setHouseNumber(selectedSchool.houseNumber || "");
      setHouseNumberSuffix(selectedSchool.houseNumberSuffix || "");
      setPostcode(selectedSchool.postcode || "");
      setCity(selectedSchool.city || "");
      setPhone(selectedSchool.phoneNumber || "");
      setWebsiteUrl(selectedSchool.websiteUrl || "");
    }
  }, [selectedSchool]);

  const deleteClassClicked = (classId) => {
    const doDelete = window.confirm(
      t("Are you sure that you want to delete this class?")
    );
    if (doDelete) {
      dispatch(deleteClass({ schoolId: id, classId: classId }));
    }
  };

  const unlinkTeacherClicked = (teacherId) => {
    const doDelete = window.confirm(
      t("Are you sure that you want to unlink this teacher?")
    );
    if (doDelete) {
      dispatch(deleteTeacher({ schoolId: id, teacherId: teacherId }));
    }
  };

  const unlinkPupilClicked = (pupilId) => {
    const doDelete = window.confirm(
      t("Are you sure that you want to unlink this pupil?")
    );
    if (doDelete) {
      dispatch(deletePupil({ schoolId: id, pupilId: pupilId }));
    }
  };

  const downloadTeachers = (id) => {
    Api.get(`/api/schools/${id}/download-teachers`).then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `teachers.csv`);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  const getEditPupilAndUser = (pupil) => {
    setSelectedPupil(pupil);
    setShowEditPupilAndUser(true);
  };

  const downloadPupils = (id) => {
    Api.get(`/api/schools/${id}/download-pupils`).then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `pupils.csv`);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  const uploadTeachersExcel = () => {
    document.getElementById("teachersExcelUpload").click();
  };

  const handleTeachersExcel = (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(uploadTeachers({ schoolId: id, formData: formData }));
  };

  const uploadPupilsExcel = () => {
    document.getElementById("pupilsExcelUpload").click();
  };

  const handlePupilsExcel = (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(uploadPupils({ schoolId: id, formData: formData }));
  };

  return (
    <div className="container padding-80">
      <Dialog
        isVisible={ShowEditPupilAndUser}
        setIsVisible={setShowEditPupilAndUser}
        size={"md"}
      >
        <EditPupilAndUser
          setIsVisible={setShowEditPupilAndUser}
          pupil={selectedPupil}
          schoolId={id}
        />
      </Dialog>
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
              <Link to="/schools">
                <button className="btn btn-sm plus-button">{arrow}</button>
              </Link>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{name}</h4>
            </div>
            <div className="col-3 text-right"></div>
          </div>
        </div>
        <div className="card-body px-5">
          <h4 className="mb-4">{t("School details")}</h4>
          <div className="row">
            <div className="col-6">
              <div className="card_view h-100">
                <div className="school-info">
                  <p className="mb-2 add_details">
                    {t("Name")}: {name}
                  </p>
                  <p className="mb-2 add_details">
                    {t("Phone")}: {phone}
                  </p>
                  <p className="mb-2 add_details">
                    {t("Website")}: {websiteUrl}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card_view">
                <h5 className="mb-3">
                  {t("Address")} {map}
                </h5>
                <div className="school-address">
                  <p className="mb-2 add_details">
                    {t("Street")}: {street}
                  </p>
                  <p className="mb-2 add_details">
                    {t("House Number")}: {houseNumber}
                  </p>
                  <p className="mb-2 add_details">
                    {t("Suffix")}: {houseNumberSuffix}
                  </p>
                  <p className="mb-2 add_details">
                    {t("Postcode")}: {postcode}
                  </p>
                  <p className="mb-2 add_details">
                    {t("Place")}: {city}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-5">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-4"></div>
            <div className="col-4">
              <h4 className="mb-0">{t("Class List")}</h4>
            </div>
            <div className="col-4 text-right">
              <div
                className="btn-toolbar d-flex justify-content-end"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div
                  className="btn-group archive-group"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    className={
                      archive
                        ? "btn btn-sm btn-primary"
                        : "btn btn-sm btn-primary active"
                    }
                    onClick={() => {
                      dispatch(switchArchive(false));
                    }}
                  >
                    {t("Active")}
                  </button>
                  <button
                    type="button"
                    className={
                      archive
                        ? "btn btn-sm btn-primary active"
                        : "btn btn-sm btn-primary"
                    }
                    onClick={() => {
                      dispatch(switchArchive(true));
                    }}
                  >
                    {t("Archive")}
                  </button>
                </div>
                &nbsp;
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Action group"
                >
                  <Link to={"/schools/" + id + "/classes/create"}>
                    <button className="btn btn-sm plus-button">{plus}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-5">
          {classes?.length ? (
            <table className="table table-hover">
              <thead>
                <tr>
                  {["Name", "Start date", "End date", "Actions"].map((h) => (
                    <th key={h}>{t(h)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(classes || []).map((c) => (
                  <tr key={"class-" + c.id}>
                    <td>
                      <Link to={`/schools/${id}/classes/${c.id}`}>
                        {c.name}
                      </Link>
                    </td>
                    <td>{formatDate(c.start_date)}</td>
                    <td>{formatDate(c.end_date)}</td>
                    <td>
                      <button
                        className="btn btn-danger btn-sm ml-auto btn-action"
                        onClick={() => deleteClassClicked(c.id)}
                      >
                        {trash}
                      </button>{" "}
                      <Link to={`/schools/${id}/classes/${c.id}/pupils/move`}>
                        <button className="btn btn-secondary btn-sm ml-auto btn-action">
                          {move}
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{t("No classes found")}</p>
          )}
        </div>
      </div>

      <div className="card mt-5">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-2"></div>
            <div className="col-6">
              <h4 className="mb-0">{t("Teacher List")}</h4>
            </div>
            <div className="col-4 text-right">
              <input
                type="file"
                style={{ display: "none" }}
                id="teachersExcelUpload"
                onChange={(e) => {
                  handleTeachersExcel(e);
                }}
              />
              <button
                className="btn btn-sm btn-info mr-1"
                onClick={() => uploadTeachersExcel()}
              >
                {upload}
              </button>
              <button
                className="btn btn-sm btn-success mr-1"
                onClick={() => downloadTeachers(id)}
              >
                {download}
              </button>
              <Link to={"/schools/" + id + "/teacher/attach"}>
                <button className="btn btn-sm plus-button">{link}</button>
              </Link>
              <Link to={"/schools/" + id + "/teacher/create"}>
                <button className="btn btn-sm plus-button">{plus}</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body px-5">
          {teachers?.length ? (
            <table className="table table-hover">
              <thead>
                <tr>
                  {["Name", "Email", "Actions"].map((h) => (
                    <th key={h}>{t(h)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(teachers || []).map((t) => (
                  <tr key={"teacher-" + t.id}>
                    <td>{t.user?.name}</td>
                    <td>{t.user?.email}</td>
                    <td>
                      <button
                        className="btn btn-danger btn-sm ml-auto"
                        onClick={() => unlinkTeacherClicked(t.id)}
                      >
                        {unlink}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{t("No teachers found")}</p>
          )}
        </div>
      </div>

      <div className="card mt-5">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-2"></div>
            <div className="col-6">
              <h4 className="mb-0">{t("Pupil List")}</h4>
            </div>
            <div className="col-4 text-right">
              <input
                type="file"
                style={{ display: "none" }}
                id="pupilsExcelUpload"
                onChange={(e) => {
                  handlePupilsExcel(e);
                }}
              />
              <button
                className="btn btn-sm btn-info mr-1"
                onClick={() => uploadPupilsExcel()}
              >
                {upload}
              </button>
              <button
                className="btn btn-sm btn-success mr-1"
                onClick={() => downloadPupils(id)}
              >
                {download}
              </button>
              <Link to={`/schools/${id}/pupils/attach`}>
                <button className="btn btn-sm plus-button">{link}</button>
              </Link>
              <Link to={"/schools/" + id + "/pupils/create"}>
                <button className="btn btn-sm plus-button">{plus}</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body px-5">
          {pupils?.length ? (
            <table className="table table-hover">
              <thead>
                <tr>
                  {["Name", "Pupil reference", "Email", "Actions"].map((h) => (
                    <th key={h}>{t(h)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(pupils || []).map((pupil) => (
                  <tr key={"pupil-" + pupil.id}>
                    <td>
                      {pupil.user && pupil.user?.name && (
                        <Fragment>
                          {pupil.user.name}
                        </Fragment>
                      )}
                    </td>
                    <td>{pupil.reference}</td>
                    <td>{pupil.user?.email}</td>
                    <td>
                      <div className="d-flex">
                        <button className="btn btn-secondary btn-sm mr-2"  onClick={() => getEditPupilAndUser(pupil)}>
                          {move}
                        </button>
                        <button
                          className="btn btn-sm btn-danger "
                          onClick={() => unlinkPupilClicked(pupil.id)}
                        >
                          {unlink}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{t("No pupils found")}</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default SchoolInfo;
