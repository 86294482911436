import { createAction, createSlice } from '@reduxjs/toolkit'
const initialState = {
    pupils: [],
    page: 1,
    totalItems: 0,
    portFolio: [],
    pupilDetails: false,
    portfolioDetails: {},
    portfolioImages: [],
    loader:false
}
export const pupilSlice = createSlice({
    name: 'pupil',
    initialState,
    reducers: {
        pageChanged: (state, action) => {
            state.page = action.payload
        },
        totalItemsChanged: (state, action) => {
            state.totalItems = action.payload
        },
        pupilDeleted: (state, action) => {
            state.pupil = state.pupils.filter(user => user.id !== action.payload)
        },
        pupilsFetched: (state, action) => {
            state.pupils = action.payload
        },
        portFolioFetched: (state, action) => {
            state.portFolio = action.payload
        },
        portFolioPageChanged: (state, action) => {
            state.portFolioPage = action.payload
        },
        portFolioTotalItemsChanged: (state, action) => {
            state.portFolioTotalItems = action.payload
        },
        pupilDetails: (state, action) => {
            state.pupilDetails = action.payload
        },
        portfolioDetails: (state, action) => {
            state.portfolioDetails = action.payload
        },
        loadingStarted:(state)=>{
            state.loader=true;
        },
        loadingStopped:(state)=>{
            state.loader=false;
        },
        portfolioImagesFetched: (state, action) => {
            state.portfolioImages = action.payload
        }
    }
})

export const { pupilsFetched, pageChanged, totalItemsChanged, pupilDetails, pupilDeleted, portFolioFetched, portFolioPageChanged, portFolioTotalItemsChanged, portfolioDetails,loadingStarted,loadingStopped, portfolioImagesFetched } = pupilSlice.actions

export const createPupil = createAction('pupil/create')
export const updatePupil = createAction('pupil/update')
    // please put the school id in the payload
export const fetchPupils = createAction('pupil/fetch-of-school')
export const deletePupil = createAction('pupil/delete')
export const attachPupils = createAction('pupil/attach')
export const attachPupilsToSchool = createAction('pupil/attach/school')
export const fetchPupilsDashboard = createAction('pupil/dashboard')
export const createPortfolio = createAction('portfolio-items/create')
export const fetchPupilsLessons = createAction('pupil/lesson')
export const fetchPupilsPortfolioItem = createAction('pupil/portfolio-items')
export const fetchPupilDetails = createAction('pupil/pupil-details')
export const fetchPortfolioDetails = createAction('portfolio-items/details')
export const transferPupils = createAction('pupil/transfer')
export const updatePupilReference = createAction('pupil/update-reference')
export const downloadPortfolio = createAction('pupil/download-portfolio')
export const resetPupilsLessons = createAction('pupil/reset')
export const resetPortFolio = createAction('pupil/portfolio/reset')
export const uploadPupils = createAction('pupil/upload')
export const fetchPortfolioImages = createAction('pupil/portfolio-items/images')
export const grantAccess = createAction('pupil/grant-access')

export default pupilSlice.reducer
