import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import SubjectList from "./subject";
import ThemesList from "./themes";
import GradeList from "./grades";
import BetaMentalitiesList from "./betaMentalities";
import  CenturySkillsList from "./centurySkills";
import  CoreDisciplinesList from "./coreDisciplines";
import  disciplinesList from "./disciplines";
import  ProfessionalOrientationList from "./professionalOrientations";
import  TargetGroupList from "./targetGroup";
import WorldOfEngineeringList  from "./worldsOfEngineering";
import WTCoreSubjectives  from "./wtCoreSubjectives";
import TaxonomieCreate from './component/Create';
import TaxonomieEdit from './component/Edit';
import { taxonomiesRoutes } from './component/Constant';

const Taxonomies = () => {
    return (
        <Switch>
            <Route path={taxonomiesRoutes.subject} component={SubjectList}/>
            <Route path={taxonomiesRoutes.targetGroup} component={TargetGroupList}/>
            <Route path={taxonomiesRoutes.discipline} component={disciplinesList}/>
            <Route path={taxonomiesRoutes.coreDiscipline} component={CoreDisciplinesList}/>
            <Route path={taxonomiesRoutes.worldsOfEngineering} component={WorldOfEngineeringList}/>
            <Route path={taxonomiesRoutes.betaMentalitie} component={BetaMentalitiesList}/>
            <Route path={taxonomiesRoutes.professionalOrientation} component={ProfessionalOrientationList}/>
            <Route path={taxonomiesRoutes.wtCoreSubjective} component={WTCoreSubjectives}/>
            <Route path={taxonomiesRoutes.centurySkill} component={CenturySkillsList}/>
            <Route path={taxonomiesRoutes.theme} component={ThemesList}/>
            <Route path={taxonomiesRoutes.grade} component={GradeList}/>
            <Route path="/taxonomies/:page/create" exact component={({match}) => <TaxonomieCreate page={match.params.page}/>}/>  
            <Route path="/taxonomies/:page/edit/:id" exact component={({match}) => <TaxonomieEdit id={match.params.id} page={match.params.page}/>}/>     
            <Redirect to={taxonomiesRoutes.subject} from="*"/>
        </Switch>
    )
}

export default Taxonomies
