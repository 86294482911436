import React from "react";
import { Route, Switch } from "react-router-dom";
import UserList from "./pages/user/UserList";
import UserEdit from "./pages/user/UserEdit";
import UserCreate from "./pages/user/UserCreate";
import SchoolList from "./pages/school/SchoolList";
import SchoolCreate from "./pages/school/SchoolCreate";
import SchoolEdit from "./pages/school/SchoolEdit";
import SchoolInfo from "./pages/school/SchoolInfo";
import ClassCreate from "./pages/class/ClassCreate";
import ClassInfo from "./pages/class/ClassInfo";
import TeacherCreate from "./pages/teacher/TeacherCreate";
import PupilCreate from "./pages/pupil/PupilCreate";
import { useSelector} from "react-redux";
import PupilAttachClass from "./pages/pupil/PupilAttachClass";
import PupilTransfer from "./pages/pupil/PupilTransfer";
import TeacherAttachClass from "./pages/teacher/TeacherAttachClass";
import PupilAttachSchool from "./pages/pupil/PupilAttachSchool";
import TeacherAttachSchool from "./pages/teacher/TeacherAttachSchool";
import LessonList from "./pages/teacher/LessonList";
import ClassList from "./pages/teacher/ClassList";
import ModererenList from "./pages/teacher/ModererenList";
import PupilList from "./pages/pupil/PupilList";
import { ToastContainer } from 'react-toastify';
import Taxonomies from "./pages/taxonomies";
import ClassDetails from "./pages/teacher/ClassDetails";
import LessonDetails from "./pages/teacher/LessonDetails";
import ModerateList from "./pages/moderate/ModerateList";
import Reports from "./pages/admin/reports";
import EditLesson from "./pages/teacher/EditLesson";
import CreateLessonPopup from "./pages/teacher/CreateLessonPopup";
import CreatePortfolioPopup from "./pages/pupil/CreatePortfolioPopup";
import PortfolioDetails from "./pages/pupil/PortfolioDetails";
import 'react-toastify/dist/ReactToastify.css';
import NotFoundPage from "./pages/not-found";
import TeacherLessonSubmission from "./pages/teacher/TeacherLessonSubmission";
import PupilLessonSubmission from "./pages/pupil/PupilLessonSubmission";
import PortfolioImages from "./pages/pupil/portfolioImages";
import Profile from "./pages/user/Profile";
import GrantAccess from "./pages/pupil/GrantAccess";

function Routes() {
    const role = useSelector((state) => state.auth?.user?.roles?.[0]?.name);
    return (
      <div className="main-col">
        <ToastContainer autoClose={false}/>
        {role==="admin" &&
        <Switch>
          <Route exact path="/" component={SchoolList}/>
          <Route exact path="/schools" component={SchoolList}/>
          <Route exact path="/schools/edit/:id" component={({match}) => <SchoolEdit id={match.params.id}/>}/>
          <Route exact path="/schools/create" component={SchoolCreate}/>
          <Route exact path="/schools/:id" component={({match}) => <SchoolInfo id={match.params.id}/>}/>
          <Route exact path="/schools/:id/classes/create" component={({match}) => <ClassCreate id={match.params.id}/>}/>
          <Route exact path="/schools/:schoolId/classes/:classId/pupils/attach" component={({match}) => <PupilAttachClass schoolId={match.params.schoolId} classId={match.params.classId}/>}/>
          <Route exact path="/schools/:schoolId/classes/:classId/pupils/move" component={({match}) => <PupilTransfer schoolId={match.params.schoolId} classId={match.params.classId}/>}/>
          <Route exact path="/schools/:schoolId/classes/:classId/teachers/attach" component={({match}) => <TeacherAttachClass schoolId={match.params.schoolId} classId={match.params.classId}/>}/>
          <Route exact path="/schools/:id/pupils/attach" component={({match}) => <PupilAttachSchool schoolId={match.params.id}/>}/>
          <Route exact path="/schools/:schoolId/classes/:classId" component={({match}) => <ClassInfo schoolId={match.params.schoolId} classId={match.params.classId}/>}/>
          <Route exact path="/schools/:id/teacher/create" component={({match}) => <TeacherCreate id={match.params.id}/>}/>
          <Route exact path="/schools/:id/teacher/attach" component={({match}) => <TeacherAttachSchool schoolId={match.params.id}/>}/>
          <Route exact path="/schools/:id/pupils/create" component={({match}) => <PupilCreate schoolId={match.params.id} />}/>
          <Route exact path="/users" component={UserList}/>
          <Route exact path="/users/edit/:id" component={({match}) => <UserEdit id={match.params.id}/>}/>
          <Route exact path="/users/create" component={UserCreate}/>
          <Route path="/taxonomies" component={Taxonomies}/>
          <Route exact path="/moderates" component={ModerateList}/>
          <Route exact path="/reports" component={Reports}/>
          <Route exact path="/profile" component={() => <Profile />} />
          <Route component={() => <NotFoundPage />} />
        </Switch>
        }

        {role==="school_admin" &&
        <Switch>
          <Route exact path="/" component={SchoolList}/>
          <Route exact path="/schools" component={SchoolList}/>
          <Route exact path="/schools/edit/:id" component={({match}) => <SchoolEdit id={match.params.id}/>}/>
          <Route exact path="/schools/create" component={SchoolCreate}/>
          <Route exact path="/schools/:id" component={({match}) => <SchoolInfo id={match.params.id}/>}/>
          <Route exact path="/schools/:id/classes/create" component={({match}) => <ClassCreate id={match.params.id}/>}/>
          <Route exact path="/schools/:schoolId/classes/:classId/pupils/attach" component={({match}) => <PupilAttachClass schoolId={match.params.schoolId} classId={match.params.classId}/>}/>
          <Route exact path="/schools/:id/pupils/attach" component={({match}) => <PupilAttachSchool schoolId={match.params.id}/>}/>
          <Route exact path="/schools/:schoolId/classes/:classId" component={({match}) => <ClassInfo schoolId={match.params.schoolId} classId={match.params.classId}/>}/>
          <Route exact path="/schools/:id/teacher/create" component={({match}) => <TeacherCreate id={match.params.id}/>}/>
          <Route exact path="/schools/:id/teacher/attach" component={({match}) => <TeacherAttachSchool schoolId={match.params.id}/>}/>
          <Route exact path="/schools/:id/pupils/create" component={({match}) => <PupilCreate schoolId={match.params.id} />}/>
          <Route exact path="/moderates" component={ModerateList}/>
          <Route exact path="/profile" component={() => <Profile />} />
          <Route component={() => <NotFoundPage marginTop={true} />} />
        </Switch>
        }
        {
          role==="teacher" &&
          <Switch>
            <Route exact path="/" component={LessonList}/>
            <Route exact path="/lessons" component={LessonList}/>
            <Route exact path="/classes" component={ClassList}/>
            <Route exact path="/modereren" component={ModererenList}/>
            <Route exact path="/class-details/:id" component={({match}) => <ClassDetails id={match.params.id}/>}/>
            <Route exact path="/lesson-create" component={CreateLessonPopup} />
            <Route exact path="/lesson-details/:id" component={({match}) => <LessonDetails id={match.params.id}/>}/>
            <Route exact path="/lesson/:lessonId/submission/:submissionId"
                   component={({match}) => <TeacherLessonSubmission />}
            />
            <Route exact path="/edit-lesson/:id" component={({match}) => <EditLesson id={match.params.id}/>}/>
            <Route exact path="/pupil/:id" component={({match}) => <PupilList id={match.params.id}/>}/>
            <Route exact path="/profile" component={() => <Profile />} />
            <Route component={() => <NotFoundPage marginTop={true} />} />
          </Switch>
        }
         {
          role==="pupil" &&
          <Switch>
            <Route exact path="/" component={PupilList}/>
            <Route exact path="/pupil/:id" component={({match}) => <PupilList id={match.params.id}/>}/>
            <Route exact path="/lesson-details/:id" component={({match}) => <LessonDetails id={match.params.id}/>}/>
            <Route exact path="/portfolio-create" component={CreatePortfolioPopup}/>
            <Route exact path="/portfolio-details/:id" component={({match}) => <PortfolioDetails id={match.params.id}/>}/>
            <Route exact path="/lesson/:lessonId/submission"
                   component={({match}) => <PupilLessonSubmission />}
            />
            <Route exact path="/:pupilId/portfolio-items/images" component={({match}) => <PortfolioImages id={match.params.pupilId}/>} />
            <Route exact path="/profile" component={() => <Profile />} />
            <Route exact path="/grant-access" component={() => <GrantAccess />} />
            <Route component={() => <NotFoundPage marginTop={true} />} />
          </Switch>
        }

      </div>
    );
}

export default Routes
