import React from "react";
import { Modal } from "react-bootstrap";

const Dialog = ({ isVisible, setIsVisible = () => {}, children, size="sm" }) => {
  const handleClose = () => {
    setIsVisible("");
  };

  return (
    <Modal
      show={isVisible}
      onHide={handleClose}
      size={size}
      backdrop="static"
      keyboard={false}
      centered
    >{children}</Modal>
  );
};

export default Dialog;
