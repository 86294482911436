import { call, put, takeEvery, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import Api from "../lib/Api";
import { errorsCleared, showNotification } from "../redux/slices/appSlice";
import i18n from "../i18n/i18n";
import FileSaver from "file-saver";
import {
  createPupil,
  pupilsFetched,
  pupilDetails,
  portfolioDetails,
  deletePupil,
  updatePupil,
  fetchPupils,
  attachPupils,
  attachPupilsToSchool,
  fetchPupilsDashboard,
  createPortfolio,
  portFolioFetched,
  fetchPupilsLessons,
  resetPupilsLessons,
  resetPortFolio,
  fetchPupilsPortfolioItem,
  portFolioPageChanged,
  portFolioTotalItemsChanged,
  fetchPupilDetails,
  transferPupils,
  fetchPortfolioDetails,
  updatePupilReference,
  downloadPortfolio,
  uploadPupils,
  loadingStarted,
  loadingStopped,
  fetchPortfolioImages,
  portfolioImagesFetched,
  grantAccess
} from "../redux/slices/pupilSlice";
import {lessonsFetched, pageChanged, totalItemsChanged} from "../redux/slices/lessonSlice";

export const getFilter = (state) => state.lesson.filter || "";

function* createPupilSaga(action) {
  yield put(errorsCleared());
  const response = yield call(Api.post, "/api/pupils", action.payload);

  if (response.response?.status === 500) {
    yield put(
      showNotification([i18n.t("Creating pupil has not succeeded."), "error"])
    );
  } else if (response.response?.status === 422) {
    let errors = Object.values(response.response?.data?.errors || []).reduce(
      (errors, fieldErrors) => [...errors, ...fieldErrors]
    );
    yield put(showNotification(errors));
  } else {
    yield put(push("/schools/" + action.payload.school_id));
  }
}

function* fetchPupilLessonsSaga(action) {
  yield put(loadingStarted());
  let lessonFilter = yield select(getFilter);
  let response = yield call(Api.get, `/api/pupils/${action.payload.pupilId}/lessons?page=${action.payload.page}&search=${action.payload.searchContent}` +(lessonFilter ? `&${lessonFilter}`: ''));
  if (response?.data?.data) {
    yield put(lessonsFetched(response.data.data || []))
    yield put(pageChanged(response.data.current_page))
    yield put(totalItemsChanged(response.data.total))
  }
  yield put(loadingStopped());
}

function* resetPupilsLessonsSaga(action) {
  yield put(lessonsFetched([]));
}
function* resetPortFolioSaga(action) {
  yield put(portFolioFetched([]));
}

function* fetchPupilPortfolioItemsSaga(action) {
  let response = yield call(
    Api.get,
    `/api/pupils/${action.payload.pupilId}/portfolio-items?page=${action.payload.page}&search=${action.payload.searchContent}`
  );
  if (response?.data?.data) {
    yield put(portFolioPageChanged(response.data.current_page));
    yield put(portFolioTotalItemsChanged(response.data.total));
    yield put(portFolioFetched(response.data.data || []));
  }
}

function* fetchPupilDetailsSaga(action) {
  yield put(loadingStarted());
  let response = yield call(Api.get, `/api/pupils/${action.payload}`);
  if (response?.data) {
    yield put(pupilDetails(response.data));
    //yield put(totalItemsChanged(response.data.total))
    //yield put(portFolioFetched(response.data.portfolioItems?.data))
  }
  yield put(loadingStopped());
}

function* fetchPortfolioDetailsSaga(action) {
  yield put(loadingStarted());
  let response = yield call(Api.get, `/api/portfolio-items/${action.payload}`);
  if (response?.data) {
    yield put(portfolioDetails(response.data));
    //yield put(totalItemsChanged(response.data.total))
    //yield put(portFolioFetched(response.data.portfolioItems?.data))
  }
  yield put(loadingStopped());
}

function* updatePupilSaga(action) {
  const id = action.payload.id;
  const response = yield call(Api.put, `/api/pupils/${id}`, action.payload);

  if (response.response?.status === 500) {
    yield put(
      showNotification([i18n.t("Updating pupil has not succeeded."), "error"])
    );
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)));
  } else {
    if (action.payload.school_id) {
      yield put(push("/schools/" + action.payload.school_id));
    } else {
      yield put(push("/schools/" + action.payload.schoolId));
    }
  }
}

function* fetchPupilsSaga(action) {
  let response = {};
  if (action.payload.classId) {
    response = yield call(
      Api.get,
      `/api/schools/${action.payload.schoolId}/classes/${action.payload.classId}/pupils`
    );
  } else {
    response = yield call(Api.get, `/api/schools/${action.payload}/pupils`);
  }
  
  if (response.status === 500) {
    yield put(
      showNotification([
        i18n.t("Something went wrong while fetching pupils."),
        "error",
      ])
    );
  } else if (response.status === 422) {
    let errors = Object.values(response.data?.errors || []).reduce(
      (errors, fieldErrors) => [...errors, ...fieldErrors]
    );
    yield put(showNotification(errors));
  } else {
    yield put(pupilsFetched(response.data));
  }
}

function* deletePupilSaga(action) {
  let response = {};
  if (action.payload.classId) {
    response = yield call(
      Api.delete,
      `/api/schools/${action.payload.schoolId}/classes/${action.payload.classId}/pupils/${action.payload.pupilId}`
    );
  } else {
    response = yield call(
      Api.delete,
      `/api/schools/${action.payload.schoolId}/pupils/${action.payload.pupilId}`
    );
  }
  if (response.response) {
    yield put(
      showNotification([i18n.t("Unlinking pupil has not succeeded."), "error"])
    );
  } else {
    if (action.payload.classId) {
      yield put(
        fetchPupils({
          schoolId: action.payload.schoolId,
          classId: action.payload.classId,
        })
      );
    } else {
      yield put(fetchPupils(action.payload.schoolId));
    }
  }
}

function* attachPupilsSaga(action) {
  const response = yield call(
    Api.post,
    `/api/schools/${action.payload.schoolId}/classes/${action.payload.classId}/pupils`,
    action.payload
  );
  if (response.response) {
    yield put(
      showNotification([
        i18n.t("Attaching pupil(s) has not succeeded."),
        "error",
      ])
    );
  } else if (response.response?.status === 404) {
    yield put(
      showNotification([i18n.t("No pupil found with that email"), "error"])
    );
  } else {
    yield put(
      push(
        `/schools/${action.payload.schoolId}/classes/${action.payload.classId}`
      )
    );
  }
}

function* attachPupilsToSchoolSaga(action) {
  const response = yield call(
    Api.post,
    `/api/schools/${action.payload.schoolId}/pupils/attach`,
    { email: action.payload.email, reference: action.payload.reference }
  );
  if (response.response?.status === 404) {
    yield put(
      showNotification([i18n.t("No pupil found with that email"), "error"])
    );
  } else {
    yield put(push(`/schools/${action.payload.schoolId}`));
  }
}

function* fetchPupilsDashboardSaga(action) {
  let response = {};
  //response = yield call(Api.get, `/api/pupils/${action.payload}`);
  response = yield call(
    Api.get,
    `/api/pupils/${action.payload.pupilId}?page=${action.payload.page}`
  );

  if (response.status === 500) {
    yield put(
      showNotification([i18n.t("Creating pupil has not succeeded."), "error"])
    );
  } else if (response.status === 422) {
    let errors = Object.values(response.data?.errors || []).reduce(
      (errors, fieldErrors) => [...errors, ...fieldErrors]
    );
    yield put(showNotification(errors));
  } else {
    yield put(lessonsFetched(response.data.lessons?.data));
    yield put(pageChanged(response.data.lessons?.current_page));
    yield put(totalItemsChanged(response.data.lessons?.total));
    yield put(portFolioFetched(response.data.portfolioItems?.data));
    yield put(pupilsFetched(response.data?.data));
    // yield put(totalItemsChanged())
  }
}

function* CreatePortfolioSaga(action) {
  const response = yield call(Api.post, "/api/portfolio-items", action.payload);
  if (response.response?.status === 500) {
    yield put(
      showNotification([
        i18n.t("Creating potfolio has not succeeded."),
        "error",
      ])
    );
  } else if (response.response?.status === 422) {
    let errors = Object.values(response.response?.data?.errors || []).reduce(
      (errors, fieldErrors) => [...errors, ...fieldErrors]
    );
    yield put(showNotification(errors));
  } else {
    yield put(push("/"));
  }
}

function* transferPupilsSaga(action) {
  const data = {
    fromClassId: action.payload.fromClassId,
    toClassId: action.payload.toClassId,
    pupils: action.payload.pupils.map((pupil) => pupil.id),
  };
  const schoolId = yield select((store) => store.school?.selectedSchool?.id);

  const response = yield call(
    Api.post,
    `/api/schools/${schoolId}/pupils/transfer`,
    data
  );

  if (response.status === 500) {
    yield put(
      showNotification([
        i18n.t("Transfering pupils has not succeeded."),
        "error",
      ])
    );
  } else if (response.status === 422) {
    let errors = Object.values(response.data?.errors || []).reduce(
      (errors, fieldErrors) => [...errors, ...fieldErrors]
    );
    yield put(showNotification(errors));
  } else {
    yield put(push("/schools/" + schoolId));
  }
}

function* updatePupilReferenceSaga(action) {
  const response = yield call(
    Api.put,
    `/api/pupils/${action.payload.pupilId}/reference`,
    action.payload.formData
  );
  if (response.response?.status === 500) {
    yield put(
      showNotification([i18n.t("Updating pupil has not succeeded."), "error"])
    );
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)));
  } else {
    if (action.payload.classId) {
      yield put(
        push(
          `/schools/${action.payload.schoolId}/classes/${action.payload.classId}`
        )
      );
    } else {
      yield put(push(`/schools/${action.payload.schoolId}`));
    }
  }
}

function* downloadPortfolioSaga(action) {
  const response = yield call(
    Api.get,
    `/api/pupils/${action.payload}}/download-portfolio`,
    {
      responseType: "blob",
      timeout: 30000,
    }
  );
  FileSaver.saveAs(response.data, "portfolio.zip");
}

function* uploadPupilsSaga(action) {
  const response = yield call(
    Api.post,
    `/api/schools/${action.payload.schoolId}/upload-pupils`,
    action.payload.formData
  );
  if (response.response?.status === 500) {
    yield put(
      showNotification([
        i18n.t("Uploading pupil(s) has not succeeded."),
        "error",
      ])
    );
    yield put(push(`/schools/${action.payload.schoolId}`));
  } else if (response.response?.status === 422) {
    let errors = Object.values(response.response?.data?.errors || []).reduce(
      (errors, fieldErrors) => [...errors, ...fieldErrors]
    );
    yield put(showNotification(errors));
    yield put(push(`/schools/${action.payload.schoolId}`));
  } else {
    yield put(push(`/schools/${action.payload.schoolId}`));
  }
}

function* fetchPortfolioImagesSaga(action) {
  let response = yield call(
    Api.get,
    `/api/pupils/${action.payload.pupilId}/portfolio-items/images`
  );

  if (response.status === 500) {
    yield put(
      showNotification([
        i18n.t("Something went wrong while fetching portfolio items image."),
        "error",
      ])
    );
  } else if (response.status === 422) {
    let errors = Object.values(response.data?.errors || []).reduce(
      (errors, fieldErrors) => [...errors, ...fieldErrors]
    );
    yield put(showNotification(errors));
  } else {
    yield put(portfolioImagesFetched(response.data));
  }
}

function* grantAccessSaga(action) {
  console.log(action)
  let response = yield call(Api.post, `/api/pupils/${action.payload.pupilId}/grant-access`, {email: action.payload.email});

  console.log(response)
}

function* pupilSaga() {
  yield takeEvery(createPupil.type, createPupilSaga)
  yield takeEvery(updatePupil.type, updatePupilSaga)
  yield takeEvery(fetchPupils.type, fetchPupilsSaga)
  yield takeEvery(deletePupil.type, deletePupilSaga)
  yield takeEvery(attachPupils.type, attachPupilsSaga)
  yield takeEvery(attachPupilsToSchool.type, attachPupilsToSchoolSaga)
  yield takeEvery(fetchPupilsDashboard.type, fetchPupilsDashboardSaga)
  yield takeEvery(createPortfolio.type, CreatePortfolioSaga)
  yield takeEvery(fetchPupilsLessons.type, fetchPupilLessonsSaga)
  yield takeEvery(resetPupilsLessons.type, resetPupilsLessonsSaga)
  yield takeEvery(resetPortFolio.type, resetPortFolioSaga)
  yield takeEvery(fetchPupilsPortfolioItem.type, fetchPupilPortfolioItemsSaga)
  yield takeEvery(fetchPupilDetails.type,fetchPupilDetailsSaga)
  yield takeEvery(fetchPortfolioDetails.type,fetchPortfolioDetailsSaga)
  yield takeEvery(transferPupils.type, transferPupilsSaga)
  yield takeEvery(updatePupilReference.type, updatePupilReferenceSaga)
  yield takeEvery(downloadPortfolio.type, downloadPortfolioSaga)
  yield takeEvery(uploadPupils.type, uploadPupilsSaga)
  yield takeEvery(fetchPortfolioImages.type, fetchPortfolioImagesSaga)
  yield takeEvery(grantAccess.type, grantAccessSaga)
}

export default pupilSaga;
