import { createAction, createSlice } from '@reduxjs/toolkit'
const initialState = {
    moderates: [],
    page: 1,
    totalItems: 0,
    portfolioModerates: [],
    portfolioPage: 1,
    portfolioTotalItems: 0
}

export const moderateSlice = createSlice({
    name: 'moderate',
    initialState,
    reducers: {
        pageChanged: (state, action) => {
            state.page = action.payload
        },
        totalItemsChanged: (state, action) => {
            state.totalItems = action.payload
        },
        moderatesFetched: (state, action) => {
            state.moderates = action.payload
        },
        portfolioModeratesFetched: (state, action) => {
            state.portfolioModerates = action.payload
        },
        portfolioPageChanged: (state, action) => {
            state.portfolioPage = action.payload
        },
        portfolioTotalItemsChanged: (state, action) => {
            state.portfolioTotalItems = action.payload
        },
    }
})

export const { moderatesFetched, pageChanged, totalItemsChanged, portfolioModeratesFetched, portfolioPageChanged, portfolioTotalItemsChanged } = moderateSlice.actions
export const fetchModerates = createAction('moderate/fetch')
export const updateModerate = createAction('moderate/update')
export const fetchPortfolioModerates = createAction('moderate/portfolio-attachment/fetch')
export const updatePortfolioModerate = createAction('moderate/portfolio-attachment/update')

export default moderateSlice.reducer