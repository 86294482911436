import { React, useState } from "react";
import { useTranslation } from "react-i18next";
// import Errors from "../Errors";

const ClassForm = ({submit, schoolId, submitTitle }) => {
  const [t] = useTranslation();
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const submitState = (e) => {
    e.preventDefault();

    submit(
      name,
      startDate,
      endDate,
      schoolId
    )
  }

  return (
    <form onSubmit={submitState}>
      <div className="row">
        <div className="col-12">
          {/* <Errors /> */}
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="name" className="col-form-label">
            {t("Name")}
          </label>
          <input
            id="name"
            type="text"
            className="form-control"
            name="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="startDate" className="col-form-label">
            {t("Start Date")}
          </label>
          <input
            id="startDate"
            type="date"
            className="form-control"
            name="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="endDate" className="col-form-label">
            {t("End Date")}
          </label>
          <input
            id="endDate"
            type="date"
            className="form-control"
            name="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </div>
        <div className="col-12 text-right">
          <button type="submit" className="btn btn-secondary">
            {submitTitle}
          </button>
        </div>
      </div>
    </form>
  );
};
export default ClassForm;
