import {React, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {fetchLessons, resetDetails, lessonsFilter,resetLessons} from "../../redux/slices/lessonSlice";
import Pagination from "react-js-pagination";
import Filters from "../../component/Filters/Filters";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faThLarge, faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import Ribbon from "../../component/Lesson/Ribbon";
import Loader from "../../component/Loader";

const LessonList = () => {
  const [t,] = useTranslation();
  const dispatch = useDispatch();
  const lessons = useSelector(state => state.lesson.lessons)
  const page = useSelector(state => state.lesson.page)
  const totalItems = useSelector(state => state.lesson.totalItems)
  const loader= useSelector(state => state.lesson.loader)
  const search = <FontAwesomeIcon icon={faSearch}/>;
  const plus = <FontAwesomeIcon icon={faPlus} />;
  const cardView = <FontAwesomeIcon icon={ faThLarge} />;
  const listView = <FontAwesomeIcon icon={ faList} />;

  const [gridView, setGridView] = useState(true)
  const [searchBox, setSearchBox] = useState(0);
  const [searchData, setSearchData] = useState("")

  useEffect(() => {
    dispatch(resetLessons());
    dispatch(lessonsFilter());
    dispatch(resetDetails())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchLessons({searchContent:searchData,page:page}))
  }, [dispatch, page, searchData])

  const menuData = (
    <div className="row">
      <div className="col-12">
        <p className="font-weight-bold mt-5 mb-3 pt-2 menu-heading">
          {t("NEW")}
        </p>
      </div>
      <div className="col-12 mb-5">
        <div className="new-button"
        >
          <Link to="/lesson-create">
            <div className="card">
              <div className="text-center mt-5 mb-3 py-2">
                <button className="btn">
                  {plus}
                </button>
              </div>
              <div className="card-body mb-4 py-2">
                <p className="card-title mb-2 text-center">
                  {t("Upload a new lessons")}
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );

  const cardViewHandler = () =>{
    setGridView(true);
  }
  const listViewHandler = () =>{
    setGridView(false);
  }
  const searchHandler = () => {
    setSearchBox(searchBox === 0 ? 1 : 0);
    setSearchData("");
  };

  return (
    <div className="row mt-4 color-grayish">
      <div className="col-10 offset-1">
        <div className="row">
          <div className="col-md-4 col-12">
            <Filters searchClickCall={fetchLessons({searchContent:searchData,page:page})}/>
            {menuData}
          </div>

        {loader &&  <div className="lesson-loader"><Loader /></div> }

          <div className="col-md-8 col-12">

            <div className="row">
            <div className="col-12 p-2">
              <div className="row mt-5">
                <div className="col-6 font-weight-bold lessons-heading mb-0">{t('Overview')}</div>
                <div className="col-6">
                  <div className="text-right mt-3">
                    <button type="button" className="btn mr-2" onClick={searchHandler} style={{ fontSize: "24px"}}>{search}</button>
                    <button type="button" className="btn mr-2 menu-button btn-primary" onClick={listViewHandler} >{listView}</button>
                    <button type="button" className="btn menu-button btn-primary" onClick={cardViewHandler}>{cardView}</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-12 ${searchBox && "py-2 pr-0"} fade-in-left`} style={{height: searchBox && "auto", transform: `scale(${searchBox})`, transition: "transform 0.5s ease"}}>
              <input type="text" className="w-100 background-white" value={searchData} onChange={(e) => setSearchData(e.target.value)} placeholder={t("Zoeken")} />
            </div>
            {(lessons || []).map((lesson) => {
              return gridView ? (
                <div className="col-6 py-2 pr-0 d-flex" key={lesson.id}>
                  <div className="lessons grid w-100">
                    <div className="lessons-image" style={{ position: "relative", backgroundImage: lesson.image_url ? `url("${lesson.image_url}")` : `url("images/placeholder-image.png")` }} >
                      <Ribbon status={lesson?.status} grade={lesson?.grade?.name} />
                    </div>
                    <div className="lesson-card p-3">
                      <div>{t('Class')}</div>
                      <div className="pt-1"><strong>{lesson?.class?.name}</strong></div>
                      <div className="pt-3">{t('School')}</div>
                      <div className="pt-1"><strong>{lesson?.class?.school?.name}</strong></div>
                      <div className="pt-3">{t('Assignment')}</div>
                      <div className="pt-1"><strong>{lesson.name}</strong></div>

                      <div className="d-flex justify-content-end">
                        <Link to={`/lesson-details/${lesson.id}`} className="btn btn-link">
                          {t("view more")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-12 py-2 pr-0 d-flex" key={lesson.id}>
                  <div className="row lessons list ml-0 w-100">
                    <div className="lessons-image col-6" style={{ backgroundImage: lesson.image_url ? `url(${lesson.image_url})`  : `url("images/placeholder-image.png")`}} >
                    </div>
                    <div className="lesson-card col-6">
                      <Ribbon status={lesson?.status} grade={lesson?.grade?.name} />
                      <div className="pt-4">{t('Class')}</div>
                      <div className="pt-1"><strong>{lesson?.class.name}</strong></div>
                      <div className="pt-3">{t('School')}</div>
                      <div className="pt-1"><strong>{lesson?.class?.school?.name}</strong></div>
                      <div className="pt-3">{t('Assignment')}</div>
                      <div className="pt-1"><strong>{lesson.name}</strong></div>
                      <div className="d-flex justify-content-end">
                        <Link to={`/lesson-details/${lesson.id}`} className="btn btn-link">
                          {t("view more")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            </div>
            {totalItems ?
              (totalItems > 3?<div className="cardPagination d-flex justify-content-center mt-5 mb-4">
                <Pagination
                  activePage={page}
                  itemClass="page-item"
                  linkClass="page-link"
                  itemsCountPerPage={4}
                  totalItemsCount={totalItems}
                  pageRangeDisplayed={3}
                  onChange={(page) => dispatch(fetchLessons({searchContent:searchData,page:page}))}
                />
              </div>:"")
              : (<p className="noLessonFound mt-4">{t("No lessons found")}</p>)
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonList;
