import styled from 'styled-components'

export const Heading = styled.p`
    white-space: nowrap;
    font-size: 60px;
    font-family: Authority;
    font-weight: 900;
    text-overflow: ellipsis;
    height: 68px;
    width: 100%;
    overflow: hidden;
    color : ${props => props.color ?? '#505050' };
`

export const SubHeading = styled.p`
    font-size: 30px;
    font-family: Heebo,sans-serif;
    font-weight : normal;
    margin: 0;
    color : ${props => props.color ?? '#f47d44' };
`

export const SubTopicHeading = styled.p`
    font-size: 20px;
    font-family: Heebo,sans-serif;
    font-weight : normal;
    margin: 0;
    color : ${props => props.color ?? '#f47d44' };
`

export const Description = styled.span`
    font-size: 15px;
    font-family: Heebo,sans-serif;
    font-weight : normal;
    color : ${props => props.color ?? '#000000' };
    text-align : justify;
`