import { React, useState } from "react";
import { Card } from "react-bootstrap";
import Nav from "../../component/Nav";
import { login } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
if(process.env.REACT_APP_STYLE === "style-1"){
  require("./login.scss");
}else{
  require("./login2.scss");
}

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginError = useSelector((state) => state.auth.loginError);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const doLogin = (event) => {
    event.preventDefault();
    dispatch(login({ email, password }));
  };

  const eye = <FontAwesomeIcon icon={faEye} />;
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <div>
      <Nav />
      <div className="login-form-wrapper">
        <div className="container login-form">
          <div className="row justify-content-center w-100 h-100 my-auto mx-auto">
            <div className="form-box col-md-8 my-auto">
              {loginError && (
                <div className="alert alert-danger">
                  {t("De ingevoerde logingegevens zijn incorrect.")}
                </div>
              )}
              <Card>
                <Card.Header>Inloggen</Card.Header>
                <Card.Body>
                  <form>
                    <div className="form-group ">
                      <div className="row">
                        <div className="col-md-4 text-md-right ">
                          <label htmlFor="email">{t("E-mailadres")}</label>
                        </div>
                        <div className="col-md-6 mb-3 ">
                          <input
                            id="email"
                            type="email"
                            name="email"
                            autoFocus
                            autoComplete="off"
                            required
                            className="w-100 form-control input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 text-md-right ">
                          <label htmlFor="password">{t("Password")}</label>
                        </div>
                        <div className="col-md-6">
                          <div className="input-group">
                            <input
                              id="password"
                              type={passwordType}
                              name="password"
                              autoFocus
                              autoComplete="off"
                              required
                              className="form-control input"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <div
                              className="input-group-append"
                              style={{ marginLeft: "-5px", "z-index": "9999" }}
                            >
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                                role="button"
                                onClick={() => togglePassword()}
                              >
                                {eye}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 offset-md-4 mt-3">
                        <button
                          className="btn btn-light login-btn"
                          onClick={doLogin}
                        >
                          {t("login")}
                        </button>
                        {/* <button className="btn btn-link login-link" href="#">
                          Wachtwoord vergeten?
                        </button> */}
                      </div>
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
