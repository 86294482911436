import { createAction, createSlice } from '@reduxjs/toolkit';
const initialState = {
    page: 1,
    totalItems: 0,
    lessonSubmissions: [],
    currentLessonSubmission: []
}

export const lessonSubmissionSlice = createSlice({
    name: 'lessonSubmission',
    initialState,
    reducers: {
        pageChanged: (state, action) => {
            state.page = action.payload
        },
        totalItemsChanged: (state, action) => {
            state.totalItems = action.payload
        },
        lessonSubmissionsFetched: (state, action) => {
            state.lessonSubmissions = action.payload
        },
        currentLessonSubmissionFetched: (state, action) => {
            state.currentLessonSubmission = action.payload
        },
    },
})

export const {
  pageChanged,
  totalItemsChanged,
  lessonSubmissionsFetched,
  currentLessonSubmissionFetched
} = lessonSubmissionSlice.actions

export const fetchLessonSubmissionList = createAction('lessonSubmission/list')
export const fetchLessonSubmission = createAction('lessonSubmission/single')
export const fetchMyLessonSubmission = createAction('lessonSubmission/mine')
export const gradeLessonSubmission = createAction('lessonSubmission/grade')

export default lessonSubmissionSlice.reducer
