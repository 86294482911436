import {React} from "react";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from 'react-bootstrap';

const ModererenList = (props) => {
    const [t, ] = useTranslation();
    const Download = <FontAwesomeIcon icon={faDownload}/>;
    const { type, id, lesson_id, attachment_url, file_name} = props.selectedvalue;
    const statusChange = (event, status) =>{
        props.handleChangeStatus(event, status, lesson_id, id);
        props.onHide();
    }
    return (
        <Modal show={props.show} onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="col-12">
                <div className="row">
                    {type === "image" ?
                        <div className="col-12">
                            <img src={`${attachment_url}`} alt={file_name} className="w-100" height="300"/>
                        </div>
                    :
                        <>
                            <div className="col-10 ellipsis-style">
                                <a href={`${attachment_url}`} target='_blank' rel="noreferrer" title={file_name}>{file_name}</a> 
                            </div>
                            <div className="col-2">
                                <div className="float-right" style={{fontSize: "20px", color: "rgb(244, 125, 68)"}}><a href={`${attachment_url}`} download={`${attachment_url}`} title={t('Download')}>{Download}</a></div>
                            </div>
                        </>
                    }
                    
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={(event)=>statusChange(event,"declined")}>{t('Decline')}</Button>
                <Button onClick={(event)=>statusChange(event,"accepted")}>{t('Accept')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModererenList;