import { Fragment, React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PupilForm = ({ showPasswordNotice = false, submit, submitTitle }) => {
  const [t] = useTranslation();
  const selectedUser = useSelector((state) => state.user.selectedUser);
  const [showPassword, setshowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [reference, setReference] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  useEffect(() => {
    if (selectedUser) {
      setFirstName(selectedUser.firstName || "");
      setLastName(selectedUser.lastName || "");
      setEmail(selectedUser.email || "");
    }
  }, [selectedUser]);

  const submitState = (e) => {
    e.preventDefault();

    submit(firstName, lastName, email, reference, password, passwordConfirm);
  };

  return (
    <form onSubmit={submitState}>
      <div className="row">
        <div className="col-12">
          {/* <Errors /> */}
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="firstName" className="col-form-label">
            {t("First Name")}
          </label>
          <input
            id="firstName"
            type="text"
            className="form-control "
            name="firstName"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="lastName" className="col-form-label">
            {t("Last Name")}
          </label>
          <input
            id="lastName"
            type="text"
            className="form-control "
            name="lastName"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
          <Fragment>
        <div className="col-12 mb-4">
          <label htmlFor="email" className="col-form-label">
            {t("Email")}
          </label>
          <input
            id="email"
            type="text"
            className="form-control "
            name="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="reference" className="col-form-label">
            {t("Reference")}
          </label>
          <input
            id="reference"
            type="text"
            className="form-control "
            name="reference"
            value={reference}
            onChange={(e) => setReference(e.target.value)}
          />
        </div>

       
            <div className="col-12 mb-4">
              <label htmlFor="password" className="col-form-label">
                {t("Password")}
              </label>
              <div class="input-group mb-3">
                <input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  className="form-control "
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div class="input-group-append">
                  <span class="input-group-text"><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setshowPassword(!showPassword)} /></span>
                </div>
              </div>
              {showPasswordNotice && (
                <div className="text-muted">
                  {t(
                    "Only fill in the password fields if you want to change the password"
                  )}
                </div>
              )}
            </div>
            <div className="col-12 mb-4">
              <label htmlFor="password_confirm" className="col-form-label">
                {t("Confirm password")}
              </label>
              <div class="input-group mb-3">
                <input
                  id="password_confirm"
                  type={showConfirmPassword ? 'text' : 'password'}
                  className="form-control "
                  name="password_confirm"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
                <div className="input-group-append">
                  <span class="input-group-text"><FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} onClick={() => setshowConfirmPassword(!showConfirmPassword)} /></span>
                </div>
              </div>
            </div>
          </Fragment>
        

        <div className="col-12 text-right">
          <button type="submit" className="btn btn-secondary">
            {submitTitle}
          </button>
        </div>
      </div>
    </form>
  );
};

export default PupilForm;
