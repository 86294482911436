import React from 'react';
import List from '../component/list';
import { componentName } from '../component/Constant';

const CenturySkillsList = () => {
    return (
        <List componentName={componentName.centurySkill}/>
    )
}

export default CenturySkillsList
