import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {Heading} from "../../component";
import {
  fetchMyLessonSubmission
} from "../../redux/slices/lessonSubmissionSlice";
import Loader from "../../component/Loader";
import {Card} from "react-bootstrap";
import FsLightbox from 'fslightbox-react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
const download = <FontAwesomeIcon icon={faDownload} />;

const PupilLessonSubmission = (props) => {
  const [t,] = useTranslation();
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    sourceIndex: null
  });

  const Submission = useSelector(store => store.lessonSubmission.currentLessonSubmission);
  const dispatch = useDispatch();

  const {lessonId} = useParams();
  const images = Submission?.attachments?.length > 0 ? Submission.attachments.filter(attachment => (attachment.type === 'image')) : [];
  const files = Submission?.attachments?.length > 0 ? Submission.attachments.filter(attachment => (attachment.type === 'file')) : [];

  useEffect(() => {
    dispatch(fetchMyLessonSubmission({lessonId}));
  }, [lessonId, dispatch]);

  const openLightboxOnSlide = (index) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      sourceIndex: index
    });
  }

  return (
    <>
      {images?.length > 0 &&
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={images.map(img => {return( process.env.REACT_APP_API_URL + img.path )})}
          sourceIndex ={lightboxController.sourceIndex}
          type="image"
        />
      }
      <div className="row mt-4 color-grayish">
        <div className="col-10 offset-1">
        {Submission ? (
          <div className="row">
            <div className="col-4">
              <div className="mt-5 mb-0">
                <Heading>{t('Extra')}</Heading>
                <Card className="bg-white">
                  <Card.Body>
                    <strong>{t('Attachments')}</strong>
                    <div className="mt-2">
                      {files?.length > 0 ? (
                        <ul>
                          {files.map(doc => (
                            <li key={doc.id}><a href={doc.attachment_url} className="color-grayish text-underline" target="_blank" download rel="noreferrer">{download} {doc.file_name || "geen naam"}</a></li>
                          ))}
                        </ul>
                      ) : (<p>{t('No attachments submitted')}</p>)}
                    </div>
                    {Submission.status === 'accepted' &&
                      <>
                        <div className="mt-2">
                          <div><strong>{t('Grade')}</strong></div>
                          <div>{Submission.grade}</div>
                        </div>
                        <div className="mt-2">
                          <div><strong>{t('Grade comment')}</strong></div>
                          <div>{Submission.grade_comment || t('None')}</div>
                        </div>
                      </>
                    }
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="col-8">
              <div className="mt-5 mb-0">
                <Heading>{t('My submission')}</Heading>
                <Card className="bg-white pb-3">
                  <Card.Body>
                    <div className="mb-2"><strong>{t('Description')}</strong></div>
                    {Submission.description}
                  </Card.Body>
                </Card>
                <Heading className="mt-5" color="#505050">{t('Images')}</Heading>
                <Card className="bg-white">
                  <Card.Body>
                    <div className="row mb-3">
                      {(images || []).map((image, index) => {
                        return(
                          <div key={index} className="col-4 mb-3" style={{"cursor": "pointer"}}>
                            <img onClick={() => openLightboxOnSlide(index)} src={`${process.env.REACT_APP_API_URL}${image.path}`} width="100%" alt="" />
                          </div>
                        )
                      })
                      }
                      {!images?.length &&
                        <div className="col-12">
                          <p>{t('Image not found')}</p>
                        </div>
                      }
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
      ) : <Loader />}
        </div>
      </div>
    </>
  )
};

export default PupilLessonSubmission;
