import React, { Fragment } from "react";
import EditProfile from "../../component/Forms/EditProfileForm"
import { useTranslation } from "react-i18next";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {updateUser} from "../../redux/slices/userSlice";

const Profile = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch()
    const arrow = <FontAwesomeIcon icon={faArrowLeft} />;

    const submit = (
        id,
        first_name,
        last_name,
        email,
        password,
        password_confirmation
      ) => {
        dispatch(updateUser({
          id,
          first_name,
          last_name,
          email,
          password,
          password_confirmation,
          profile: true
        }))
    }

    return ( <Fragment>
        {/* {loader &&  <div className="lesson-loader"><Loader/></div>} */}
        <Fragment>
            <div className="row mt-5">
                <div className="col-8 offset-2">
                    <div className="card" style={{background: "none"}}>
                        <div className="card-header text-center">
                            <div className="row align-items-center">
                                <div className="col-3 text-left">
                                <Link to="/">
                                    <button className="btn plus-button">{arrow}</button>
                                </Link>
                                </div>
                                <div className="col-6">
                                <h4 className="mb-0">{t('Profile')}</h4>
                                </div>
                                <div className="col-3 "></div>
                            </div>
                        </div>
                        <div className="card-body">
                            <EditProfile submit={submit}/>
                        </div>
                    </div>
                </div>
            </div>
            </Fragment>
        </Fragment>
    ) 
};

export default Profile;
