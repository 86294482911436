import {Link} from "react-router-dom";
import {React, useEffect} from "react";
import UserForm from "../../component/Forms/UserForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {selectUser, updateUser} from "../../redux/slices/userSlice";
import {useTranslation} from "react-i18next";

const UserEdit = ({id}) => {
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const dispatch = useDispatch()
  const [t, ] = useTranslation()

  useEffect(() => {
    dispatch(selectUser(id))
  }, [dispatch, id])

  const submit = (
    first_name,
    last_name,
    email,
    role,
    password,
    password_confirmation
  ) => {
    dispatch(updateUser({
      first_name,
      last_name,
      email,
      role,
      password,
      password_confirmation
    }))
  }

  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
              <Link to="/users">
                <button className="btn plus-button">{arrow}</button>
              </Link>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t('Edit user')}</h4>
            </div>
            <div className="col-3 "></div>
          </div>
        </div>
        <div className="card-body">
          <UserForm showPasswordNotice={true} submit={submit} submitTitle={t('Update')} type="edit" />
        </div>
      </div>
    </div>
  )
}

export default UserEdit;
