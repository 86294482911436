import {call, put, takeEvery, select} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import Api from "../lib/Api";
import {
  createTaxonomie,
  deleteTaxonomie,
  fetchTaxonomies,
  pageChanged,
  taxonomieDeleted,
  taxonomieSelected,
  taxonomiesFetched,
  selectTaxonomie,
  totalItemsChanged,
  updateTaxonomie,
  lessonsWithTaxonomies,
  fetchLessonsWithTaxonomies,
  fetchLessonsWithTaxonomy,
  setLessonsWithTaxonomySearch,
  lessonsWithTaxonomySearch,
  lessonsWithTaxonomy,
  fetchAllTaxonomies,
  allTaxonomiesFetched
} from "../redux/slices/taxonomieSlice";
import {showNotification} from "../redux/slices/appSlice";
import i18n from "../i18n/i18n";

function* fetchTaxonomiesSaga(action) {
  let response = false;
  if (action.payload.searchContent) {
    response = yield call(Api.get, `/api/${action.payload.endpoint}?page=${action.payload.page}&search=${action.payload.searchContent}`)
  } else {
    response = yield call(Api.get, `/api/${action.payload.endpoint}?page=${action.payload.page}`)
  }
  if (response?.data?.data) {
    yield put(taxonomiesFetched(response.data.data || []))
    yield put(pageChanged(response.data.current_page))
    yield put(totalItemsChanged(response.data.total))
  } else if (response?.data) {
    yield put(taxonomiesFetched(response.data || []))
  } else {
    yield put(showNotification([i18n.t('Unfortunately there was an error fetching the taxonomies from the server.')]))
  }
}

function* fetchAllTaxonomiesSaga(action) {
  const response = yield call(Api.get, "/api/taxonomies")

  if (response?.data) {
    yield put(allTaxonomiesFetched(response.data || []))
  } else {
    yield put(allTaxonomiesFetched([]))
  }
}

function* deleteTaxonomieSaga(action) {
  const response = yield call(Api.delete, `/api/${action.payload.endpoint}/${action.payload.id}`)

  if (response.response) {
    yield put(showNotification([i18n.t('Deleting taxonomie has not succeeded.')]))
  } else {
    yield put(taxonomieDeleted(action.payload.id))
    const page = yield select(state => state.taxonomie.page)
    yield put(fetchTaxonomies({endpoint:action.payload.endpoint ,page :page}))
  }
}

function* createTaxonomieSaga(action) {
  const response = yield call(Api.post, `/api/${action.payload.endpoint}`, action.payload.formData)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Creating taxonomie has not succeeded.')]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)))
  } else {
    yield put(push(action.payload.navigate))
  }
}

function* updateTaxonomieSaga(action) {
  const id = yield select(state => state.taxonomie.selectedTaxonomie.id)
  const response = yield call(Api.put, `/api/${action.payload.endpoint}/${id}`, action.payload.formData)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Creating taxonomie has not succeeded.')]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)))
  } else {
    yield put(push(action.payload.navigate))
  }
}

function* selectTaxonomieSaga(action) {
  const response = yield call(Api.get, `/api/${action.payload.endpoint}/${action.payload.id}`)
  if (response?.data) {
    const taxonomieData = {
      id: response.data.id,
      name: response.data.name,
    };

    yield put(taxonomieSelected(taxonomieData))
  } else {
    yield put(showNotification(Object.values(response.data)))
  }
}

function* fetchLessonsWithTaxonomiesSaga(action) {
  const response = yield call(Api.get, `/api/lessons-with-taxonomies`)
  if(response?.data) {
    yield put(lessonsWithTaxonomies(response.data))
  }  
}

function* setLessonsWithTaxonomySearchSaga(action) {
  yield put(lessonsWithTaxonomySearch(action.payload))

}

function* fetchLessonsWithTaxonomySaga(action) {
  const response = yield call(Api.post, `/api/lessons-with-taxonomy`, action.payload)
  if(response?.data) {
    yield put(lessonsWithTaxonomy(response.data))
  }
}

function* taxonomieSaga() {
  yield takeEvery(fetchTaxonomies.type, fetchTaxonomiesSaga)
//   yield takeEvery(fetchAllTaxonomies.type, fetchAllTaxonomiesSaga)
  yield takeEvery(deleteTaxonomie.type, deleteTaxonomieSaga)
  yield takeEvery(createTaxonomie.type, createTaxonomieSaga)
  yield takeEvery(updateTaxonomie.type, updateTaxonomieSaga)
  yield takeEvery(selectTaxonomie.type, selectTaxonomieSaga)
  yield takeEvery(fetchLessonsWithTaxonomies.type, fetchLessonsWithTaxonomiesSaga)
  yield takeEvery(fetchLessonsWithTaxonomy.type, fetchLessonsWithTaxonomySaga)
  yield takeEvery(setLessonsWithTaxonomySearch.type, setLessonsWithTaxonomySearchSaga)
  yield takeEvery(fetchAllTaxonomies.type, fetchAllTaxonomiesSaga)
}

export default taxonomieSaga
