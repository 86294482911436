import {Link} from "react-router-dom";
import {React, useEffect} from "react";
import TaxonomiesForm from "./Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {selectTaxonomie, updateTaxonomie} from "../../../redux/slices/taxonomieSlice";
import {useTranslation} from "react-i18next";
import {errorsCleared} from "../../../redux/slices/appSlice";
import { endpointName, taxonomiesRoutes, subHeading} from "./Constant";

const TaxonomieEdit = ({id,page}) => {
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const dispatch = useDispatch()
  const [t, ] = useTranslation()

  useEffect(() => {
    dispatch(selectTaxonomie({endpoint: endpointName[page],id: id}))
    dispatch(errorsCleared())
  }, [dispatch, id, page])

  const submit = ( name ) => {
    dispatch(updateTaxonomie({endpoint: endpointName[page],navigate : taxonomiesRoutes[page], formData : {name:name}}))
  }

  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
              <Link to={taxonomiesRoutes[page]}>
                <button className="btn plus-button">{arrow}</button>
              </Link>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t(`Edit ${subHeading[page]}`)}</h4>
            </div>
            <div className="col-3 "></div>
          </div>
        </div>
        <div className="card-body">
          <TaxonomiesForm submit={submit} submitTitle={t('Update')} />
        </div>
      </div>
    </div>
  )
}

export default TaxonomieEdit;
