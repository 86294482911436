// eslint-disable-next-line import/no-anonymous-default-export
export default {
    name: 'defaultLang',

    lookup(options) {
        return 'nl';
    },

    cacheUserLanguage(lng, options) {

    }
};