import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import reportWebVitals from './reportWebVitals';
import {store} from './redux/store'
import {Provider} from 'react-redux'
import i18n from './i18n/i18n'
import {I18nextProvider} from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';


if(process.env.REACT_APP_STYLE === "style-1"){
  require("./scss/style.scss");
}else{
  require("./scss2/style.scss");
}
let persistor = persistStore(store);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <App/>
        </React.StrictMode>
      </PersistGate>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);
reportWebVitals();
