import {React, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { grantAccess } from "../../redux/slices/pupilSlice";

const GrantAccess = () => {
  const [t, ] = useTranslation()
  const history = useHistory();
  const dispatch = useDispatch();
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;

  const user = useSelector((state) => state.auth.user);

  const [email, setEmail] = useState("");

  const submit = (e) => {
    e.preventDefault();
    console.log(user, email);
    dispatch(grantAccess({email, pupilId: user.id}))
  }

  return (
    <div className="container padding-80 mt-5">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
                <button className="btn btn-sm btn-primary"  onClick={() => history.goBack()}>{arrow}</button>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t('Grant access')}</h4>
            </div>
            <div className="col-3 "></div>
          </div>
        </div>
        <div className="card-body">
        <form onSubmit={submit}>
          <div className="row">
            <div className="col-12">
              {/* <Errors /> */}
            </div>
            
            <div className="col-12 mb-4">
              <label htmlFor="email" className="col-form-label">
                {t("Email")}
              </label>
              <input
                id="email"
                type="text"
                className="form-control "
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="col-12 text-right">
              <button type="submit" className="btn btn-secondary">Submit</button>
            </div>
          </div>
        </form>
        </div>
      </div>
    </div>
  )
}

export default GrantAccess;
