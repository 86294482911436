import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { attachFile } from "../../redux/slices/lessonSlice"

function AddImageOrFile({ setIsVisible = () => {}, type, lessonId }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [image, setImage] = useState({});

  const handleSubmit = () => {
    const data = {
      [type]: image
    };
    let formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    dispatch(attachFile({ type : type, lessonId : lessonId, formData : formData, resetStates : resetStates}));
  };

  const resetStates = () => {
    setImage({});
    setIsVisible(false);
  };

  return (
    <div className="p-3">
      <h4 className="modal-title d-flex justify-content-center">
        {t(`Upload ${type}`)}
      </h4>

      <div className="row mt-3">
        <div className="col-12 mb-2">
          <label
            htmlFor="image"
            className="col-form-label btn btn-primary mr-4"
          >
            {t(`Select ${type}`)}
          </label>
          <p style={{width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginBottom: "0px"}}>{image?.name}</p>
          <input
            id="image"
            type="file"
            className=" d-none"
            name="image"
            required
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <Button variant="outline-primary" onClick={resetStates}>{t("Cancel")}</Button>
        <Button onClick={() => handleSubmit()}>{t("Submit")}</Button>
      </div>
    </div>
  );
}
export default AddImageOrFile;
