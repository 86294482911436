import { createAction, createSlice } from '@reduxjs/toolkit'
const initialState = {
    teachers: [],
    page: 1,
    totalItems: 0
}
export const teacherSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {
        pageChanged: (state, action) => {
            state.page = action.payload
        },
        totalItemsChanged: (state, action) => {
            state.totalItems = action.payload
        },
        teacherDeleted: (state, action) => {
            state.teacher = state.teacher.filter(user => user.id !== action.payload)
        },
        teachersFetched: (state, action) => {
            state.teachers = action.payload
        },
    }
})

export const { teachersFetched, pageChanged, totalItemsChanged, teacherDeleted } = teacherSlice.actions

export const createTeacher = createAction('teacher/create')
export const updateTeacher = createAction('teacher/update')
    // please put the school id in the payload
export const fetchTeachers = createAction('teacher/fetch-of-school')
export const deleteTeacher = createAction('teacher/delete')
export const attachTeacher = createAction('teacher/attach')
export const attachTeacherClass = createAction('teacher/attach/class')
export const uploadTeachers = createAction('teacher/upload')

export default teacherSlice.reducer