import {call, put, takeEvery, select} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import Api from "../lib/Api";
import {
  createUser,
  deleteUser,
  fetchUsers,
  pageChanged,
  userDeleted,
  userSelected,
  usersFetched,
  selectUser,
  totalItemsChanged, updateUser
} from "../redux/slices/userSlice";
import { fetchUser } from '../redux/slices/authSlice';
import {errorsCleared, showNotification} from "../redux/slices/appSlice";
import i18n from '../i18n/i18n'

function* fetchUsersSaga(action) {
  yield put(errorsCleared())
  let response = false;
  const {searchContent, page} = action.payload;

  if (searchContent) {
    response = yield call(Api.get, `/api/users?page=${page}&search=${searchContent}`)
  } else {
    response = response = yield call(Api.get, `/api/users?page=${page}`)
  }
  if (response?.data?.data) {
    yield put(usersFetched(response.data.data || []))
    yield put(pageChanged(response.data.current_page))
    yield put(totalItemsChanged(response.data.total))
  } else {
    yield put(showNotification([i18n.t('Unfortunately there was an error fetching the users from the server.'),"error"]))
  }
}

function* deleteUserSaga(action) {
  yield put(errorsCleared())
  const response = yield call(Api.delete, `/api/users/${action.payload}`)

  if (response.response) {
    yield put(showNotification([i18n.t('Deleting user has not succeeded.'),"error"]))
  } else {
    yield put(userDeleted(action.payload))
    const page = yield select(state => state.user.page)
    yield put(fetchUsers({page}))
  }
}

function* createUserSaga(action) {
    yield put(errorsCleared())
    console.log('action.payload ',action.payload)
    const response = yield call(Api.post, '/api/users', action.payload)

    if (response.response?.status === 500) {
        yield put(showNotification([i18n.t('Creating user has not succeeded.'),"error"]))
    } else if (response.response?.status === 422) {
        let errors = Object.values(response.response?.data?.errors || []).reduce((errors, fieldErrors) => [...errors, ...fieldErrors]);
        yield put(showNotification(errors))
    } else {
    yield put(push('/users'))
  }
}

function* updateUserSaga(action) {
  yield put(errorsCleared())
  const id = yield action.payload.profile ? action.payload.id : select(state => state.user.selectedUser.id)

  let data = action.payload

  if (data.password === '') {
    delete data.password
    delete data.password_confirmation
  }

  const response = yield Api.put(`/api/users/${id}`, data);
  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Updating user has not succeeded.'),"error"]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(response.response?.data))
  } else {
    if(data.schoolId){
      yield put(push(`/schools/${data.schoolId}`))
    }else if(data.profile){
      yield put(fetchUser())
      yield put(push('/profile'))
    }else{
      yield put(push('/users'))
    }
  }
}

function* selectUserSage(action) {
  yield put(errorsCleared())
  const response = yield call(Api.get, `/api/users/${action.payload}`)

  if (response?.data) {
    let role = 'admin';

    if (response.data.roles && response.data.roles[0]) {
      role = response.data.roles[0].name;
    }

    const userData = {
      id: response.data.id,
      name: response.data.name,
      firstName: response.data.first_name,
      lastName: response.data.last_name,
      email: response.data.email,
      role: role,
    };

    yield put(userSelected(userData))
  } else {
    yield put(showNotification(Object.values(response.data)))
  }
}


function* userSaga() {
  yield takeEvery(fetchUsers.type, fetchUsersSaga)
  yield takeEvery(deleteUser.type, deleteUserSaga)
  yield takeEvery(createUser.type, createUserSaga)
  yield takeEvery(updateUser.type, updateUserSaga)
  yield takeEvery(selectUser.type, selectUserSage)
}

export default userSaga
