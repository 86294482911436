import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { addClassMentor } from '../../redux/slices/classSlice';

function ClassMentorModal({ setIsVisible = () => {}, teacher, schoolId, classId }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [selectedTeacherId, setSelectedTeacherId] = useState("");

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("teacher", selectedTeacherId);
    dispatch(addClassMentor({ schoolId : schoolId, classId: classId, formData : formData, resetStates : resetStates}));
  };

  const resetStates = () => {
    setSelectedTeacherId("");
    setIsVisible(false);
  };

  return (
    <div className="p-3">
      <h4 className="modal-title d-flex justify-content-center text-capitalize">
        {t(`Add Class Mentor`)}
      </h4>

      <div className="row mt-3">
        <div className="col-12 mb-4">
          <label htmlFor="selectedTeacherId" className="col-form-label">
            {t("Teacher")}
          </label>
          <select
            id="selectedTeacherId"
            className="form-control "
            name="selectedTeacherId"
            value={selectedTeacherId}
            onChange={(e) => setSelectedTeacherId(e.target.value)}
          >
            <option value="">{t("Select teacher")}</option>
            {(teacher || []).map( teacher => 
                {
                    return (
                        <option key={teacher.id} value={teacher.id}>{teacher.user.name}</option>
                    )
                }
            )}
          </select>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <Button onClick={resetStates}>{t("Close")}</Button>

        <Button onClick={() => handleSubmit()}>{t("Submit")}</Button>
      </div>
    </div>
  );
}
export default ClassMentorModal;
