import {React, useEffect, useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {selectionCleared} from "../../redux/slices/userSlice";
import { attachPupils, fetchPupils } from "../../redux/slices/pupilSlice";
import { MultiSelect } from "react-multi-select-component";

const PupilAttach = ({schoolId, classId}) => {
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const dispatch = useDispatch()
  const [t, ] = useTranslation()
  const history = useHistory();

  const pupils = useSelector(state => state.pupil.pupils);
  const [selected, setSelected] = useState([]);

  const options = pupils?.map(pupil => {
    return { label: pupil.user.name, value: pupil.id }
  })
  useEffect(() => {
    dispatch(selectionCleared())
    dispatch(fetchPupils(schoolId))
  }, [dispatch, schoolId])

  const submit = (e) => {
    e.preventDefault();
    dispatch(attachPupils({
      schoolId: schoolId,
      classId: classId,
      pupils: selected.map(s => s.value)
    }))
  }

  return (
    <div className="container main-container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
                <button className="btn btn-sm btn-primary" onClick={() => history.goBack()}>{arrow}</button>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t('Attach pupil')}</h4>
            </div>
            <div className="col-3 "></div>
          </div>
        </div>
        <div className="card-body overflow-visible">
          <form onSubmit={submit}>
            <MultiSelect
              className="my-4"
              options={options ? options : []}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
            <button type="submit" className="btn btn-primary my-4 float-right">{t('Submit')}</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PupilAttach;
