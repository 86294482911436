import React from 'react';
import List from '../component/list';
import { componentName} from '../component/Constant';

const WorldOfEngineeringList = () => {
    return (
        <List componentName={componentName.worldsOfEngineering}/>
    )
}

export default WorldOfEngineeringList
