import React from 'react';
import List from '../component/list';
import {componentName} from '../component/Constant';

const ThemesList = () => {
    return (
        <List componentName={componentName.theme}/>
    )
}

export default ThemesList
