import {React, useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import { attachTeacher} from "../../redux/slices/teacherSlice";
const TeacherAttachSchool = ({schoolId}) => {
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const dispatch = useDispatch()
  const [t, ] = useTranslation()
  const history = useHistory();
  const [email, setEmail] = useState("");

  const submit = (e) => {
    e.preventDefault();
    dispatch(attachTeacher({
      schoolId: schoolId,
      email: email
    }))
  }
  return (
    <div className="container main-container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
                <button className="btn btn-sm btn-primary" onClick={() => history.goBack()}>{arrow}</button>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t('Attach Teacher')}</h4>
            </div>
            <div className="col-3 "></div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={submit}>
            <div className="row">
              <div className="col-12 mb-4">
            <label htmlFor="email" className="col-form-label">
              {t("Email")}
            </label>
            <input
              id="email"
              type="text"
              className="form-control "
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          </div>
            <button type="submit" className="btn btn-primary my-4 float-right">{t('Submit')}</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default TeacherAttachSchool;
