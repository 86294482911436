import {createAction, createSlice} from '@reduxjs/toolkit'
const initialState = {
  users: [],
  selectedUser: {},
  page: 1,
  totalItems: 0,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userSelected: (state, action) => {
      state.selectedUser = action.payload
    },
    selectionCleared: (state) => {
      state.selectedUser = {}
    },
    pageChanged: (state, action) => {
      state.page = action.payload
    },
    totalItemsChanged: (state, action) => {
      state.totalItems = action.payload
    },
    userDeleted: (state, action) => {
      state.users = state.users.filter(user => user.id !== action.payload)
    },
    usersFetched: (state, action) => {
      state.users = action.payload
    }
  }
})

export const { userSelected, selectionCleared, usersFetched, pageChanged, totalItemsChanged, userDeleted } = userSlice.actions
export const createUser = createAction('user/create')
export const updateUser = createAction('user/update')
export const fetchUsers = createAction('user/fetchAll')
export const selectUser = createAction('user/select')
export const deleteUser = createAction('user/delete')

export default userSlice.reducer
