import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const EditProfileForm = ({ submit }) => {
  const [t] = useTranslation();
  const profile = useSelector((state) => state.auth?.user);

  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const eye = <FontAwesomeIcon icon={faEye} />;

  useEffect(() => {
    if (profile) {
      setId(profile.id || "");
      setFirstName(profile.first_name || "");
      setLastName(profile.last_name || "");
      setEmail(profile.email || "");
    }
  }, [profile]);

  const submitState = (e) => {
    e.preventDefault();
    submit(id, firstName, lastName, email, password, passwordConfirm);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordType("password");
    }
  };

  return (
    <form onSubmit={submitState}>
      <div className="row">
        <div className="col-12 mb-4">
          <label htmlFor="first_name" className="col-form-label">
            {t("First Name")}
          </label>
          <input
            id="first_name"
            type="text"
            className="form-control "
            name="first_name"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="last_name" className="col-form-label">
            {t("Last Name")}
          </label>
          <input
            id="last_name"
            type="text"
            className="form-control "
            name="last_name"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="email" className="col-form-label">
            {t("Email")}
          </label>
          <input
            id="email"
            type="text"
            className="form-control "
            name="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="password" className="col-form-label">
            {t("Password")}
          </label>
          <div className="input-group">
            <input
              id="password"
              type={passwordType}
              className="form-control "
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                role="button"
                onClick={() => togglePassword()}
              >
                {eye}
              </span>
            </div>
          </div>
        </div>
        <div className="text-muted">
          {t(
            "Only fill in the password fields if you want to change the password"
          )}
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="password_confirm" className="col-form-label">
            {t("Confirm password")}
          </label>
          <div className="input-group">
            <input
              id="password_confirm"
              type={confirmPasswordType}
              className="form-control "
              name="password_confirm"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                role="button"
                onClick={() => toggleConfirmPassword()}
              >
                {eye}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 text-right">
          <button type="submit" className="btn btn-primary">
            {t("Update")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditProfileForm;
