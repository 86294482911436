import React from "react";
import {useTranslation} from "react-i18next";

const Ribbon = ({status, grade}) => {

  const [t,] = useTranslation();

  const translateStatus = status => {
    const firstLetter = status.substr(0, 1);
    status = firstLetter.toUpperCase() + status.substr(1);
    return t(status);
  }

  return (<div className="lesson-ribbon">
      <div className={"status-label " + status + ' ' + (grade ? 'has-grade' : 'no-grade')}>
        {translateStatus(status)}
      </div>
      {status === 'done' && grade &&
        <div className="grade-label">
          {grade}
        </div>
      }
    </div>
  )
}

export default Ribbon;
