import {Link} from "react-router-dom";
import {React, useEffect} from "react";
import SchoolForm from "../../component/Forms/SchoolForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {selectSchool, updateSchool} from "../../redux/slices/schoolSlice";
import {useTranslation} from "react-i18next";

const SchoolEdit = ({id}) => {
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const dispatch = useDispatch()
  const [t, ] = useTranslation()

  useEffect(() => {
    dispatch(selectSchool(id))
  }, [dispatch, id])

  const submit = (
    name,
    street,
    house_number,
    house_number_suffix,
    postcode,
    city,
    phone_number,
    active,
    website_url
  ) => {
    dispatch(updateSchool({
      name,
      street,
      house_number,
      house_number_suffix,
      postcode,
      city,
      phone_number,
      active,
      website_url
    }))
  }

  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
              <Link to="/schools">
                <button className="btn plus-button">{arrow}</button>
              </Link>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t('Edit school')}</h4>
            </div>
            <div className="col-3 "></div>
          </div>
        </div>
        <div className="card-body">
          <SchoolForm submit={submit} submitTitle={t('Update')} />
        </div>
      </div>
    </div>
  )
}

export default SchoolEdit;
