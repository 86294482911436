import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import AccordionCpllaps from "./AccordionCpllaps";
import {useSelector, useDispatch} from "react-redux";
import moment from 'moment';

import {
  fetchClasses,
  // fetchSubjects,
  // fetchTargetGroups,
  fetchDisciplines,
  fetchCoreDisciplines,
  fetchWorldsOfEngineering,
  fetchBetaMentalities,
  fetchProfessionalOrientations,
  fetchWTCoreSubjectives,
  fetchCenturySkills,
  // fetchThemes,
  lessonsFilter
} from "../../redux/slices/lessonSlice";

function Filters(props) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const Classes = useSelector((state) => state.lesson.classes);
  const Role = useSelector((state) => state.auth?.user?.roles?.[0]?.name);
  // const Subjects = useSelector((state) => state.lesson.subjects);
  // const TargetGroups = useSelector(state => state.lesson.targetGroups);
  const Disciplines = useSelector(state => state.lesson.disciplines);
  const CoreDisciplines = useSelector(state => state.lesson.coreDisciplines);
  const WorldsOfEngineering = useSelector(state => state.lesson.worldsOfEngineering);
  const BetaMentalities = useSelector(state => state.lesson.betaMentalities);
  const ProfessionalOrientations = useSelector((state) => state.lesson.professionalOrientations);
  const WTCoreSubjectives = useSelector(state => state.lesson.wTCoreSubjectives);
  const CenturySkills = useSelector(state => state.lesson.centurySkills);
  // const Themes = useSelector((state) => state.lesson.themes);
  const [saveState, setSaveState] = useState({});
  useEffect(() => {
    dispatch(fetchClasses({all: true}));
    // dispatch(fetchSubjects());
    // dispatch(fetchTargetGroups());
    dispatch(fetchDisciplines());
    dispatch(fetchCoreDisciplines());
    dispatch(fetchWorldsOfEngineering());
    dispatch(fetchBetaMentalities());
    dispatch(fetchProfessionalOrientations());
    dispatch(fetchWTCoreSubjectives());
    dispatch(fetchCenturySkills());
    // dispatch(fetchThemes());
  }, [dispatch]);

  const classesOptions = Classes?.map((classes) => {
    let name = classes.name;
    if (classes.deleted_at) {
      name += ` (${moment(classes.deleted_at).format('DD-MM-YYYY')})`
    } else if (moment(classes.end_date).isBefore(new Date())) {
      name += ` (${moment(classes.end_date).format('DD-MM-YYYY')})`
    }
    return {
      name,
      id: classes.id
    };
  });

  const statusOptions = Role === 'pupil' ? ["open", "draft", "pending", "done"] : ["open", "draft", "done"];

  const data = [
    {heading: t("Classes"), key: "classes", option: classesOptions},
    {heading: t("Status"), key: "status", option: statusOptions},
    // {heading: t("Subjects"), key: "subjects", option: Subjects},
    // {heading: t("Target Groups"), key: "targetGroups", option: TargetGroups},
    {heading: t("Disciplines"), key: "disciplines", option: Disciplines},
    {
      heading: t("Core Disciplines"),
      key: "coreDisciplines",
      option: CoreDisciplines
    },
    {
      heading: t("Worlds Of Engineering"),
      key: "worldsOfEngineerings",
      option: WorldsOfEngineering
    },
    {
      heading: t("Beta Mentalities"),
      key: "betaMentalities",
      option: BetaMentalities
    },
    {
      heading: t("Professional Orientations"),
      key: "professionalOrientations",
      option: ProfessionalOrientations
    },
    {
      heading: t("WT Core Subjectives"),
      key: "wtCoreSubjectives",
      option: WTCoreSubjectives
    },
    {
      heading: t("21st Century Skills"),
      key: "centurySkills",
      option: CenturySkills
    },
    // {heading: t("Themes"), key: "themes", option: Themes}
  ]
  const searchFilter = () => {
    let filterToSend = new URLSearchParams();
    Object.keys(saveState)
      .map(key =>
        saveState?.[key]?.length
        && saveState[key]
          .map(value =>
            filterToSend.append(key + "[]", encodeURIComponent(value)
            )
          )
      )
    dispatch(lessonsFilter(filterToSend.toString()))
    dispatch(props.searchClickCall)
  }
  return (<div className="row">
    <div className="col-12">
      <p className="font-weight-bold mt-5 mb-3 pt-2 menu-heading">
        {t("FILTER")}
      </p>
    </div>
    <div className="col-12">
      <div className="new-button">
        <div className="filter card">
          <div className="py-3 pl-3">
            {data.map((data2, i) => <AccordionCpllaps data={data2} key={i}
                                                      saveState={saveState}
                                                      setSaveState={setSaveState}/>)}
          </div>
          <div className="card-body text-center">
            <button className="btn search-btn btn-primary" onClick={searchFilter}>
              {t("Zoeken")}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default Filters;
