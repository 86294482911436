import React, { useState, useEffect, Fragment } from "react";
import {withRouter} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Heading
} from "../../component";
import Loader from "../../component/Loader";
import {fetchPortfolioDetails} from "../../redux/slices/pupilSlice";
import { Card } from 'react-bootstrap';
import FsLightbox from 'fslightbox-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload
} from "@fortawesome/free-solid-svg-icons";

const PortfolioDetails = (props) => {
  const [t,] = useTranslation();
  const dispatch = useDispatch();
  const portfolioDetails = useSelector((state) => state.pupil.portfolioDetails);
  const { id } = props;
  const loader = useSelector((state) => state.pupil.loader);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    sourceIndex: null
  });

  const download = <FontAwesomeIcon icon={faDownload} />;

  const images = portfolioDetails?.portfolio_attachments?.filter(attachment => (attachment.type === 'image' && attachment.status !== "declined"));
  const documentList =
  portfolioDetails?.portfolio_attachments?.length > 0 &&
    portfolioDetails.portfolio_attachments.map((doc) => {
      return doc.type === "file" ? (
        <li key={doc.id}><a href={doc.attachment_url} className="color-grayish text-underline" target="_blank" download rel="noreferrer">{download} {doc.file_name || "geen naam"}</a></li>
      ) : (
        false
      );
    }).filter(doc => doc);

  useEffect(() => {
    dispatch(fetchPortfolioDetails(id));
  }, [dispatch, id]);

  const openLightboxOnSlide = (index) => {
    setLightboxController({
    toggler: !lightboxController.toggler,
    sourceIndex: index
    });
  }
    
  return ( <Fragment>
      {loader &&  <div className="lesson-loader"><Loader/></div>}
    {portfolioDetails && Object.keys(portfolioDetails).length > 0 && (
    <Fragment>
      {images?.length > 0 &&
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={images.map(img => { return (img.attachment_url) })}
          sourceIndex={lightboxController.sourceIndex}
          type="image"
        />
      }
        <div className="row mt-4 color-grayish">
          <div className="col-10 offset-1">
            <div className="row">
              <div className="col-8 offset-2 mt-3">
                <Heading color="#505050">{portfolioDetails.name}</Heading>
                <Card className="bg-white">
                  <Card.Body>
                    <div className="row mt-3">
                      <div className="col-12">
                        <h3 className="lesson-description">{t('Description')}</h3>
                        <p>{portfolioDetails?.description}</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6 offset-3">
                      { portfolioDetails?.image && <img src={portfolioDetails.image_url} width="100%" alt=""/>}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-12">
                      <h3 className="lesson-description">{t('Attachments')}</h3>
                      <ul className="list-unstyled">
                        {documentList}
                      </ul>
                      {!(portfolioDetails?.portfolio_attachments?.filter(attachment => (attachment.type === 'file' && attachment.status !== "declined")).length) &&
                        <p>{t('Attachment not found')}</p>
                      }
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>

        <div className="row color-grayish">
          <div className="col-10 offset-1">
            <div className="row">
              <div className="col-8 offset-2 mt-3">
                <Heading className="mt-5" color="#505050">{t('Gellary')}</Heading>
                <Card className="bg-white">
                  <Card.Body>
                    <div className="row">
                      {(images || []).map((image, index) => {
                        return (
                          <div key={index} className="col-4 mb-3">
                            <img style={{ cursor: "pointer" }} onClick={() => openLightboxOnSlide(index)} src={image.attachment_url} width="100%" alt="" />
                          </div>
                        )
                      })
                      }
                      {!images?.length &&
                        <div className="col-12">
                          <p>{t('Image not found')}</p>
                        </div>
                      }
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Fragment>)}
    </Fragment>
  ) 
};

export default withRouter(PortfolioDetails);
