import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchClasses,
  // fetchSubjects,
  // fetchTargetGroups,
  fetchDisciplines,
  fetchCoreDisciplines,
  fetchWorldsOfEngineering,
  fetchBetaMentalities,
  fetchProfessionalOrientations,
  fetchWTCoreSubjectives,
  fetchCenturySkills,
  // fetchThemes,
  fetchSimilarLessonPupils,
} from "../../redux/slices/lessonSlice";
import { MultiSelect } from "../../component/Input/MultiSelect";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

function CreateLessonStep2({
  classId,
  setSelectClassPupils,
  setClassId,
  // subjects,
  // setSubjects,
  // targetGroups,
  // setTargetGroups,
  disciplines,
  setDisciplines,
  coreDisciplines,
  setCoreDisciplines,
  worldsOfEngineering,
  setWorldsOfEngineering,
  betaMentalities,
  setBetaMentalities,
  professionalOrientations,
  setProfessionalOrientations,
  wTCoreSubjectives,
  setWTCoreSubjectives,
  centurySkills,
  setCenturySkills,
  // themes,
  // setThemes,
  setSchoolId,
  setStep,
  isEdit=false
}) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const Classes = useSelector((state) => state.lesson.classes);
  // const Subjects = useSelector((state) => state.lesson.subjects);
  // const TargetGroups = useSelector(state => state.lesson.targetGroups);
  const Disciplines = useSelector(state => state.lesson.disciplines);
  const CoreDisciplines = useSelector(state => state.lesson.coreDisciplines);
  const WorldsOfEngineering = useSelector(state => state.lesson.worldsOfEngineering);
  const BetaMentalities = useSelector(state => state.lesson.betaMentalities);
  const ProfessionalOrientations = useSelector((state) => state.lesson.professionalOrientations);
  const WTCoreSubjectives = useSelector(state => state.lesson.wTCoreSubjectives);
  const CenturySkills = useSelector(state => state.lesson.centurySkills);
  // const Themes = useSelector((state) => state.lesson.themes);

  const similarLessonPupils = useSelector((state) => state.lesson.similarLessonPupils)
  const [totalPupils, setTotalPupils] = useState("")
  useEffect(() => {
    dispatch(fetchClasses());
    // dispatch(fetchSubjects());
    // dispatch(fetchTargetGroups());
    dispatch(fetchDisciplines());
    dispatch(fetchCoreDisciplines());
    dispatch(fetchWorldsOfEngineering());
    dispatch(fetchBetaMentalities());
    dispatch(fetchProfessionalOrientations());
    dispatch(fetchWTCoreSubjectives());
    dispatch(fetchCenturySkills());
    // dispatch(fetchThemes());
  }, [dispatch]);
  // const SubjectsOptions = Subjects?.map((Subject) => {
  //   return { label: Subject.name, value: Subject.id };
  // });
  // const TargetGroupsOptions = TargetGroups?.map((TargetGroup) => {
  //   return { label: TargetGroup.name, value: TargetGroup.id };
  // });
  const DisciplinesOptions = Disciplines?.map((Discipline) => {
    return { label: Discipline.name, value: Discipline.id };
  });
  const CoreDisciplinesOptions = CoreDisciplines?.map((CoreDiscipline) => {
    return { label: CoreDiscipline.name, value: CoreDiscipline.id };
  });
  const WorldsOfEngineeringOptions = WorldsOfEngineering?.map((WorldsOfEngineering) => {
    return { label: WorldsOfEngineering.name, value: WorldsOfEngineering.id };
  });
  const BetaMentalitiesOptions = BetaMentalities?.map((BetaMentality) => {
    return { label: BetaMentality.name, value: BetaMentality.id };
  });
  const ProfessionalOrientationsOptions = ProfessionalOrientations?.map((ProfessionalOrientation) => {
    return {
      label: ProfessionalOrientation.name,
      value: ProfessionalOrientation.id,
    };
  });
  const WTCoreSubjectivesOptions = WTCoreSubjectives?.map((WTCoreSubjective) => {
    return { label: WTCoreSubjective.name, value: WTCoreSubjective.id };
  });
  const CenturySkillsOptions = CenturySkills?.map((CenturySkill) => {
    return { label: CenturySkill.name, value: CenturySkill.id };
  });
  // const ThemesOptions = Themes?.map((Theme) => {
  //   return { label: Theme.name, value: Theme.id };
  // });

  useEffect(() => {
    const selectedClass = Classes.find(classes => classes.id === parseInt(classId));
    setSchoolId(selectedClass?.school_id);
    setTotalPupils(selectedClass?.pupils?.length)
    if(selectedClass?.pupils?.length){
      setSelectClassPupils([...selectedClass.pupils])
    }
  }, [classId, Classes, setSchoolId, setSelectClassPupils]);

  useEffect(() => {
    const data = {
      class: parseInt(classId),
      // subjects: subjects.map((item) => item.value),
      betaMentalities: betaMentalities.map((item) => item.value),
      centurySkills: centurySkills.map((item) => item.value),
      coreDisciplines: coreDisciplines.map((item) => item.value),
      disciplines: disciplines.map((item) => item.value),
      professionalOrientations: professionalOrientations.map(
        (item) => item.value
      ),
      // targetGroups: targetGroups.map((item) => item.value),
      // themes: themes.map((item) => item.value),
      worldOfEngineerings: worldsOfEngineering.map((item) => item.value),
      wtCoreSubjectives: wTCoreSubjectives.map((item) => item.value),
    };
    if
      (classId &&
      (
        // subjects.length ||
        betaMentalities.length ||
        centurySkills.length ||
        coreDisciplines.length ||
        disciplines.length ||
        professionalOrientations.length ||
        // targetGroups.length ||
        // themes.length ||
        worldsOfEngineering.length ||
        wTCoreSubjectives.length)) {
      dispatch(fetchSimilarLessonPupils(data));
    }
  }, [dispatch,
    classId,
    // subjects,
    // targetGroups,
    disciplines,
    coreDisciplines,
    worldsOfEngineering,
    betaMentalities,
    professionalOrientations,
    wTCoreSubjectives,
    centurySkills,
    // themes,
  ]);
  return (
    <div className="mt-5">

      <>
        <h4 className="modal-title d-flex justify-content-center mb-4">{t('Nieuwe les maken')}</h4>
        <ProgressBar percent={50}>
          <Step>{({ accomplished, index }) => (<div className={`indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</div>)}</Step>
          <Step>{({ accomplished, index }) => (<div className={`indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</div>)}</Step>
          <Step>{({ accomplished, index }) => (<div className={`indexedStep ${accomplished ? "accomplished" : null}`}>{index + 1}</div>)}</Step>
        </ProgressBar>
      </>

      <div className="row mt-4">
        <div className="col-12">
          <select
            className="w-100 mb-2 mt-3 p-2"
            name="role"
            value={classId}
            onChange={(e) => setClassId(e.target.value)}
            disabled={isEdit}
          >
            <option value="" disabled>
              {t('Select a class')}
            </option>
            {(Classes || []).map((classes) => (
              <option key={classes.id} value={classes.id}>
               {classes.school?.name}-{t(classes.name)}
              </option>
            ))}
          </select>
        </div>
        {/*<div className="col-6">
          <label className="col-form-label">{t('Subject')}</label>
          <MultiSelect
            className="mb-2"
            options={SubjectsOptions ? SubjectsOptions : []}
            value={subjects}
            onChange={setSubjects}
            labelledBy="Select"
          />
        </div>
        <div className="col-6">
          <label className="col-form-label">{t('Target Groups')}</label>
          <MultiSelect
            className="mb-2"
            options={TargetGroupsOptions ? TargetGroupsOptions : []}
            value={targetGroups}
            onChange={setTargetGroups}
            labelledBy="Select"
          />
        </div>
        */}
        <div className="col-6">
          <label className="col-form-label">{t('Disciplines')}</label>
          <MultiSelect
            className="mb-2"
            options={DisciplinesOptions ? DisciplinesOptions : []}
            value={disciplines}
            onChange={setDisciplines}
            labelledBy="Select"
          />
        </div>
        <div className="col-6">
          <label className="col-form-label">{t('Core Disciplines')}</label>
          <MultiSelect
            className="mb-2"
            options={CoreDisciplinesOptions ? CoreDisciplinesOptions : []}
            value={coreDisciplines}
            onChange={setCoreDisciplines}
            labelledBy="Select"
          />
        </div>
        <div className="col-6">
          <label
            className="col-form-label">{t('Worlds Of Engineering')}</label>
          <MultiSelect
            className="mb-2"
            options={WorldsOfEngineeringOptions ? WorldsOfEngineeringOptions : []}
            value={worldsOfEngineering}
            onChange={setWorldsOfEngineering}
            labelledBy="Select"
          />
        </div>
        <div className="col-6">
          <label className="col-form-label">{t('Beta Mentalities')}</label>
          <MultiSelect
            className="mb-2"
            options={BetaMentalitiesOptions ? BetaMentalitiesOptions : []}
            value={betaMentalities}
            onChange={setBetaMentalities}
            labelledBy="Select"
          />
        </div>
        <div className="col-6">
          <label
            className="col-form-label">{t('Professional Orientations')}</label>
          <MultiSelect
            className="mb-2"
            options={
              ProfessionalOrientationsOptions
                ? ProfessionalOrientationsOptions
                : []
            }
            value={professionalOrientations}
            onChange={setProfessionalOrientations}
            labelledBy="Select"
          />
        </div>
        <div className="col-6">
          <label className="col-form-label">{t('WT Core Subjectives')}</label>
          <MultiSelect
            className="mb-2"
            options={WTCoreSubjectivesOptions ? WTCoreSubjectivesOptions : []}
            value={wTCoreSubjectives}
            onChange={setWTCoreSubjectives}
            labelledBy="Select"
          />
        </div>
        <div className="col-6">
          <label className="col-form-label">{t('21st Century Skills')}</label>
          <MultiSelect
            className="mb-2"
            options={CenturySkillsOptions ? CenturySkillsOptions : []}
            value={centurySkills}
            onChange={setCenturySkills}
            labelledBy="Select"
          />
        </div>
        {/*<div className="col-6">
          <label className="col-form-label">{t('Themes')}</label>
          <MultiSelect
            className="mb-2"
            options={ThemesOptions ? ThemesOptions : []}
            value={themes}
            onChange={setThemes}
            labelledBy="Select"
          />
        </div>*/}
      </div>
      {similarLessonPupils.length ?
        <div className="justify-content-center d-flex my-2"><p
          className="mb-0 text-primary">{`${similarLessonPupils.length} ${t('similarLessonPupils1')} ${totalPupils} ${t('similarLessonPupils2')}`}</p>
        </div> : ""}
      <div className="d-flex justify-content-around mb-5 mt-3">
      <Button variant="outline-primary"
                onClick={() => setStep(1)}>{t('Back')}</Button>
        <Button onClick={() => setStep(3)}  data-toggle="tooltip" data-placement="top" title={ !classId ? t('disable_step') : "" } disabled={classId ? false : true}>{t("Next")}</Button>
      </div>
    </div>
  );
}

export default CreateLessonStep2;
