import {call, put, takeEvery} from 'redux-saga/effects'
import Api from "../lib/Api";
import {
  //  errorsCleared,
  showNotification,
} from "../redux/slices/appSlice";
import i18n from '../i18n/i18n'

import {
  currentLessonSubmissionFetched,
  fetchLessonSubmission,
  fetchLessonSubmissionList, fetchMyLessonSubmission, gradeLessonSubmission,
  lessonSubmissionsFetched,
  pageChanged,
  totalItemsChanged
} from "../redux/slices/lessonSubmissionSlice";

function* fetchLessonSubmissionListSaga(action) {
  const {lessonId, page = 1, searchContent = ""} = action.payload

  let response = yield call(Api.get, `/api/lessons/${lessonId}/submissions?page=${page}&search=${searchContent}`);
  if (response?.data?.data) {
    yield put(lessonSubmissionsFetched(response.data.data || []))
    yield put(pageChanged(response.data.current_page))
    yield put(totalItemsChanged(response.data.total))
  } else {
    yield put(showNotification([i18n.t("Submission list could not be fetched for this lesson"), "error"]));
  }
}

function* fetchCurrentLessonSubmissionSaga(action) {
  const {lessonId, submissionId} = action.payload

  const response = yield call(Api.get, `/api/lessons/${lessonId}/submissions/${submissionId}`)
  if (response.data) {
    yield put(currentLessonSubmissionFetched(response.data || []))
  } else {
    yield put(showNotification([i18n.t("Submission details could not be fetched"), "error"]));
  }
}

function* fetchMyLessonSubmissionSaga(action) {
  const {lessonId} = action.payload

  const response = yield call(Api.get, `/api/lessons/${lessonId}/submissions/mine`)
  if (response.data) {
    yield put(currentLessonSubmissionFetched(response.data || []))
  } else {
    yield put(showNotification([i18n.t("Submission details could not be fetched"), "error"]));
  }
}

function* gradeLessonSubmissionSaga(action) {
  const {lessonId, submissionId, grade, gradeComment} = action.payload

  const response = yield call(Api.put, `/api/lessons/${lessonId}/submissions/${submissionId}/grade`, {grade, grade_comment: gradeComment})

  if (response.data) {
    yield put(fetchLessonSubmission({lessonId, submissionId}))
  } else {
    yield put(showNotification([i18n.t("Submission could not be graded"), "error"]));
  }
}

function* lessonSubmissionSaga() {
  yield takeEvery(fetchLessonSubmissionList.type, fetchLessonSubmissionListSaga)
  yield takeEvery(fetchLessonSubmission.type, fetchCurrentLessonSubmissionSaga)
  yield takeEvery(fetchMyLessonSubmission().type, fetchMyLessonSubmissionSaga)
  yield takeEvery(gradeLessonSubmission.type, gradeLessonSubmissionSaga)
}

export default lessonSubmissionSaga
