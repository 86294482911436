import React, { useState, useEffect, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClock,
  faDownload,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import AddImageOrFile from "./AddImageOrFile";
import FinishGradeModal from "./FinishGradeModal";
import PublishLesson from "./PublishLesson";
import { Heading, Dialog } from "../../component";
import Loader from "../../component/Loader";
import {
  fetchLessonDetails,
  submitDraft,
  DeleteAttechmentFile,
} from "../../redux/slices/lessonSlice";
import { Card } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import Ribbon from "../../component/Lesson/Ribbon";

const LessonDetails = (props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const loginUserId = useSelector((state) => state.auth?.user?.id);
  const lessonDetails = useSelector((state) => state.lesson.lessonsDetails);
  const pupilId = useSelector((state) => state.auth?.user.pupil?.id);
  const role = useSelector((state) => state.auth?.user?.roles?.[0]?.name);
  const loader = useSelector((state) => state.lesson.loader);
  const { id, history } = props;
  const [showFileUploader, setShowFileUploader] = useState("");
  const [showPublishLessonModal, setShowPublishLessonModal] = useState(false);
  const [showFinishGradeModal, setShowFinishGradeModal] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    sourceIndex: null,
  });

  // pupil submission states
  const [description, setDescription] = useState(false);
  const hasDraftSubmission =
    lessonDetails?.attachments?.filter((lesson) => lesson.status === "draft")
      ?.length > 0;

  const download = <FontAwesomeIcon icon={faDownload} />;
  const deletIcon = <FontAwesomeIcon icon={faTrashAlt} />;
  const check = <FontAwesomeIcon icon={faCheck} />;
  const clock = <FontAwesomeIcon icon={faClock} />;

  useEffect(() => {
    dispatch(fetchLessonDetails(id));
  }, [dispatch, id]);

  const deleteAttechment = (lessonId, attachmentId) => {
    const doDelete = window.confirm(
      t("Are you sure that you want to delete Attachment ?")
    );
    if (doDelete) {
      dispatch(DeleteAttechmentFile({ lessonId, attachmentId }));
    }
  };

  const images = lessonDetails?.attachments?.filter(
    (attachment) => attachment.type === "image" && attachment.user_id === loginUserId
  );

  const taxonomies = [
    // "subjects",
    // "target_groups",
    "beta_mentalities",
    "century_skills",
    "core_disciplines",
    "disciplines",
    "professional_orientations",
    "world_of_engineerings",
    "w_t_core_subjectives",
    // "themes",
  ];
  const taxonomiesHeading = [
    // "Subjects",
    // "Target Groups",
    "Beta Mentalities",
    "Century Skills",
    "Core Disciplines",
    "Disciplines",
    "Professional Orientations",
    "World Of Engineerings",
    "WT Core Subjectives",
    // "Themes",
  ];

  const taxanomieList = taxonomies.map((taxonomie, index) => {
    if (lessonDetails[taxonomie] && lessonDetails[taxonomie].length) {
      return (
        <div key={taxonomie + index} className="mb-2">
          <strong>{t(taxonomiesHeading[index])}</strong>
          {lessonDetails[taxonomie].map((el, indx) => {
            return (
              <div key={taxonomie + index + indx}>
                {el.name}
                {lessonDetails[taxonomie].length > indx + 1 ? "," : ""}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  });

  const documentList =
    lessonDetails?.attachments &&
    lessonDetails.attachments
      .map((doc) => {
        return doc.type === "file" &&  doc.user_id === loginUserId ? (
          <li key={doc.id}>
            <a
              href={doc.attachment_url}
              className="color-grayish text-underline"
              target="_blank"
              download
              rel="noreferrer"
            >
              {download} {doc.file_name || "geen naam"}
            </a>{" "}
            <sup
              role="button"
              className="cursor"
              onClick={() => deleteAttechment(doc.lesson_id, doc.id)}
            >
              {deletIcon}
            </sup>
          </li>
        ) : (
          false
        );
      })
      .filter((doc) => doc);

  const onSubmitDraft = () => {
    dispatch(submitDraft({ lessonId: id, pupilId, description }));
  };

  const openLightboxOnSlide = (index) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      sourceIndex: index,
    });
  };

  return (
    <Fragment>
      {loader && (
        <div className="lesson-loader">
          <Loader />
        </div>
      )}
      {Object.keys(lessonDetails).length > 0 && (
        <Fragment>
          {images.length > 0 && (
            <FsLightbox
              toggler={lightboxController.toggler}
              sources={images.map((img) => {
                return process.env.REACT_APP_API_URL + img.path;
              })}
              sourceIndex={lightboxController.sourceIndex}
              type="image"
              key={showFileUploader}
            />
          )}
          <Dialog
            isVisible={!!showFileUploader}
            setIsVisible={setShowFileUploader}
            size={"md"}
          >
            <AddImageOrFile
              type={showFileUploader}
              setIsVisible={setShowFileUploader}
              lessonId={id}
            />
          </Dialog>
          <Dialog
            isVisible={showPublishLessonModal}
            setIsVisible={setShowPublishLessonModal}
            size={"md"}
          >
            <PublishLesson
              lessonId={id}
              setIsVisible={setShowPublishLessonModal}
            />
          </Dialog>
          <Dialog
            isVisible={showFinishGradeModal}
            setIsVisible={setShowFinishGradeModal}
            size={"lg"}
          >
            <FinishGradeModal
              lessonId={id}
              classId={lessonDetails?.["class"]?.["id"]}
              schoolId={lessonDetails?.["class"]?.["school_id"]}
              setIsVisible={setShowFinishGradeModal}
            />
          </Dialog>
          <div className="row mt-4 color-grayish">
            {hasDraftSubmission && (
              <div className="col-10 offset-1 mt-5">
                <div className="mb-0 alert alert-warning">
                  {t("submit work error")}
                </div>
              </div>
            )}
            <div className="col-10 offset-1">
              <div className="row">
                <div className="col-4 mt-5">
                  <Heading color="#505050">{t("EXTRA")}</Heading>
                  <Card className="bg-white">
                    <Card.Body>
                      {lessonDetails.submissions.length > 0 &&
                        role === "teacher" && (
                          <div>
                            <strong>{t("Submissions")}</strong>
                            <ul className="list-unstyled">
                              {lessonDetails.submissions.map((submission) => {
                                const color =
                                  submission.status === "accepted"
                                    ? "green"
                                    : "primary";
                                return (
                                  <li key={`sub-${submission.id}`}>
                                    <Link
                                      to={`/lesson/${lessonDetails.id}/submission/${submission.id}`}
                                      className={color}
                                    >
                                      {submission.status === "accepted"
                                        ? check
                                        : clock}{" "}
                                      <span>
                                        {t("Submission of pupil", {
                                          pupil: submission?.pupil?.user?.name,
                                        })}
                                      </span>{" "}
                                      {submission.grade && (
                                        <span>
                                          ({t("grade")}: {submission.grade})
                                        </span>
                                      )}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      {lessonDetails.submissions.length > 0 &&
                        role === "pupil" && (
                          <div>
                            <strong>{t("My submission")}</strong>
                            <ul className="list-unstyled">
                              {lessonDetails.submissions.map((submission) => {
                                const color =
                                  submission.status === "accepted"
                                    ? "green"
                                    : "primary";
                                return (
                                  <li>
                                    <Link
                                      to={`/lesson/${lessonDetails.id}/submission`}
                                      style={{ color: color }}
                                    >
                                      {submission.status === "accepted"
                                        ? check
                                        : clock}{" "}
                                      <span>{t("Submission details")}</span>{" "}
                                      {submission.grade && (
                                        <span>
                                          ({t("grade")}: {submission.grade})
                                        </span>
                                      )}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      {taxanomieList}
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-8 mt-5">
                  <Heading color="#505050">{lessonDetails.name}</Heading>
                  <Card className="bg-white">
                    <Card.Body>
                      <Ribbon
                        status={lessonDetails?.status}
                        grade={lessonDetails?.grade?.name}
                      />
                      <div className="row">
                        <div className="col-6">
                          <p className="mb-0">
                            <strong>{t("School")}</strong>
                          </p>
                          <p>{lessonDetails?.class?.school?.name}</p>
                          <p className="mb-0">
                            <strong>{t("Compartment")}</strong>
                          </p>
                          {lessonDetails["disciplines"].map((el, indx) => {
                            return (
                              <span key={indx}>
                                {el.name}
                                {lessonDetails["disciplines"].length > indx + 1
                                  ? ", "
                                  : ""}
                              </span>
                            );
                          })}
                        </div>
                        <div className="col-6">
                          <p className="mb-0">
                            <strong>{t("Class")}</strong>
                          </p>
                          <p>{lessonDetails?.class?.name}</p>

                          <p className="mb-0">
                            <strong>{t("Lecturer")}</strong>
                          </p>
                          <p>{lessonDetails?.teacher?.user?.name}</p>
                        </div>
                        <div className="col-6">
                          <p className="mb-0">
                            <strong>{t("Pupil")}</strong>
                          </p>
                          {lessonDetails["pupils"] &&
                            lessonDetails["pupils"].map((el, indx) => {
                              return (
                                <span key={indx}>
                                  {el.user && el.user.name && (
                                    <Fragment>
                                      {" "}
                                      {el.user.name}
                                      {lessonDetails["pupils"].length > indx + 1
                                        ? ", "
                                        : ""}
                                    </Fragment>
                                  )}
                                </span>
                              );
                            })}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12">
                          <h3 className="lesson-description">
                            {t("Description")}
                          </h3>
                          <p>{lessonDetails?.description || "-"}</p>
                        </div>
                      </div>
                      {lessonDetails?.grade_comment && (
                        <div className="row mt-3">
                          <div className="col-12">
                            <h3 className="lesson-description">
                              {t("Comment from teacher")}
                            </h3>
                            <p>{lessonDetails?.grade_comment}</p>
                          </div>
                        </div>
                      )}
                      {lessonDetails?.image && (
                        <div className="row mt-3">
                          <div className="col-6 offset-3">
                            <img
                              src={`${process.env.REACT_APP_API_URL}${lessonDetails?.image}`}
                              width="100%"
                              alt="Featured"
                            />
                          </div>
                        </div>
                      )}
                      <div className="row mt-3">
                        <div className="col-12">
                          <h3 className="lesson-description">
                            {t("Attachments")}
                          </h3>
                          <ul className="list-unstyled">{documentList}</ul>
                          {!lessonDetails?.attachments.filter(
                            (attachment) => attachment.type === "file" && attachment.user_id === loginUserId
                          ).length && <p>{t("Attachment not found")}</p>}
                          {role === "teacher" &&
                            (lessonDetails?.status === "draft" ||
                              lessonDetails?.status === "open") && (
                              <button
                                onClick={() => setShowFileUploader("file")}
                                className="btn btn-primary mr-2"
                                style={{ borderRadius: "5px" }}
                              >
                                {t("Upload file")}
                              </button>
                            )}

                          {role === "pupil" &&
                            lessonDetails?.status === "open" && (
                              <button
                                onClick={() => setShowFileUploader("file")}
                                className="btn btn-primary mr-2"
                                style={{ borderRadius: "5px" }}
                              >
                                {t("Upload file")}
                              </button>
                            )}
                        </div>
                      </div>
                      {role === "teacher" && (
                        <div className="row mt-3">
                          <div className="col-12">
                            {lessonDetails?.status === "draft" && (
                              <>
                                <button
                                  onClick={() =>
                                    history.push(`/edit-lesson/${id}`)
                                  }
                                  className="btn bg-secondary mr-2"
                                  style={{ borderRadius: "5px" }}
                                >
                                  {t("Edit")}
                                </button>
                                <button
                                  onClick={() =>
                                    setShowPublishLessonModal(
                                      !showPublishLessonModal
                                    )
                                  }
                                  className="btn btn-primary"
                                  style={{ borderRadius: "5px" }}
                                >
                                  {t("Publish")}
                                </button>
                              </>
                            )}
                            {lessonDetails?.status === "open" && (
                              <button
                                onClick={() =>
                                  setShowFinishGradeModal(!showFinishGradeModal)
                                }
                                className="btn btn-primary"
                                style={{ borderRadius: "5px" }}
                              >
                                {t("Finish and grade lesson")}
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>

                  <Heading className="mt-5" color="#505050">
                    {t("Gellary")}
                  </Heading>
                  <Card className="bg-white">
                    <Card.Body>
                      <div className="row">
                        {(images || []).map((image, index) => {
                          return (
                            <div key={index} className="col-4 mb-3">
                              <img
                                style={{ cursor: "pointer" }}
                                onClick={() => openLightboxOnSlide(index)}
                                src={`${process.env.REACT_APP_API_URL}${image.path}`}
                                width="100%"
                                alt=""
                              />
                            </div>
                          );
                        })}
                        {!images.length && (
                          <div className="col-12">
                            <p>{t("Image not found")}</p>
                          </div>
                        )}
                      </div>
                      {role === "teacher" &&
                        (lessonDetails?.status === "draft" ||
                          lessonDetails?.status === "open") && (
                          <div className="col-12 pl-0">
                            <button
                              onClick={() => setShowFileUploader("image")}
                              className="btn btn-primary"
                              style={{ borderRadius: "5px" }}
                            >
                              {t("Upload image")}
                            </button>
                          </div>
                        )}

                      {role === "pupil" && lessonDetails?.status === "open" && (
                        <div className="col-12 pl-0">
                          <button
                            onClick={() => setShowFileUploader("image")}
                            className="btn btn-primary"
                            style={{ borderRadius: "5px" }}
                          >
                            {t("Upload image")}
                          </button>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                  {role === "pupil" && hasDraftSubmission && (
                    <div className="mt-5">
                      <Heading>{t("Submit your work")}</Heading>
                      <Card className="bg-white">
                        <Card.Body>
                          <div>
                            <div className="form-group w-75 mt-4">
                              <label htmlFor="description-field">
                                {t("Description")}
                              </label>
                              <textarea
                                onChange={(e) => setDescription(e.target.value)}
                                id="description-field"
                                className="form-control"
                                style={{ height: "200px" }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="mt-5">
                            <button
                              onClick={onSubmitDraft}
                              style={{ borderRadius: "5px" }}
                              className="btn btn-primary"
                            >
                              {t("Submit work")}
                            </button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withRouter(LessonDetails);
