import React,{ useState } from 'react';
import { Accordion, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
function AccordionCpllaps(props){
    const [t,] = useTranslation();
    const caretRight = <FontAwesomeIcon icon={faCaretRight}/>;
    const caretDown = <FontAwesomeIcon icon={faCaretDown}/>;
    const [rotate1, setRotate1] = useState(false);
    const toggler =(e)=>{
      setRotate1(!rotate1)
    }
    const setFilterById = (checked, value) => {
      if(checked){
        props.setSaveState({
          ...(props?.saveState || {}),
          [props?.data?.key ||""]:(props?.saveState?.[props?.data?.key ||""] || [])?.concat(value)
        })
      }else{
        props.setSaveState({
          ...(props?.saveState || {}),
          [props?.data?.key ||""]:(props?.saveState?.[props?.data?.key ||""]||[])?.filter(inst=>inst !== value)
        });
      }
    }
    return(
        <Accordion className="">
              <Accordion.Toggle
                as={Card}
                variant="link"
                eventKey="0"
                className="accordion"
                onClick={(e) =>toggler(e) }
              ><div className="py-2">{rotate1 === true ?caretDown:caretRight}{` ${props.data.heading}`}</div>
              </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="py-0">
            {
              props.data.option?.length > 0 && props.data.option.map((option,i)=>{
               return <div key={i}>
                 <label>
                   <input className="mr-2"
                          type="checkbox" id={i} name={option.id || option}
                          checked={props?.saveState?.[props?.data?.key || ""]?.some?.(inst => inst === (option.id || option)) || false}
                          onChange={(e) => {
                            setFilterById(e.target.checked, option.id || option)
                          }}/>
                   {t(option.name || option)}</label>
               </div>
              })
            }
            </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    )
}
export default AccordionCpllaps
