import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { MultiSelect } from "../../component/Input/MultiSelect";
import { fetchPupils } from "../../redux/slices/pupilSlice";
import { addPupilsToClass } from "../../redux/slices/classSlice";

function AddPupilsModal({ setIsVisible = () => {}, classId, schoolId }) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const pupils = useSelector(state => state.pupil.pupils)
    const [newPupils, setNewPupils] = useState([])

    const pupilOptions = pupils?.filter((pupil) => {
        let valid = true;
        pupil.classes.forEach(c => {
            if(c.id === Number(classId)) {
                valid = false;
            }
        });
        return valid;
    }).map((pupil) => {
        return { label: pupil.user.first_name + " " + pupil.user.last_name, value: pupil.id };
    });
    
    const handleSubmit = () => {
        let data = {
            class_id: classId,
            pupils: newPupils.map(p => p.value)
        }
        dispatch(addPupilsToClass(data));
    };

    const resetStates = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        dispatch(fetchPupils(schoolId))
    }, [schoolId, dispatch])

    return (
        <div className="p-3">
            <h4 className="modal-title d-flex justify-content-center text-capitalize">
                {t(`Add pupils to class`)}
            </h4>

            <div>
                <label className="col-form-label">{t('Select Pupils')}</label>
                <MultiSelect
                    className="mb-2"
                    options={pupilOptions ? pupilOptions : []}
                    value={newPupils}
                    onChange={setNewPupils}
                    labelledBy="Select"
                />
            </div>
            
            
            <div className="d-flex justify-content-between mt-3">
                <Button onClick={resetStates}>{t("Close")}</Button>
                <Button onClick={handleSubmit}>{t("Submit")}</Button>
            </div>
        </div>
    );
}
export default AddPupilsModal;
