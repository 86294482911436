import {React, useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {fetchModereren} from "../../redux/slices/lessonSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import { faImage, faCheck, faTimes, faFile } from "@fortawesome/free-solid-svg-icons";
import ModererenView from '../popup/ModererenView.jsx';
import { changeStatusModereren } from "../../redux/slices/lessonSlice";

const ModererenList = () => {
    const [t, ] = useTranslation();
    const dispatch = useDispatch();
    const modereren = useSelector((state) => state.lesson.modereren);
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    useEffect(() => {
        dispatch(fetchModereren())
    }, [dispatch])
    const handleChangeStatus = (event, status, lessonId, attachmentId) => {
        event.stopPropagation();
        dispatch(changeStatusModereren({ lessonId: lessonId, attachmentId:attachmentId, status: status }));
    };
    const Image = <FontAwesomeIcon icon={faImage}/>;
    const Check = <FontAwesomeIcon icon={faCheck}/>;
    const Incorrect = <FontAwesomeIcon icon={faTimes}/>;
    const File = <FontAwesomeIcon icon={faFile}/>;

    return (
        <div className="col-12">
            {show ? <ModererenView show={show} onHide={()=>{setShow(false)}} selectedvalue={selectedData} handleChangeStatus={handleChangeStatus}/> : ""}
            <div className="row pb-5">
                <div className="col-12">
                    <p className="font-weight-bold mt-5 modereren-heading mb-0">{t('Moderate')}</p>
                </div>

                {modereren?.length !== 0 ? (modereren || []).map((inst, index)=>(
                    <div className="col-md-4 col-6 mb-2 d-flex" key={index}>
                        <div className="modereren-card w-100" onClick={()=>{setShow(true); setSelectedData(inst)}}>
                            <div className="modereren-card-body">
                                <div className="row">
                                    <div className="col-md-9 col-6 ellipsis-style">{(inst?.path.split('/'))[inst?.path.split('/').length - 1] || ""}</div>
                                    <div className="col-md-3 col-6">
                                        <div className="float-right icon-primary" style={{fontSize: "20px", color: "rgb(244, 125, 68)", cursor:"pointer"}} title={inst.type}>{inst.type === "image" ? Image : File}</div>
                                    </div>
                                    <div className="col-12 ellipsis-style">{inst?.user?.name || <span>&nbsp;</span>}</div>
                                    <div className="col-md-8 col-12 ellipsis-style">{inst?.lesson?.name || <span>&nbsp;</span>}</div>
                                    <div className="col-md-4 col-12">
                                        <div className="float-right">
                                            <span className="mr-2 icon-primary" onClick={(event)=>handleChangeStatus(event, "public", inst?.lesson_id, inst?.id)} style={{fontSize: "20px", color: "rgb(244, 125, 68)"}} title={t('Accept')}>{Check}</span>
                                            <span className="icon-primary"  style={{fontSize: "20px", color: "rgb(244, 125, 68)"}} onClick={(event)=>handleChangeStatus(event, "declined", inst?.lesson_id, inst?.id)} title={t('Decline')}>{Incorrect}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : <div className="col-12">
                        <p>{t('At this moment, there are no attachments to moderate')}</p>
                    </div>
                }
            </div>
        </div>
    );
}

export default ModererenList;
