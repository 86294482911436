import { React, useEffect } from "react";
import Nav from "../../component/Nav/index";
import Routes from "../../routes";
import Footer from "../../component/Footer/Footer";
import { useDispatch } from "react-redux";
import { pageChanged } from "../../redux/slices/appSlice";
import { useLocation } from 'react-router-dom';

function TeacherDashboard() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
      dispatch(pageChanged(location.pathname))
  },[location.pathname, dispatch]);

  return (
    <div className="teacher-dashboard">
      <Nav />
      <div className="container">
        <Routes />
      </div>
      <div className="container-fluid">
        <Footer />
      </div>
    </div>
  );
}
export default TeacherDashboard;
