import React from 'react';
import List from '../component/list';
import { componentName } from '../component/Constant';

const SubjectList = () => {
    return (
       <List componentName={componentName.subject}/>
    )
}

export default SubjectList
