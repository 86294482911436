import {call, put, takeEvery, select} from 'redux-saga/effects'
import Api from "../lib/Api";
import {
  fetchModerates,
  pageChanged,
  totalItemsChanged,
  moderatesFetched,
  updateModerate,
  fetchPortfolioModerates,
  updatePortfolioModerate,
  portfolioModeratesFetched,
  portfolioPageChanged,
  portfolioTotalItemsChanged
} from "../redux/slices/moderateSlice";
import {showNotification} from "../redux/slices/appSlice";
import i18n from "../i18n/i18n";

function* fetchModeratesSaga(action) {
  let response = false;
  if (action.payload.searchContent) {
    response = yield call(Api.get, `/api/moderates?page=${action.payload.page}&search=${action.payload.searchContent}`)
  } else {
    response = yield call(Api.get, `/api/moderates?page=${action.payload}`)
  }
  if (response?.data?.data) {
    yield put(moderatesFetched(response.data.data || []))
    yield put(pageChanged(response.data.current_page))
    yield put(totalItemsChanged(response.data.total))
  } else {
    yield put(showNotification([i18n.t('Unfortunately there was an error fetching the moderates from the server.'),"error"]))
  }
}

function* updateModerateSaga(action) {
  const response = yield call(Api.put, `/api/lessons/${action.payload.lessonId}/attachments/${action.payload.attachmentId}`, action.payload)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Updating has not succeeded.'),"error"]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)))
  } else {
    console.log('check')
    const page = yield select(state => state.moderate.page)
    yield put(fetchModerates(page))
  }
}

function* fetchPortfolioModeratesSaga(action) {
  let response = false;
  if (action.payload?.searchContent) {
    response = yield call(Api.get, `/api/portfolio-moderates?page=${action.payload.page}&search=${action.payload.searchContent}`)
  } else {
    response = yield call(Api.get, `/api/portfolio-moderates?page=${action.payload}`)
  }
  if (response?.data?.data) {
    yield put(portfolioModeratesFetched(response.data.data || []))
    yield put(portfolioPageChanged(response.data.current_page))
    yield put(portfolioTotalItemsChanged(response.data.total))
  } else {
    yield put(showNotification([i18n.t('Unfortunately there was an error fetching the portfolio moderates from the server.'),"error"]))
  }

}

function* updatePortfolioModerateSaga(action) {
  const response = yield call(Api.put, `/api/portfolio-items/${action.payload.portfolioId}/attachments/${action.payload.attachmentId}`, action.payload)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Updating has not succeeded.'),"error"]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)))
  } else {
    const portfolioPage = yield select(state => state.moderate.portfolioPage)
    yield put(fetchPortfolioModerates(portfolioPage))
  }
}


function* moderateSaga() {
  yield takeEvery(fetchModerates.type, fetchModeratesSaga)
  yield takeEvery(updateModerate.type, updateModerateSaga)
  yield takeEvery(fetchPortfolioModerates.type, fetchPortfolioModeratesSaga)
  yield takeEvery(updatePortfolioModerate.type, updatePortfolioModerateSaga)
}

export default moderateSaga
