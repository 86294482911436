import React, { useState } from "react";
import CreateLessonStep2 from "./CreateLessonStep2";
import CreateLessonStep3 from "./CreateLessonStep3";
import CreateLessonStep1 from "./CreateLessonStep1";
import { createLesson } from "../../redux/slices/lessonSlice";
import { useTranslation } from "react-i18next";
import {useDispatch,} from "react-redux";

function CreateLessonPopup() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [step,setStep] = useState(1)
  //----------------states for step-1--------------------
  const [subjects, setSubjects] = useState([]);
  const [targetGroups, setTargetGroups] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [coreDisciplines, setCoreDisciplines] = useState([]);
  const [worldsOfEngineering, setWorldsOfEngineering] = useState([]);
  const [betaMentalities, setBetaMentalities] = useState([]);
  const [professionalOrientations, setProfessionalOrientations] = useState([]);
  const [wTCoreSubjectives, setWTCoreSubjectives] = useState([]);
  const [centurySkills, setCenturySkills] = useState([]);
  const [themes, setThemes] = useState([]);
  const [classId, setClassId] = useState("");
  const [schoolId, setSchoolId] = useState("");


  //----------------states for step-2--------------------
  const [selectClassPupils, setSelectClassPupils] = useState([]);
  //----------------states for step-3--------------------
  const [lessonName, setLessonName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState();
  const [files, setFiles] = useState([]);
  const [ErrorName, setErrorName] = useState("");

  //------------------dispatch data from here----------------
  const handleSubmit = () => {
    if(!lessonName){
      setErrorName(t("lesson name is required"));
      return;
    }
    if(!image){
      setErrorName(t("image is required"));
      return;
    }
    setErrorName("");
    const data = {
      class_id: classId,
      name: lessonName,
      pupils: selectClassPupils.map((item) => item.id),
      description: description,
      image: image,
      files:files,
      betaMentalities: betaMentalities.map((item) => item.value),
      centurySkills: centurySkills.map((item) => item.value),
      coreDisciplines: coreDisciplines.map((item) => item.value),
      disciplines: disciplines.map((item) => item.value),
      professionalOrientations: professionalOrientations.map((item) => item.value),
      // targetGroups: targetGroups.map((item) => item.value),
      // themes: themes.map((item) => item.value),
      worldOfEngineerings: worldsOfEngineering.map((item) => item.value),
      wtCoreSubjectives: wTCoreSubjectives.map((item) => item.value),
    };

    let formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if(Array.isArray(value)) {
        for (var i = 0; i < value.length; i++) {
          formData.append(key+'[]', value[i]);
        }
      } else {
        formData.append(key, value);
      }
    }

    dispatch(createLesson(formData));
    // clearAllState();
  };

  const CreateLessonStep1Props = {
    lessonName,
    setLessonName,
    ErrorName,
    description,
    setDescription,
    image,
    setImage,
    files,
    setFiles
  };

  const CreateLessonStep2Props = {
    classId,
    setSelectClassPupils,
    setClassId,
    subjects,
    setSubjects,
    targetGroups,
    setTargetGroups,
    disciplines,
    setDisciplines,
    coreDisciplines,
    setCoreDisciplines,
    worldsOfEngineering,
    setWorldsOfEngineering,
    betaMentalities,
    setBetaMentalities,
    professionalOrientations,
    setProfessionalOrientations,
    wTCoreSubjectives,
    setWTCoreSubjectives,
    centurySkills,
    setCenturySkills,
    themes,
    setThemes,
    setSchoolId,
  };
  const CreateLessonStep3Props = {
    classId,
    schoolId,
    selectClassPupils,
    setSelectClassPupils,
    handleSubmit,
  };

  if (step === 1) {
    return (
      <div className="create-lesson">
        <div className="row d-flex justify-content-center">
          <div className="col-10">
            <CreateLessonStep1 setStep={setStep} {...CreateLessonStep1Props} />
        </div>
        </div>
      </div>
    );
  }
  if (step === 2) {
    return (
      <div className="create-lesson">
        <div className="row d-flex justify-content-center">
          <div className="col-10">
            <CreateLessonStep2 setStep={setStep} {...CreateLessonStep2Props} />
          </div>
        </div>
      </div>
    );
  }
  if (step === 3) {
    return (
      <div className="create-lesson">
        <div className="row d-flex justify-content-center">
          <div className="col-10">
            <CreateLessonStep3 setStep={setStep} {...CreateLessonStep3Props} />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateLessonPopup;
