import {React, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {fetchPupils, transferPupils} from "../../redux/slices/pupilSlice";
import PupilTransferForm from "../../component/Forms/PupilTransferForm";
import {selectClass} from "../../redux/slices/classSlice";

const PupilTransfer = ({schoolId, classId}) => {
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const dispatch = useDispatch()
  const [t, ] = useTranslation()
  const history = useHistory();

  const Pupils = useSelector(state => state.pupil.pupils);
  const Class = useSelector(state => state.class.selectedClass);

  useEffect(() => {
    dispatch(selectClass({schoolId, classId}))
    dispatch(fetchPupils({schoolId, classId}))
  }, [dispatch, schoolId, classId])

  const submit = (fromClassId, toClassId, pupils) => {
    dispatch(transferPupils({fromClassId, toClassId, pupils}));
  }

  return (
    <div className="container main-container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
                <button className="btn btn-sm btn-primary" onClick={() => history.goBack()}>{arrow}</button>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t('Transfer pupils from')}{' '}"{Class.name}"</h4>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
        <div className="card-body">
          <PupilTransferForm
            schoolId={schoolId}
            pupils={Pupils}
            fromClass={Class}
            submitTransfer={submit}
          />
        </div>
      </div>
    </div>
  )
}

export default PupilTransfer;
