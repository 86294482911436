import {call, put, takeEvery } from 'redux-saga/effects'
import {push} from 'connected-react-router'
import Api from "../lib/Api";
import {errorsCleared, showNotification} from "../redux/slices/appSlice";
import i18n from '../i18n/i18n'
import {
  createTeacher,
  teachersFetched,
  deleteTeacher,
  updateTeacher,
  fetchTeachers,
  attachTeacher,
  attachTeacherClass,
  uploadTeachers
} from "../redux/slices/teacherSlice";
function* createTeacherSaga(action) {
  yield put(errorsCleared())
  const response = yield call(Api.post, '/api/teachers', action.payload)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Creating teacher has not succeeded.'),"error"]))
  } else if (response.response?.status === 422) {
    let errors = Object.values(response.response?.data?.errors || []).reduce((errors, fieldErrors) => [...errors, ...fieldErrors]);
    yield put(showNotification(errors))
  } else {
    yield put(push('/schools/' + action.payload.school_id))
  }
}

function* updateTeacherSaga(action) {
  const id = action.payload.id
  const response = yield call(Api.put, `/api/teachers/${id}`, action.payload)

  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Updating teacher has not succeeded.'),"error"]))
  } else if (response.response?.status === 422) {
    yield put(showNotification(Object.values(response.data)))
  } else {
    yield put(push('/schools/' + id))
  }
}

function* fetchTeachersSaga(action) {
  let response = {}
  if(action.payload.classId) {
    response = yield call(Api.get, `/api/schools/${action.payload.schoolId}/classes/${action.payload.classId}/teachers`);
  } else {
    response = yield call(Api.get, `/api/schools/${action.payload}/teachers`);
  }
  if (response.status === 500) {
    yield put(showNotification([i18n.t('Fetching teachers has not succeeded.'),"error"]))
  } else if (response.status === 422) {
    let errors = Object.values(response.data?.errors || []).reduce((errors, fieldErrors) => [...errors, ...fieldErrors]);
    yield put(showNotification(errors))
  } else {
    yield put(teachersFetched(response.data))
  }
  
}

function* deleteTeacherSaga(action) {
  const response = yield call(Api.delete, `/api/schools/${action.payload.schoolId}/teachers/${action.payload.teacherId}`)
  if (response.response) {
    yield put(showNotification([i18n.t('Unlinking teacher has not succeeded.'),"error"]))
  } else {
    yield put(fetchTeachers(action.payload.schoolId))
  }
}

function* attachTeacherSaga(action) {
  const response = yield call(Api.post, `/api/schools/${action.payload.schoolId}/teachers/attach`, {"email":action.payload.email})
   if (response.response?.status === 404) {
    yield put(showNotification([i18n.t('No teacher found with that email'),"error"]))
  } else {
    yield put(push(`/schools/${action.payload.schoolId}`))
  }
}

function* attachTeacherClassSaga(action) {
  const response = yield call(Api.post, `/api/schools/${action.payload.schoolId}/classes/${action.payload.classId}/teachers`, action.payload)
  if (response.response) {
    yield put(showNotification([i18n.t('Attaching teacher(s) has not succeeded.'), "error"]))
  } else if (response.response?.status === 404) {
    yield put(showNotification([i18n.t('No teacher found with that email')]))
  } else {
    yield put(push(`/schools/${action.payload.schoolId}/classes/${action.payload.classId}`))
  }
}

function* uploadTeachersSaga(action) {
  const response = yield call(Api.post, `/api/schools/${action.payload.schoolId}/upload-teachers`, action.payload.formData)
  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Uploading teacher(s) has not succeeded.'), "error"]))
    yield put(push(`/schools/${action.payload.schoolId}`))
  }else if (response.response?.status === 422) {
    let errors = Object.values(response.response?.data?.errors || []).reduce((errors, fieldErrors) => [...errors, ...fieldErrors]);
    yield put(showNotification(errors))
    yield put(push(`/schools/${action.payload.schoolId}`))
  } else {
    yield put(push(`/schools/${action.payload.schoolId}`))
  }
}


function* teacherSaga() {
  yield takeEvery(createTeacher.type, createTeacherSaga)
  yield takeEvery(updateTeacher.type, updateTeacherSaga)
  yield takeEvery(fetchTeachers.type, fetchTeachersSaga)
  yield takeEvery(deleteTeacher.type, deleteTeacherSaga)
  yield takeEvery(attachTeacher.type, attachTeacherSaga)
  yield takeEvery(attachTeacherClass.type, attachTeacherClassSaga)
  yield takeEvery(uploadTeachers.type, uploadTeachersSaga)
}

export default teacherSaga
