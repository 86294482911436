import { createAction, createSlice } from '@reduxjs/toolkit';
const initialState = {
    page: 1,
    totalItems: 0,
    lessons: [],
    classes: [],
    pupils: [],
    subjects: [],
    targetGroups: [],
    disciplines: [],
    coreDisciplines: [],
    worldsOfEngineering: [],
    betaMentalities: [],
    professionalOrientations: [],
    wTCoreSubjectives: [],
    centurySkills: [],
    themes: [],
    grades: [],
    similarLessonPupils: [],
    lessonsDetails: {},
    lessonDetailsFetched: [],
    filter:"",
    modereren:[],
    loader:false
}

export const lessonSlice = createSlice({
    name: 'lesson',
    initialState,
    reducers: {
        lessonsFilter: (state, action) => {
            state.filter = action.payload
        },
        pageChanged: (state, action) => {
            state.page = action.payload
        },
        totalItemsChanged: (state, action) => {
            state.totalItems = action.payload
        },
        lessonsFetched: (state, action) => {
            state.lessons = action.payload
        },
        classesFetched: (state, action) => {
            state.classes = action.payload
        },
        pupilsFetched: (state, action) => {
            state.pupils = action.payload
        },
        mentorFetched: (state, action) => {
            state.mentor = action.payload
        },
        subjectsFetched: (state, action) => {
            state.subjects = action.payload
        },
        targetGroupsFetched: (state, action) => {
            state.targetGroups = action.payload
        },
        disciplinesFetched: (state, action) => {
            state.disciplines = action.payload
        },
        coreDisciplinesFetched: (state, action) => {
            state.coreDisciplines = action.payload
        },
        worldsOfEngineeringFetched: (state, action) => {
            state.worldsOfEngineering = action.payload
        },
        betaMentalitiesFetched: (state, action) => {
            state.betaMentalities = action.payload
        },
        professionalOrientationsFetched: (state, action) => {
            state.professionalOrientations = action.payload
        },
        wTCoreSubjectivesFetched: (state, action) => {
            state.wTCoreSubjectives = action.payload
        },
        centurySkillsFetched: (state, action) => {
            state.centurySkills = action.payload
        },
        themesFetched: (state, action) => {
            state.themes = action.payload
        },
        gradesFetched: (state, action) => {
            state.grades = action.payload
        },
        similarLessonPupilsFetched: (state, action) => {
            state.similarLessonPupils = action.payload
        },
        lessonDetailsFetched: (state, action) => {
            state.lessonsDetails = action.payload
        },
        modererenFetched: (state, action) => {
            state.modereren = action.payload
        },
        loadingStopped:(state)=>{
            state.loader=false
        },
        loadingStarted:(state)=>{
            state.loader=true
        }
    },
})

export const {
    lessonsFetched,
    lessonsFilter,
    pageChanged,
    totalItemsChanged,
    classesFetched,
    pupilsFetched,
    mentorFetched,
    subjectsFetched,
    targetGroupsFetched,
    disciplinesFetched,
    coreDisciplinesFetched,
    worldsOfEngineeringFetched,
    betaMentalitiesFetched,
    professionalOrientationsFetched,
    wTCoreSubjectivesFetched,
    centurySkillsFetched,
    themesFetched,
    gradesFetched,
    similarLessonPupilsFetched,
    lessonDetailsFetched,
    modererenFetched,
    loadingStopped,
    loadingStarted
} = lessonSlice.actions

export const fetchLessons = createAction('lesson/fetchAll')
export const fetchClasses = createAction('lesson/classes')
export const fetchSubjects = createAction('lesson/subjects')
export const fetchTargetGroups = createAction('lesson/targetGroups')
export const fetchDisciplines = createAction('lesson/disciplines')
export const fetchCoreDisciplines = createAction('lesson/coreDisciplines')
export const fetchWorldsOfEngineering = createAction('lesson/worldsOfEngineering')
export const fetchBetaMentalities = createAction('lesson/betaMentalities')
export const fetchProfessionalOrientations = createAction('lesson/professionalOrientations')
export const fetchWTCoreSubjectives = createAction('lesson/wTCoreSubjectives')
export const fetchCenturySkills = createAction('lesson/centurySkills')
export const fetchThemes = createAction('lesson/themes')
export const fetchGrades = createAction('lesson/grades')
export const fetchSimilarLessonPupils = createAction('lesson/similarLessonPupils')
export const createLesson = createAction('lesson/create')
export const fetchLessonDetails = createAction('lesson/fetchLessonDetails')
export const attachFile = createAction('lesson/file')
export const publishLesson = createAction('lesson/publish')
export const finishGradeLesson = createAction('lesson/finish-grade')
export const editLesson = createAction('lesson/edit')
export const DeleteAttechmentFile = createAction('lesson/deleteFile')
export const fetchModereren = createAction('lesson/attachments')
export const submitDraft = createAction('lesson/draft/submit')
export const changeStatusModereren = createAction('lesson/attachments/changeStatus')
export const resetDetails = createAction('lesson/resetDetails')
export const resetClassesDetails = createAction('lesson/resetClassDetails')
export const resetLessons = createAction('lesson/resetLessons')


export default lessonSlice.reducer
