import {React, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {switchArchive} from "../../redux/slices/classSlice";

const PupilTransferForm = ({schoolId, pupils, fromClass, submitTransfer}) => {
  const [t] = useTranslation();
  const [selectedClassId, setSelectedClassId] = useState();
  const [selectedClassPupils, setSelectedClassPupils] = useState([]);
  const dispatch = useDispatch();
  const Classes = useSelector(state => state.class.classes);

  const noClassesToTransformTo = (Classes?.length < 2)
  const showSubmitButton = selectedClassPupils?.length && selectedClassId && parseInt(selectedClassId, 10) !== fromClass.id;
  const selectRef = useRef()

  const isChecked = (id) => selectedClassPupils.filter(pupil => pupil.id === id).length > 0

  const setPupilById = (id, checked) => {
    const currentPupil = pupils.filter(pupil => pupil.id === id)[0]
    let selectedPupilsWithoutCurrent = selectedClassPupils.filter(pupil => pupil.id !== id);

    if (checked) {
      setSelectedClassPupils([...selectedPupilsWithoutCurrent, currentPupil])
    } else {
      setSelectedClassPupils(selectedPupilsWithoutCurrent)
    }
  }

  useEffect(() => {
    setSelectedClassPupils(pupils);
  }, [pupils]);

  useEffect(() => {
    dispatch(switchArchive(false));
  }, [dispatch]);

  useEffect(() => {
    if (selectRef.current) {
      setSelectedClassId(selectRef.current.value)
    }
  }, [selectRef])

  const NoClassesToSelectFrom = () => (
    <div className="row">
      <div className="col-12">
        <strong className="mt-4">{t('No classes to choose from')}</strong>
        <p className="mt-2">{t('No classes to choose from text')}</p>
      </div>
    </div>
  )

  const NoPupilsToSelectFrom = () => (
    <div className="row">
      <div className="col-12">
        <strong className="mt-4">{t('No pupils to choose from')}</strong>
        <p className="mt-2">{t('No pupils to choose from text')}</p>
      </div>
    </div>
  )

  return noClassesToTransformTo ?
    <NoClassesToSelectFrom/> :
    !pupils?.length ?
      <NoPupilsToSelectFrom/> :
      (
        <form onSubmit={(e) => {
          e.preventDefault();
          submitTransfer(fromClass.id, selectedClassId, selectedClassPupils)
        }}>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label
                  htmlFor="class-select">{t('Class to transfer pupils to')}</label>
                <select
                  ref={selectRef}
                  id="class-select"
                  className="form-control form-select"
                  value={selectedClassId}
                  onChange={e => setSelectedClassId(e.target.value)}
                  labelled-by="Select"
                >
                  {(Classes || [])
                    .filter(classItem => classItem.id !== fromClass.id)
                    .map(classItem => <option
                      key={"class-" + classItem.id}
                      value={classItem.id}
                    >
                      {classItem.name}
                    </option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {t('Pupils to transfer')}
            </div>
          </div>
          <div className="row">
            {(pupils || []).filter(pupil => pupil.deleted_from_class == null).map((pupil) => (
              <div className="col-3 mt-3" key={pupil.id}>
                <input className="mr-2" type="checkbox" id={'pupil-' + pupil.id}
                       name={pupil.user?.name} value={pupil.id}
                       checked={isChecked(pupil.id)} onChange={(e) => {
                  setPupilById(pupil.id, e.target.checked)
                }}/>
                <label htmlFor={'pupil-' + pupil.id}>{pupil.user?.name}</label>
              </div>
            ))}
          </div>
          <div className="col-12 mt-3 text-right">
            <button disabled={!showSubmitButton} type="submit"
                    className="btn btn-secondary">
              {t('Transfer pupils')}
            </button>
          </div>
        </form>
      );
};
export default PupilTransferForm;
