import {call, put, takeEvery, select} from 'redux-saga/effects'
import {
  loggedIn,
  loggedOut,
  login,
  logout,
  loginFailed,
  userFetched,
  fetchUser
} from '../redux/slices/authSlice'
import { push } from 'connected-react-router'
import Api from '../lib/Api'
import history from "../history";
import {appReady, pageChanged, showNotification, loadingStarted} from "../redux/slices/appSlice";
import i18n from "../i18n/i18n";
import { pupilDetails } from '../redux/slices/pupilSlice';

function* loginSaga(action) {
  const response = yield Api.post("/api/login", action.payload)
  if (response.data?.[0]?.access_token) {
    yield put(loadingStarted())
    yield put(loggedIn(response.data?.[0]?.access_token));
  } else {
    yield put(loginFailed(response.data?.[1]));
  }
}

function* logoutSaga(action) {
  yield put(loggedOut())
  yield put(push('/'))
}

function* loggedInSaga(action) {
  const role = yield select(state => state.auth?.user?.roles?.[0]?.name)

  switch (role) {
    case "admin":
    case "school_admin":
      yield call(history.push, '/schools')
      break;
    case "teacher":
      yield call(history.push, '/lessons')
      break;
    case "pupil":
      yield call(history.push, '/pupil')
      break;
    default:
      yield call(history.push, '/')
  }
}

function* fetchUserSaga(action) {
  const loggedIn = yield select(state => state.auth.loggedIn);

  if (loggedIn) {
    const response = yield call(Api.get, '/api/me')
    if (response.response) {
      yield put(showNotification([i18n.t('Could not fetch logged in user.'),"error"]))
    } else {
      yield put(userFetched(response.data))
    }
  }
}

function* pageChangedSaga(action) {
  yield put(pupilDetails(false))
}

function* authSaga() {
  yield takeEvery(login.type, loginSaga)
  yield takeEvery(loggedIn.type, loggedInSaga)
  yield takeEvery(logout.type, logoutSaga)
  yield takeEvery(appReady.type, fetchUserSaga)
  yield takeEvery(loggedIn.type, fetchUserSaga)
  yield takeEvery(pageChanged.type, pageChangedSaga)
  yield takeEvery(fetchUser.type, fetchUserSaga)
}

export default authSaga
