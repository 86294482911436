import React from "react";
import {MultiSelect as LibraryMultiSelect} from "react-multi-select-component";
import {useTranslation} from "react-i18next";

export const MultiSelect = (props) => {
  const [t,] = useTranslation();

  return <LibraryMultiSelect {...props}
    overrideStrings={{
      "allItemsAreSelected": t("All items are selected."),
      "clearSearch": t("Clear Search"),
      "clearSelected": t("Clear Selected"),
      "noOptions": t("No options"),
      "search": t("Search"),
      "selectAll": t("Select All"),
      "selectAllFiltered": t("Select All (Filtered)"),
      "selectSomeItems": t("Select..."),
      "create": t("Create"),
    }}
  />
}
