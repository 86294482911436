import {Link} from "react-router-dom";
import {React, useEffect} from "react";
import ClassForm from "../../component/Forms/ClassForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {createClass, selectionCleared} from "../../redux/slices/classSlice";
import {useTranslation} from "react-i18next";

const ClassCreate = ({id}) => {
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;

  const [t, ] = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(selectionCleared())
  }, [dispatch])

  const submit = (
    name,
    start_date,
    end_date
  ) => {
    dispatch(createClass({
      name,
      start_date,
      end_date,
      school_id: id
    }))
  }

  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
              <Link to={`/schools/${id}`}>
                <button className="btn btn-sm btn-primary">{arrow}</button>
              </Link>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t('Create class')}</h4>
            </div>
            <div className="col-3 "></div>
          </div>
        </div>
        <div className="card-body">
          <ClassForm submit={submit} schoolId={id} submitTitle={t('Create')} />
        </div>
      </div>
    </div>
  )
}

export default ClassCreate;
