import { call, put, takeEvery, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import Api from "../lib/Api";
import {
  //  errorsCleared,
   showNotification, } from "../redux/slices/appSlice";
import i18n from '../i18n/i18n'
import {
  fetchLessons,
  pageChanged,
  totalItemsChanged,
  lessonsFetched,
  fetchClasses,
  classesFetched,
  pupilsFetched,
  fetchSubjects,
  subjectsFetched,
  fetchTargetGroups,
  fetchDisciplines,
  fetchCoreDisciplines,
  fetchWorldsOfEngineering,
  fetchBetaMentalities,
  fetchProfessionalOrientations,
  fetchWTCoreSubjectives,
  fetchCenturySkills,
  fetchThemes,
  fetchGrades,
  fetchSimilarLessonPupils,
  targetGroupsFetched,
  disciplinesFetched,
  coreDisciplinesFetched,
  worldsOfEngineeringFetched,
  betaMentalitiesFetched,
  professionalOrientationsFetched,
  wTCoreSubjectivesFetched,
  centurySkillsFetched,
  themesFetched,
  gradesFetched,
  modererenFetched,
  changeStatusModereren,
  fetchModereren,
  similarLessonPupilsFetched,
  mentorFetched,
  createLesson,
  lessonDetailsFetched,
  fetchLessonDetails,
  attachFile,
  publishLesson,
  finishGradeLesson,
  DeleteAttechmentFile,
  editLesson,
  resetDetails,
  resetClassesDetails,
  resetLessons,
  submitDraft,
  loadingStarted,
  loadingStopped
} from "../redux/slices/lessonSlice"
export const getFilter = (state) => state.lesson.filter || ""
function* fetchLessonsSaga(action){
    yield put(loadingStarted());
    let lessonFilter = yield select(getFilter);
    let response = yield call(Api.get, `/api/lessons?page=${action.payload.page}&search=${action.payload.searchContent}` +(lessonFilter ? `&${lessonFilter}`: ''));
    if (response?.data?.data){
      yield put(lessonsFetched(response.data.data || []))
      yield put(pageChanged(response.data.current_page))
      yield put(totalItemsChanged(response.data.total))
    }
    yield put(loadingStopped());
  }

  function* fetchClassesSaga(action){
    yield put(loadingStarted());
    let response = {}
    if(action.payload?.page) {
        response = yield call(Api.get, `/api/classes?page=${(action.payload.page)}`);
    }else if(action.payload?.id){
      response = yield call(Api.get, `/api/classes/${(action.payload.id)}`);
    }else if(action.payload?.archive){
      response = yield call(Api.get, `/api/classes?archive=1`);
    }else if(action.payload?.all) {
      response = yield call(Api.get, `/api/classes/all`);
    }else{
      response = yield call(Api.get, `/api/classes`);
    }
    if(response.data?.[0]?.pupils){
      yield put(classesFetched(response.data || []))
      yield put(pupilsFetched(response.data?.[0].pupils || []))
    }else if (response.data?.data){
      yield put(classesFetched(response.data.data || []))
      yield put(pageChanged(response.data.current_page))
      yield put(totalItemsChanged(response.data.total))
    }else if(response.data?.class?.[0]?.pupils){
      yield put(classesFetched(response.data.class || []))
      yield put(pupilsFetched(response.data.class?.[0].pupils || []))
      yield put(mentorFetched(response.data.mentor || false))
    }else if(response?.data){
      yield put(classesFetched(response.data || []))
    }
    yield put(loadingStopped());
  }

  function* fetchSubjectsSaga(action){
    const response = yield call(Api.get, `/api/subjects`);
    if (response?.data){
      yield put(subjectsFetched(response?.data || []))
    }
  }
  function* fetchTargetGroupsSaga(action){
    const response = yield call(Api.get, `/api/target-groups`);
    if (response?.data){
      yield put(targetGroupsFetched(response.data || []))
    }
  }
  function* fetchDisciplinesSaga(action){
    const response = yield call(Api.get, `/api/disciplines`);
    if (response?.data){
      yield put(disciplinesFetched(response.data || []))
    }
  }
  function* fetchCoreDisciplinesSaga(action){
    const response = yield call(Api.get, `/api/core-disciplines`);
    if (response?.data){
      yield put(coreDisciplinesFetched(response.data || []))
    }
  }
  function* fetchWorldsOfEngineeringSaga(action){
    const response = yield call(Api.get, `/api/worlds-of-engineering`);
    if (response?.data){
      yield put(worldsOfEngineeringFetched(response.data || []))
    }
  }
  function* fetchBetaMentalitiesSaga(action){
    const response = yield call(Api.get, `/api/beta-mentalities`);
    if (response?.data){
      yield put(betaMentalitiesFetched(response.data || []))
    }
  }
  function* fetchProfessionalOrientationsSaga(action){
    const response = yield call(Api.get, `/api/professional-orientations`);
    if (response?.data){
      yield put(professionalOrientationsFetched(response.data || []))
    }
  }
  function* fetchWTCoreSubjectivesSaga(action){
    const response = yield call(Api.get, `/api/wt-core-subjectives`);
    if (response?.data){
      yield put(wTCoreSubjectivesFetched(response.data || []))
    }
  }
  function* fetchCenturySkillsSaga(action){
    const response = yield call(Api.get, `/api/21st-century-skills`);
    if (response?.data){
      yield put(centurySkillsFetched(response.data || []))
    }
  }
  function* fetchThemesSaga(action){
    const response = yield call(Api.get, `/api/themes`);
    if (response?.data){
      yield put(themesFetched(response.data || []))
    }
  }
  function* fetchGradesSaga(action){
    const response = yield call(Api.get, `/api/grades`);
    if (response?.data){
      yield put(gradesFetched(response.data || []))
    }
  }
  function* fetchSimilarLessonPupilsSaga(action){
    const response = yield call(Api.post, `/api/lessons/check-class-for-similar-lessons`, action.payload);
    if (response?.data){
      yield put(similarLessonPupilsFetched(response.data || []))
    }
  }
  function* createLessonSaga(action) {
    const response = yield call(Api.post, '/api/lessons', action.payload)
    if (response.response?.status === 500) {
      yield put(showNotification([i18n.t('Creating lesson has not succeeded.'),"error"]))
    } else {
      yield put(push('/'));
    }
  }
  function* lessonDetailSaga(action) {

    const response = yield call(Api.get, `/api/lessons/${action.payload}`)
    if (response.data) {
      yield put(lessonDetailsFetched(response.data || []))
    }

  }
  function* attachFileSaga(action) {
    const response = yield call(Api.post, `/api/lessons/${action.payload.lessonId}/${action.payload.type}`, action.payload.formData)
    if (response.response?.status === 500) {
      yield put(showNotification([i18n.t(`Creating ${action.payload.type} has not succeeded.`),"error"]))
    }else{
      // yield put(showNotification(i18n.t(`Creating ${action.payload.type} succeeded.`)))
      yield put(fetchLessonDetails(action.payload.lessonId))
      action.payload.resetStates()
    }
  }
  function* lessonFinishGradeSaga(action) {
    const response = yield call(Api.post, `/api/lessons/${action.payload.lessonId}/finish`, action.payload.formData)
    if (response.response?.status === 500) {
      yield put(showNotification([i18n.t('Finishing lesson has not succeeded.'),"error"]))
    }else{
      // yield put(showNotification(i18n.t(`Publishing lesson has succeeded.`)))
      yield put(push(`/lesson-details/${action.payload.lessonId}`))
    }
  }
  function* lessonDeleteAttechmentFileSaga(action) {
    const response = yield call(Api.delete, `/api/lessons/${action.payload.lessonId}/attachments/${action.payload.attachmentId}`)
    if (response.response?.status === 500) {
      yield put(showNotification([i18n.t('Finishing lesson has not succeeded.'),"error"]))
    }else{
      // yield put(showNotification(i18n.t(`Publishing lesson has succeeded.`)))
      yield put(push(`/lesson-details/${action.payload.lessonId}`))
    }
  }
  function* lessonPublishSaga(action) {
    const response = yield call(Api.get, `/api/lessons/${action.payload.lessonId}/publish`)
    if (response.response?.status === 500) {
      yield put(showNotification([i18n.t('Publishing lesson has not succeeded.'),"error"]))
    }else{
      // yield put(showNotification(i18n.t(`Publishing lesson has succeeded.`)))
      yield put(push(`/lesson-details/${action.payload.lessonId}`))
    }
  }
  function* editLessonSaga(action) {
    console.log({p: action.payload})
    const response = yield call(Api.post, `/api/lessons/${action.payload.lessonId}`, action.payload.data)
    if (response.response?.status === 500) {
      yield put(showNotification([i18n.t('Editing lesson has not succeeded.'),"error"]))
    }else{
      yield put(push(`/lesson-details/${action.payload.lessonId}`))
    }
  }
function* fetchModererenSaga(action){
  const response = yield call(Api.get, `/api/lessons/attachments`);
  if (response?.data){
    yield put(modererenFetched(response.data || []))
  }
}

function* changeStatusModererenSaga(action) {
  const response = yield call(Api.put, `/api/lessons/${action.payload.lessonId}/attachments/${action.payload.attachmentId}`, {status: action.payload.status})
  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('modereren change status has not succeeded.'),"error"]))
  }else{
    yield call(fetchModererenSaga)
  }
}

function* resetLessonDetailSaga(action) {
  yield put(lessonDetailsFetched({}))
}

function* resetClassesDetailsSaga(action){
  yield put(classesFetched([]))
}

function* resetLessonsSaga(action){
  yield put(lessonsFetched(false))
}

function* submitDraftSaga(action) {
  const {lessonId, pupilId, description} = action.payload;

  const response = yield call(Api.post, `/api/lessons/${lessonId}/submissions`, {
    pupil_id: pupilId,
    description
  });
  if (response.response?.status === 500) {
    yield put(showNotification([i18n.t('Submitting the lesson has not succeeded.'), "error"]))
  } else if (response.response?.status === 422) {
    yield put(showNotification([i18n.t('Please fill in the description correctly before submitting.'), "error"]))
  } else {
    yield put(showNotification([i18n.t('Succesfully submitted the lesson.'), "success"]))
    yield put(fetchLessonDetails(lessonId));
  }
}

function* lessonSaga() {
    yield takeEvery(fetchLessons.type, fetchLessonsSaga)
    yield takeEvery(fetchClasses.type, fetchClassesSaga)
    yield takeEvery(fetchSubjects.type, fetchSubjectsSaga)
    yield takeEvery(fetchTargetGroups.type, fetchTargetGroupsSaga)
    yield takeEvery(fetchDisciplines.type, fetchDisciplinesSaga)
    yield takeEvery(fetchCoreDisciplines.type, fetchCoreDisciplinesSaga)
    yield takeEvery(fetchWorldsOfEngineering.type, fetchWorldsOfEngineeringSaga)
    yield takeEvery(fetchBetaMentalities.type, fetchBetaMentalitiesSaga)
    yield takeEvery(fetchProfessionalOrientations.type, fetchProfessionalOrientationsSaga)
    yield takeEvery(fetchWTCoreSubjectives.type, fetchWTCoreSubjectivesSaga)
    yield takeEvery(fetchCenturySkills.type, fetchCenturySkillsSaga)
    yield takeEvery(fetchThemes.type, fetchThemesSaga)
    yield takeEvery(fetchGrades.type, fetchGradesSaga)
    yield takeEvery(fetchSimilarLessonPupils.type, fetchSimilarLessonPupilsSaga)
    yield takeEvery(createLesson.type, createLessonSaga)
    yield takeEvery(attachFile.type, attachFileSaga)
    yield takeEvery(fetchLessonDetails.type,lessonDetailSaga)
    yield takeEvery(publishLesson.type,lessonPublishSaga)
    yield takeEvery(finishGradeLesson.type,lessonFinishGradeSaga)
    yield takeEvery(DeleteAttechmentFile.type,lessonDeleteAttechmentFileSaga)
    yield takeEvery(editLesson.type,editLessonSaga)
    yield takeEvery(fetchModereren.type, fetchModererenSaga)
    yield takeEvery(changeStatusModereren.type, changeStatusModererenSaga)
    yield takeEvery(resetDetails.type, resetLessonDetailSaga)
    yield takeEvery(resetClassesDetails.type, resetClassesDetailsSaga)
    yield takeEvery(resetLessons.type, resetLessonsSaga)
    yield takeEvery(submitDraft.type, submitDraftSaga)
}

export default lessonSaga
