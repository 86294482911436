import React from "react";
import { Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { faDownload ,faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CreatePotfolio({ name, description, image, handleSubmit, setName, setDescription, setImage, date, setDate, files, setFiles, images, setImages }) {
  const [t,] = useTranslation();

  const download = <FontAwesomeIcon icon={faDownload} />;
  const deletIcon=<FontAwesomeIcon icon={faTrashAlt} />;

  const fileSelectedHandler = (e,type) => {
    if(type==='image'){
      setImages([...images, e.target.files[0]])
    } else {
      setFiles([...files, e.target.files[0]])
    }
  }
  const RemoveFile = (name, type) => {
    if (type === 'image') {
      let newFiles = images.filter((data) => data.name !== name)
      setImages(newFiles)
    } else {
      let newFiles = files.filter((data) => data.name !== name)
      setFiles(newFiles)
    }

  }

  return (
    <div className="row pb-5">
      <div className="col-12 pt-5">
        <div className="d-flex justify-content-center">
          <h4 className="modal-title">{t('Create a new portfolio')}</h4>
        </div>
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="portfolioName" className="col-form-label">{t('Name')}</label>
        <input
          id="portfolio-name"
          type="text"
          className="form-control background-white "
          name="portfolioName"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="col-12 mb-4">
        <label htmlFor="date" className="col-form-label">
          {t("Date")}
        </label>
        <input
          id="date"
          type="date"
          className="form-control"
          name="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="portfolioDescription" className="col-form-label">{t('Description')}</label>
        <textarea
          id="portfolio-description"
          className="form-control background-white "
          name="portfolioDescription"
          value={description}
          rows="4"
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <div className="col-12 mb-2">
        <label htmlFor="image" className="col-form-label btn btn-primary mr-4">
          {t('Image')}
        </label>{image.name}
        <input
          id="portfolio-image"
          type="file"
          className="input-type-file"
          name="image"
          required
          onChange={(e) => setImage(e.target.files[0])}
        />
      </div>

      <div className="col-12 mb-2">
        <div>
          <label htmlFor="Attachments" className="col-form-label">
            {t('Attachments')}
          </label>
        </div>
        <label htmlFor="files" className="col-form-label btn btn-primary mr-4">
          {t('Upload file')}
        </label>
        <input
          id="files"
          type="file"
          className=" d-none"
          name="files"
          accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          multiple
          onChange={(e) => fileSelectedHandler(e, 'file')}
        />
        {files.map((data, i) => <div key={i} class="m-2"> <span className="color-grayish text-underline">{download} {data.name || "geen naam"} </span><sup role="button" onClick={() => RemoveFile(data.name,'file')}>{deletIcon}</sup></div>)}
      </div>

      <div className="col-12 mb-2">
        <div>
          <label htmlFor="Attachments" className="col-form-label">
            {t('Gallery')}
          </label>
        </div>
        <label htmlFor="images" className="col-form-label btn btn-primary mr-4">
          {t('Upload image')}
        </label>
        <input
          id="images"
          type="file"
          className=" d-none"
          name="images"
          accept="image/*"
          multiple
          onChange={(e) => fileSelectedHandler(e, 'image')}
        />
        {images.map((data, i) => <div key={i} class="m-2"> <span className="color-grayish text-underline">{download} {data.name || "geen naam"} </span><sup role="button" onClick={() => RemoveFile(data.name,'image')}>{deletIcon}</sup></div>)}
      </div>
      <div className="w-100 d-flex justify-content-end">
        <Button onClick={() => handleSubmit()}>{t('Submit')}</Button>
      </div>

    </div>)
}
export default CreatePotfolio;
