import {Link} from "react-router-dom";
import {React, useEffect} from "react";
import TaxonomieForm from "./Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {createTaxonomie, selectionCleared} from "../../../redux/slices/taxonomieSlice";
import {useTranslation} from "react-i18next";
import {errorsCleared} from "../../../redux/slices/appSlice";
import { subHeading, taxonomiesRoutes, endpointName } from './Constant'

const TaxonomieCreate = ({page}) => {
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const dispatch = useDispatch()
  const [t, ] = useTranslation()

  useEffect(() => {
    dispatch(errorsCleared())
    dispatch(selectionCleared())
  }, [dispatch])

  const submit = (name) => {
    dispatch(createTaxonomie({endpoint: endpointName[page],navigate : taxonomiesRoutes[page], formData : {name:name}}))
  }

  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
              <Link to={`${taxonomiesRoutes[page]}`}>
                <button className="btn btn-primary">{arrow}</button>
              </Link>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t(`Create ${subHeading[page]}`)}</h4>
            </div>
            <div className="col-3 "></div>
          </div>
        </div>
        <div className="card-body">
          <TaxonomieForm submit={submit} submitTitle={t('Create')} />
        </div>
      </div>
    </div>
  )
}

export default TaxonomieCreate;
