import {React, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Errors from "../../../component/Errors";
import {useSelector} from "react-redux";

const TaxonomiesForm = ({submit, submitTitle}) => {
  const [t,] = useTranslation()

  const selectedTaxonomies = useSelector(state => state.taxonomie.selectedTaxonomie)

  const [name, setName] = useState('')
 

  useEffect(() => {
    if (selectedTaxonomies) {
      setName(selectedTaxonomies.name || '')
    }
  }, [selectedTaxonomies])

  const submitState = (e) => {
    e.preventDefault()
    submit(name)
  }

  return (
    <form onSubmit={submitState}>
      <div className="row">
        <div className="col-12">
          <Errors />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="name" className="col-form-label">
            {t('Name')}
          </label>
          <input
            id="name"
            type="text"
            className="form-control "
            name="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-12 text-right">
          <button type="submit" className="btn btn-secondary">
            {submitTitle}
          </button>
        </div>
      </div>
    </form>
  );
}

export default TaxonomiesForm;
