import axios from "axios";
import { store } from '../redux/store'
import {loggedOut} from "../redux/slices/authSlice";
const Api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://api.dp.thewiseminds.com'
})
Api.interceptors.request.use((request) => {
    const token = store.getState().auth.token;
    request.headers.Authorization = `Bearer ${token}`;
    return request
});

Api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error?.response?.status === 401) {
        store.dispatch(loggedOut())
    }
    return error;
});

export default Api;
