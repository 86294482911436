import React, {useState} from "react";
import CreatePortfolio from "./CreatePortfolio";
import {createPortfolio } from "../../redux/slices/pupilSlice";
import {useDispatch,} from "react-redux";

function CreatePortfolioPopup() {
  const dispatch = useDispatch();
  
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [image, setImage] = useState({});
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
 
  //------------------dispatch data from here----------------
  const handleSubmit = () => {
      const data = {
        name: name,
        description: description,
        image: image,
        date:date,
        files:files,
        images:images
      };
      let formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if(Array.isArray(value)) {
          for (var i = 0; i < value.length; i++) {
            formData.append(key+'[]', value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }
      dispatch(createPortfolio(formData));
      // clearAllState();
  };
  // const clearAllState = useCallback(() => {
  //   setName("")
  //   setImage({})
  // }, []);
  // useEffect(()=>{
  //   if(!props.show){
  //     clearAllState()
  //   }
  // },[props.show,clearAllState])
 
  const portfolioProps = {
    name,
    setName,
    description,
    setDescription,
    image,
    setImage,
    date,
    setDate,
    files,
    setFiles,
    images,
    setImages,
    handleSubmit
  };
   return (
    <div className="row d-flex justify-content-center">
      <div className="col-10">
        <CreatePortfolio
          {...portfolioProps}
        />
      </div>
    </div>
    );
    }

export default CreatePortfolioPopup;
