import { React, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import Login from "./pages/login/index";
import history from "./history";
import { appReady } from "./redux/slices/appSlice";
import AdminDashboard from "./pages/dashboard/AdminDashboard";
import TeacherDashboard from "./pages/dashboard/TeacherDashboard";
import PupilDashboard from "./pages/dashboard/PupilDashboard";
import Loader from "./component/Loader"
import {loadingStopped} from "./redux/slices/appSlice"
import NotFoundPage from "./pages/not-found";

function App() {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const role = useSelector((state) => state.auth?.user?.roles?.[0]?.name);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.app.isLoading);
  useEffect(() => {
    dispatch(appReady());
  }, [dispatch]);

  useEffect(()=>{
      if (role){
    dispatch(loadingStopped())
      }
  },[dispatch,role]);

  return (
    <div>
       {isLoading?<Loader/>:
      <ConnectedRouter history={history}>
        {loggedIn && (role === "admin"  || role === "school_admin") ? (
          <AdminDashboard/>
        ) : loggedIn && role === "teacher" ? (
          <TeacherDashboard />
        ): loggedIn && role === "pupil" ? (
          <PupilDashboard />
        ): (
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route component={() => <NotFoundPage withLayout={true} marginTop={true} />} />
          </Switch>
        )}
      </ConnectedRouter>
}
    </div>
  );
}

export default App;
