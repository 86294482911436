import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Heading
} from "../../component";
import Loader from "../../component/Loader";
import {fetchPortfolioImages} from "../../redux/slices/pupilSlice";
import { Card } from 'react-bootstrap';
import FsLightbox from 'fslightbox-react';

const PortfolioImages = (props) => {
  const [t,] = useTranslation();
  const dispatch = useDispatch();
  const { id } = props;
  const portfolioImages = useSelector((state) => state.pupil.portfolioImages);
  const loader = useSelector((state) => state.pupil.loader);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    sourceIndex: null
  });

  useEffect(() => {
    dispatch(fetchPortfolioImages({pupilId: id}));
  }, [dispatch, id]);

  const openLightboxOnSlide = (index) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      sourceIndex: index
    });
  }

    
  return ( <Fragment>
      {loader &&  <div className="lesson-loader"><Loader/></div>}
    {portfolioImages && Object.keys(portfolioImages).length > 0 && (
    <Fragment>
    
    <FsLightbox
        toggler={lightboxController.toggler}
        sources={portfolioImages}
        sourceIndex={lightboxController.sourceIndex}
        type="image"
        />
      <div className="row mt-5 color-grayish">
        <div className="col-10 offset-1">
          <Heading color="#505050">{t('All Portfolio Item Images')}</Heading>
          <Card className="bg-white">
            <Card.Body>
              <div className="row">
                {(portfolioImages || []).map((image, key) => {
                  return(
                    <div className="col-3 mb-4" key={key}>
                        <img onClick={() => openLightboxOnSlide(key)} src={image} width="100%" alt=""/>
                    </div>
                  )  
                })}
                
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      </Fragment>)}
    </Fragment>
  ) 
};

export default PortfolioImages;
