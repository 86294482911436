import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { fetchClasses, resetClassesDetails } from "../../redux/slices/lessonSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../i18n/i18n";
import Loader from '../../component/Loader';
import {  Nav, NavDropdown } from 'react-bootstrap';
import AddPupilsModal from './AddPupilsModal';
import { Dialog } from '../../component';
// import Pagination from "react-js-pagination";

function ClassDetails({ id }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const classInformation = useSelector(state => state.lesson.classes[0])
  const pupilList = useSelector(state => state.lesson.pupils)
  const isMentor = useSelector(state => state.lesson.mentor)
  const [showAddPupilsModal, setShowAddPupilsModal] = useState(false)
  const loader = useSelector(state => state.lesson.loader)
  const AddIcon = <FontAwesomeIcon icon={faUserPlus} />;
  const Ellipsis = <FontAwesomeIcon icon={faEllipsisV} />;

  useEffect(() => {
    dispatch(resetClassesDetails())
    dispatch(fetchClasses({ id: id }))
  }, [id, dispatch])


  return (
    <div className="col-12">
      {loader &&  <div className="lesson-loader"><Loader /></div>}
      { showAddPupilsModal && 
        <Dialog
          isVisible={showAddPupilsModal}
          setIsVisible={setShowAddPupilsModal}
          size={"md"}
        >
          <AddPupilsModal setIsVisible={setShowAddPupilsModal} classId={id} schoolId={classInformation?.school?.id}/>
        </Dialog>
      }
      {classInformation && <div className="mt-5 row">
        <div className="col-8 p-2">
          <p className="font-weight-bold lessons-heading mb-0" >{classInformation?.name}</p>
        </div>
        <div className="col-4">
            {isMentor ?
              <div className='float-right'>
                <button type="button" className="btn btn-primary" title={t("Add pupils")} onClick={() => {setShowAddPupilsModal(true)}}>{AddIcon}</button>
              </div>
              : ""}
          </div>
        <div className="col-12 p-2">
          <div>
            <span className="font-weight-bold">{t('School')} : </span>
            <span>{classInformation?.school?.name}</span>
          </div>
          <div>
            <span className="font-weight-bold">{t('Start')} : </span>
            <span>{formatDate(classInformation?.start_date)}</span>
          </div>
          <div>
            <span className="font-weight-bold">{t('End')} : </span>
            <span>{formatDate(classInformation?.end_date)}</span>
          </div>
        </div>
        <div className="col-12 p-2">
          <p className="font-weight-bold mt-5 lessons-heading mb-0" >{t('Pupils')}</p>
        </div>
        <div className="col-12 p-2 mb-5">
          <div className="row">
            {(pupilList || []).map((Pupil) => (
              <div className="col-md-4 col-6 pb-2 d-flex" key={Pupil?.id}>
                <div className="lessons w-100">
                  <div className="lesson-card p-3">
                    <div className='row'>
                      <div className='col-10'>
                        <p className="mb-0 pupil-name d-flex align-middle">{Pupil.user?.name}</p>
                      </div>
                      <div className='col-2'>
                        <div className='float-right class-detail-pupil'>
                          <ul className="navbar-nav mr-auto">
                            <li>
                              <Nav>
                                <NavDropdown
                                  id="nav-dropdown-dark-example"
                                  title= {Ellipsis}
                                  style={{marginTop:"-10px",color:"#0000"}}
                                >
                                  <NavDropdown.Item href={`/pupil/${Pupil?.id}`}> {t('View')}</NavDropdown.Item>
                                </NavDropdown>
                              </Nav>
                             
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
        </div>

      </div> }

    </div>
  )
}
export default ClassDetails;