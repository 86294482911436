import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import Errors from "../Errors";
import {useSelector, useDispatch} from "react-redux";
import { fetchAllSchools } from "../../redux/slices/schoolSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";

const UserForm = ({ showPasswordNotice = false, submit, submitTitle, type=null }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const schools = useSelector((state) => state.school.allSchools);
  const selectedUser = useSelector((state) => state.user.selectedUser);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [reference, setReference] = useState("");
  const [role, setRole] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const eye = <FontAwesomeIcon icon={faEye}/>;

  useEffect(() => {
    if (selectedUser) {
      setfirstName(selectedUser.firstName || "");
      setlastName(selectedUser.lastName || "");
      setEmail(selectedUser.email || "");
      setRole(selectedUser.role || "admin");
      setReference(selectedUser.reference || "");
      setSchoolId(selectedUser.schoolId || "");
    }
  }, [selectedUser]);

  useEffect(() => {
    dispatch(fetchAllSchools())
  }, [dispatch]);

  const submitState = (e) => {
    e.preventDefault();
    if(type === "edit"){
      submit(firstName, lastName, email, role, password, passwordConfirm);
    }else{
      submit(firstName, lastName, email, role, schoolId, password, passwordConfirm,reference);
    }
  };

  const togglePassword = () => {
    if(passwordType === 'password'){
      setPasswordType("text")
    }else{
      setPasswordType("password")
    }
  }

  const toggleConfirmPassword = () => {
    if(confirmPasswordType === 'password'){
      setConfirmPasswordType("text")
    }else{
      setConfirmPasswordType("password")
    }
  }

  return (
    <form onSubmit={submitState}>
      <div className="row">
        <div className="col-12">
          {/* <Errors /> */}
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="first_name" className="col-form-label">
            {t("First Name")}
          </label>
          <input
            id="first_name"
            type="text"
            className="form-control "
            name="first_name"
            required
            value={firstName}
            onChange={(e) => setfirstName(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="last_name" className="col-form-label">
            {t("Last Name")}
          </label>
          <input
            id="last_name"
            type="text"
            className="form-control "
            name="last_name"
            required
            value={lastName}
            onChange={(e) => setlastName(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="email" className="col-form-label">
            {t("Email")}
          </label>
          <input
            id="email"
            type="text"
            className="form-control "
            name="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="role" className="col-form-label">
            {t("Role")}
          </label>
          <select
            id="role"
            className="form-control "
            name="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="">{t("Select role")}</option>
            <option value="admin">{t("Admin")}</option>
            <option value="school_admin">{t("School Admin")}</option>
            <option value="teacher">{t("Teacher")}</option>
            <option value="pupil">{t("Pupil")}</option>
          </select>
        </div>
        {((role === "school_admin" || role === "teacher" || role === "pupil") && type !== "edit") && (
          <div className="col-12 mb-4">
            <label htmlFor="school" className="col-form-label">
              {t("School")}
            </label>
            <select
              id="school"
              className="form-control "
              name="school_id"
              value={schoolId}
              onChange={(e) => setSchoolId(e.target.value)}
            >
              <option value="">{t("Select Schools")}</option>
              {(schools || []).map((school) => (
                <option key={`school_${school.id}`} value={school.id}>{t(school.name)}</option>
              ))}
            </select>
          </div>
        )}
        {(role === "pupil" && type !== "edit") &&  <div className="col-12 mb-4">
          <label htmlFor="reference" className="col-form-label">
            {t("Pupil reference")}
          </label>
          <input
            id="reference"
            type="text"
            className="form-control "
            name="reference"
            required
            value={reference}
            onChange={(e) => setReference(e.target.value)}
          />
        </div>}
        <div className="col-12 mb-4">
          <label htmlFor="password" className="col-form-label">
            {t("Password")}
          </label>
          <div className="input-group">
            <input
              id="password"
              type={passwordType}
              className="form-control "
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text" role="button" onClick={() => togglePassword()}>{eye}</span>
            </div>
          </div>
          {showPasswordNotice && (
            <div className="text-muted">
              {t(
                "Only fill in the password fields if you want to change the password"
              )}
            </div>
          )}
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="password_confirm" className="col-form-label">
            {t("Confirm password")}
          </label>
          <div className="input-group">
            <input
              id="password_confirm"
              type={confirmPasswordType}
              className="form-control "
              name="password_confirm"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text" role="button" onClick={() => toggleConfirmPassword()}>{eye}</span>
            </div>
          </div>
        </div>
        <div className="col-12 text-right">
          <button type="submit" className="btn btn-secondary">
            {submitTitle}
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
