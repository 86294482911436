import {React, useEffect} from "react";
import PupilForm from "../../component/Forms/PupilCreateForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {createPupil} from "../../redux/slices/pupilSlice"
import {selectionCleared} from "../../redux/slices/userSlice";
const PupilCreate = ({schoolId}) => {
  const arrow = <FontAwesomeIcon icon={faArrowLeft} />;
  const dispatch = useDispatch()
  const [t, ] = useTranslation()

  const history = useHistory();

  useEffect(() => {
    dispatch(selectionCleared())
  }, [dispatch])

  const submit = (
    first_name,
    last_name,
    email,
    reference,
    password,
    passwordConfirmation
  ) => {
    dispatch(createPupil({
      first_name,
      last_name,
      email,
      reference,
      password,
      password_confirmation: passwordConfirmation,
      school_id: schoolId
    }))
  }

  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3 text-left">
                <button className="btn btn-sm btn-primary"  onClick={() => history.goBack()}>{arrow}</button>
            </div>
            <div className="col-6">
              <h4 className="mb-0">{t('Create pupil')}</h4>
            </div>
            <div className="col-3 "></div>
          </div>
        </div>
        <div className="card-body">
          <PupilForm submit={submit} submitTitle={t('Create')} />
        </div>
      </div>
    </div>
  )
}

export default PupilCreate;
