import React from 'react'
import styled from 'styled-components'

const InnerContainer = styled.div`
    z-index : 10;
    background : url(${props => props.imgUrl}), #D0D0D0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width : ${props => props.width ?? "350px"};
    height : ${props => props.height ?? " 350px"};
    clip-path: polygon(50% 0,100% 25%, 100% 75%,50% 100%,0 75%,0 25%);
`
const OuterContainer = styled.div`
    width: ${props => props.width ?? "395px"};
    height: ${props=> props.height ?? "341px"};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 103%;
    overflow: hidden;
`
const FloatingContainer = styled.div`
    background : ${props => props.color};
    background-size : 100% 100%;
    width : ${props => props.width ?? "350px"};
    height : ${props => props.height ?? " 350px"};
    position : absolute;
    ${props => props.verticalDirection} : ${props => props.verticalDirectionVal};
    ${props => props.horizontalDirection} : ${props => props.horizontalDirectionVal};
    clip-path: polygon(50% 0,100% 25%, 100% 75%,50% 100%,0 75%,0 25%);
`

export const HexagonalContainer = ({ imgUrl }) => {
    return (
        <>
            <FloatingContainer
                color={"#83d0f5"}
                width="150px"
                height="150px"
                verticalDirection="top"
                verticalDirectionVal="0"
                horizontalDirection="right"
                horizontalDirectionVal="218px"
            />
            <OuterContainer>
                <InnerContainer imgUrl={imgUrl} />
            </OuterContainer>
            <FloatingContainer
                color={"#f47d44"}
                width="150px"
                height="150px"
                verticalDirection="bottom"
                verticalDirectionVal="0"
                horizontalDirection="right"
                horizontalDirectionVal="36px"
            />
        </>
    )
}

export const GalleryImageContainer = styled.div`
    background : url("${props => props.imgUrl}"), #D0D0D0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width : ${props => props.width ?? "600px"};
    height : ${props => props.height ?? "400px"};
    cursor : pointer;
`

export const LogoHexagonalContainer = ({ imgUrl, width, height }) => {
    return (
        <>
            <OuterContainer width="200px" height="250px">
                <InnerContainer width={width} height={height} imgUrl={imgUrl} />
            </OuterContainer>
        </>
    )
}
