import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { updatePupilReference } from '../../redux/slices/pupilSlice';

function EditPupilModal({ setIsVisible = () => {}, pupil, schoolId, classId }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [reference, setReference] = useState((pupil?.reference || ""));

  const handleSubmit = () => {
    dispatch(updatePupilReference({ pupilId: pupil.id, schoolId : schoolId, classId: classId ? classId : '', formData : {reference: reference}, resetStates : resetStates}));
  };

  const resetStates = () => {
    setReference("");
    setIsVisible(false);
  };

  return (
    <div className="p-3">
      <h4 className="modal-title d-flex justify-content-center text-capitalize">
        {t(`Update pupil reference`)}
      </h4>
        <div className="row mt-3">
            <div className="col-12 mb-4">
                <label htmlFor="pupilName" className="col-form-label">
                    {t("Pupil name")}
                </label>
                <input
                    className="form-control"
                    value={pupil?.user?.name}
                    readOnly
                />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12 mb-4">
                <label htmlFor="reference" className="col-form-label">
                    {t("Reference")}
                </label>
                <input
                    id="reference"
                    className="form-control "
                    name="reference"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                />
            </div>
        </div>
      <div className="d-flex justify-content-between mt-3">
        <Button onClick={resetStates}>{t("Close")}</Button>

        <Button onClick={() => handleSubmit()}>{t("Submit")}</Button>
      </div>
    </div>
  );
}
export default EditPupilModal;
