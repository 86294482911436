import React from "react";
import Nav from "../../component/Nav/index";
import Routes from "../../routes";
import Footer from "../../component/Footer/Footer";

function PupilDashboard() {
  return (
    <div className="teacher-dashboard">
      <div>
        <Nav />
      </div>
      <div className="container create-portfolio">
        <Routes />
      </div>
      <div className="container-fluid">
        <Footer />
      </div>
    </div>
  );
}
export default PupilDashboard;
