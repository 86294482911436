import React from 'react';
import List from '../component/list';
import { componentName } from '../component/Constant';

const DisciplinesList = () => {
    return (
        <List componentName={componentName.discipline}/>
    )
}

export default DisciplinesList
