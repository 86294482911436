import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateLessonStep2 from "./CreateLessonStep2";
import CreateLessonStep3 from "./CreateLessonStep3";
import CreateLessonStep1 from "./CreateLessonStep1";
import { editLesson } from "../../redux/slices/lessonSlice";

function EditLesson({ id }) {
  const dispatch = useDispatch();
  const [step,setStep] = useState(1);
  const isEdit = true;
  const lessonDetails = useSelector((state) => state.lesson.lessonsDetails);
  //----------------states for step-1--------------------
  const [subjects, setSubjects] = useState([]);
  const [targetGroups, setTargetGroups] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [coreDisciplines, setCoreDisciplines] = useState([]);
  const [worldsOfEngineering, setWorldsOfEngineering] = useState([]);
  const [betaMentalities, setBetaMentalities] = useState([]);
  const [professionalOrientations, setProfessionalOrientations] = useState([]);
  const [wTCoreSubjectives, setWTCoreSubjectives] = useState([]);
  const [centurySkills, setCenturySkills] = useState([]);
  const [themes, setThemes] = useState([]);
  const [classId, setClassId] = useState("");
  const [schoolId, setSchoolId] = useState("");

  //----------------states for step-2--------------------
  const [selectClassPupils, setSelectClassPupils] = useState([]);
  //----------------states for step-3--------------------
  const [lessonName, setLessonName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const getList = (list) => {
    if (list && list.length) {
      return list.map((item) => {
        return { value: item.id, label: item.name };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (Object.keys(lessonDetails).length) {
      setClassId(lessonDetails["class"]["id"]);
      setSchoolId(lessonDetails["class"]["school_id"]);
      setLessonName(lessonDetails["name"]);
      setDescription(lessonDetails["description"]);
      setSelectClassPupils(lessonDetails["pupils"]);
      setSubjects(getList(lessonDetails["subjects"]));
      setTargetGroups(getList(lessonDetails["target_groups"]));
      setDisciplines(getList(lessonDetails["disciplines"]));
      setCoreDisciplines(getList(lessonDetails["core_disciplines"]));
      setWorldsOfEngineering(getList(lessonDetails["world_of_engineerings"]));
      setBetaMentalities(getList(lessonDetails["beta_mentalities"]));
      setProfessionalOrientations(
        getList(lessonDetails["professional_orientations"])
      );
      setWTCoreSubjectives(getList(lessonDetails["w_t_core_subjectives"]));
      setCenturySkills(getList(lessonDetails["century_skills"]));
      setThemes(getList(lessonDetails["themes"]));
      setImageUrl(lessonDetails['image_url'])
    }
  }, [lessonDetails]);

  //------------------dispatch data from here----------------
  const handleSubmit = () => {
    const data = {
      class_id: classId,
      name: lessonName,
      pupils: selectClassPupils.map((item) => item.id),
      description: description,
      subjects: subjects.map((item) => item.value),
      betaMentalities: betaMentalities.map((item) => item.value),
      centurySkills: centurySkills.map((item) => item.value),
      coreDisciplines: coreDisciplines.map((item) => item.value),
      disciplines: disciplines.map((item) => item.value),
      professionalOrientations: professionalOrientations.map(
        (item) => item.value
      ),
      targetGroups: targetGroups.map((item) => item.value),
      themes: themes.map((item) => item.value),
      worldOfEngineerings: worldsOfEngineering.map((item) => item.value),
      wtCoreSubjectives: wTCoreSubjectives.map((item) => item.value),
    };
    if(image) { data.image = image }

    let formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if(Array.isArray(value)) {
        for (var i = 0; i < value.length; i++) {
          formData.append(key+'[]', value[i]);
        }
      } else {
        formData.append(key, value);
      }
    }

    dispatch(editLesson({lessonId: id, data: formData}));
  };

  useEffect(()=>{
    if(image){
      setImageUrl(URL.createObjectURL(image))
    }else{
      setImageUrl(null)
    }
  },[image])

  const CreateLessonStep1Props = {
    lessonName,
    setLessonName,
    description,
    setDescription,
    image,
    setImage,
    imageUrl:image ? imageUrl : lessonDetails && lessonDetails['image_url'] ? lessonDetails['image_url'] : null,
    setImageUrl,
    isEdit
  };

  const CreateLessonStep2Props = {
    classId,
    setSelectClassPupils,
    setClassId,
    subjects,
    setSubjects,
    targetGroups,
    setTargetGroups,
    disciplines,
    setDisciplines,
    coreDisciplines,
    setCoreDisciplines,
    worldsOfEngineering,
    setWorldsOfEngineering,
    betaMentalities,
    setBetaMentalities,
    professionalOrientations,
    setProfessionalOrientations,
    wTCoreSubjectives,
    setWTCoreSubjectives,
    centurySkills,
    setCenturySkills,
    themes,
    setThemes,
    setSchoolId,
    isEdit
  };
  const CreateLessonStep3Props = {
    classId,
    schoolId,
    selectClassPupils,
    setSelectClassPupils,
    handleSubmit,
  };

  if (step === 1) {
    return (
        <CreateLessonStep1 setStep={setStep} {...CreateLessonStep1Props} />
    );
  }
  if (step === 2) {
    return (
        <CreateLessonStep2 setStep={setStep} {...CreateLessonStep2Props} />
    );
  }
  if (step === 3) {
    return (
      <CreateLessonStep3 setStep={setStep} {...CreateLessonStep3Props} />
    );
  }
}

export default EditLesson;
