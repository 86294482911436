import {createSlice, createAction} from '@reduxjs/toolkit'

const initialState = {
  token: "",
  user: {},
  loggedIn: false,
  loginError: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedOut: (state, action) => {
      state.token = ''
      state.loggedIn = false
      state.user = {}
      state.loginError = false
    },
    loggedIn: (state, action) => {
      state.loginError = false
      state.token = action.payload
      state.loggedIn = true;
    },
    userFetched: (state, action) => {
      state.user = action.payload
    },
    loginFailed: (state, action) => {
      state.loginError = true
    },
  },
})

export const login = createAction('auth/login');
export const logout = createAction('auth/logout');
export const fetchUser = createAction('auth/user');

// Actions
export const {loggedIn, userFetched, loginFailed, loggedOut} = authSlice.actions

export default authSlice.reducer
