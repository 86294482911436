import {React, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faCheck, faSearch} from "@fortawesome/free-solid-svg-icons";
import {useSelector, useDispatch} from "react-redux";
import {fetchModerates, updateModerate, fetchPortfolioModerates, updatePortfolioModerate} from "../../redux/slices/moderateSlice";
import Pagination from "react-js-pagination";
import {useTranslation} from "react-i18next";

const ModerateList = () => {
  const [t, ] = useTranslation();
  const dispatch = useDispatch();
  const times = <FontAwesomeIcon icon={faTimes}/>;
  const check = <FontAwesomeIcon icon={faCheck}/>;
  const search = <FontAwesomeIcon icon={faSearch}/>;

  const moderates = useSelector(state => state.moderate.moderates)
  const page = useSelector(state => state.moderate.page)
  const totalItems = useSelector(state => state.moderate.totalItems)
  const portfolioModerates = useSelector(state => state.moderate.portfolioModerates)
  const portfolioPage = useSelector(state => state.moderate.portfolioPage)
  const portfolioTotalItems = useSelector(state => state.moderate.portfolioTotalItems)

  const [searchBox, setSearchBox] = useState(0);
  const[searchData,setSearchData] = useState("")
  const [portfolioSearchBox, setPortfolioSearchBox] = useState(0);
  const[portfolioSearchData, setPortfolioSearchData] = useState("")

  useEffect(() => {
    if(searchData){
      dispatch(fetchModerates({searchContent:searchData,page:page}))
    }else{
      dispatch(fetchModerates(page))
    }

    if(portfolioSearchData){
      dispatch(fetchPortfolioModerates({searchContent:portfolioSearchData, page:portfolioPage}))
    }else{
      dispatch(fetchPortfolioModerates(portfolioPage))
   }

  }, [dispatch, page, searchData, portfolioPage, portfolioSearchData])

  const updateAttachment = (lessonId, attachmentId, status) => {
    dispatch(updateModerate({
      lessonId: lessonId,
      attachmentId: attachmentId,
      status: status
    }))
  };

  const updatePortfolioAttachment = (portfolioId, attachmentId, status) => {
    dispatch(updatePortfolioModerate({
      portfolioId: portfolioId,
      attachmentId: attachmentId,
      status: status
    }))
  };

  const searchHandler = () => {
    setSearchBox(searchBox === 0 ? 1 : 0);
    setSearchData("");
  };

  const portfolioSearchHandler = () => {
    setPortfolioSearchBox(portfolioSearchBox === 0 ? 1 : 0);
    setPortfolioSearchData("");
  };

  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3"></div>
            <div className="col-6">
              <h4 className="mb-0">{t('Lesson Moderate list')}</h4>
            </div>
            <div className="col-3 text-right">
              <button type="button" className="btn btn-sm mr-2" onClick={searchHandler}>{search}</button>
            </div>
          </div>
        </div>
        { searchBox !== 0 &&
          <div className={`col-12 searchbox ${searchBox && "py-2"} fade-in-left`} style={{height: searchBox && "auto", transform: `scale(${searchBox})`, transition: "transform 0.5s ease"}}>
            <input type="text" className="w-100 background-white" value={searchData} onChange={(e) => setSearchData(e.target.value)} placeholder={t("Zoeken")} />
          </div>
        }
        <div className="card-body">
          <table className="table table-hover">
            <thead>
            <tr>{(['Filename','Lesson', 'User', 'Type', 'Actions']).map((h) => (
              <th key={h}>{t(h)}</th>
            ))}</tr>
            </thead>
            <tbody>
              {(moderates || []).map((moderate) => (
                <tr key={'moderate-'+moderate.id}>
                  <td>{moderate.file_name}</td>
                  <td>{moderate.lesson?.name}</td>
                  <td>{moderate.user?.name}</td>
                  <td>{moderate.type}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger ml-auto btn-action"
                      onClick={() => {updateAttachment(moderate.lesson.id, moderate.id, 'declined')}}>
                      {times}
                    </button>
                    {' '}
                    <button
                      className="btn btn-sm btn-success ml-auto btn-action"
                      onClick={() => {updateAttachment(moderate.lesson.id, moderate.id, 'public')}}>
                      {check}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="cardPagination">
            <Pagination
              activePage={page}
              itemClass="page-item"
              linkClass="page-link"
              itemsCountPerPage={10}
              totalItemsCount={totalItems}
              pageRangeDisplayed={3}
              onChange={(page) => dispatch(fetchModerates(page))}
            />
          </div>
        </div>
      </div>


      <div className="card mt-5">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3"></div>
            <div className="col-6">
              <h4 className="mb-0">{t('Portfolio Moderate list')}</h4>
            </div>
            <div className="col-3 text-right">
              <button type="button" className="btn btn-sm mr-2" onClick={portfolioSearchHandler}>{search}</button>
            </div>
          </div>
        </div>
        { portfolioSearchBox !== 0 &&
          <div className={`col-12 searchbox ${portfolioSearchBox && "py-2"} fade-in-left`} style={{height: portfolioSearchBox && "auto", transform: `scale(${portfolioSearchBox})`, transition: "transform 0.5s ease"}}>
            <input type="text" className="w-100 background-white" value={portfolioSearchData} onChange={(e) => setPortfolioSearchData(e.target.value)} placeholder={t("Zoeken")} />
          </div>
        }
        <div className="card-body">
          <table className="table table-hover">
            <thead>
            <tr>{(['Filename','Portfolio', 'User', 'Type', 'Actions']).map((h) => (
              <th key={h}>{t(h)}</th>
            ))}</tr>
            </thead>
            <tbody>
              {(portfolioModerates || []).map((moderate) => (
                <tr key={'moderate-'+moderate.id}>
                  <td>{moderate.file_name}</td>
                  <td>{moderate.portfolio?.name}</td>
                  <td>{moderate.user?.name}</td>
                  <td>{moderate.type}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger ml-auto taxonomies"
                      onClick={() => {updatePortfolioAttachment(moderate.portfolio.id, moderate.id, 'declined')}}>
                      {times}
                    </button>
                    {' '}
                    <button
                      className="btn btn-sm btn-success ml-auto taxonomies"
                      onClick={() => {updatePortfolioAttachment(moderate.portfolio.id, moderate.id, 'public')}}>
                      {check}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="cardPagination">
            <Pagination
              activePage={portfolioPage}
              itemClass="page-item"
              linkClass="page-link"
              itemsCountPerPage={10}
              totalItemsCount={portfolioTotalItems}
              pageRangeDisplayed={3}
              onChange={(portfolioPage) => dispatch(fetchPortfolioModerates(portfolioPage))}
            />
          </div>
        </div>
      </div>


    </div>
  );
}

export default ModerateList;
