import React from "react";
import Footer from "../../component/Footer/Footer";
import Nav from "../../component/Nav";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function NotFoundPage({withLayout = false, marginTop = false}) {
  const [t, ] = useTranslation();

  return (
    <div className="not-found">
      {withLayout && (<div>
        <Nav />
      </div>)}
      <div className={ marginTop ? "container mt-5 pt-4" : "container"}>
          <div className="row">
            <div className="col-12">
              <h1>{t('Page not found')}</h1>
              <p className="mt-4">{t('Page not found text')}</p>
              <Link to="/">{t('Go to home')}</Link>
            </div>
          </div>
      </div>
      {withLayout && (<div className="container-fluid mt-5">
        <Footer />
      </div>)}
    </div>
  );
}
export default NotFoundPage;
