import { Fragment, React, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {fetchClasses,resetClassesDetails } from "../../redux/slices/lessonSlice";
import Pagination from "react-js-pagination";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";
import { formatDate } from "../../i18n/i18n"
import Loader from "../../component/Loader";

const ClassList = () => {
  const [t, ] = useTranslation();
  const dispatch = useDispatch();
  const Classes = useSelector(state => state.lesson.classes)||[]
  const loader = useSelector(state => state.lesson.loader)
  const page = useSelector(state => state.class.page)
  const totalItems = useSelector(state => state.lesson.totalItems)
  const [archive, setArchive] = useState(false);
  useEffect(() => {
    dispatch(fetchClasses({page:page}))
  }, [page, dispatch])

  useEffect(() => {
    dispatch(resetClassesDetails())
  }, [dispatch])

  const toggleArchive = archive => {
    if(archive){
      setArchive(archive)
      dispatch(fetchClasses({archive:1}))
    }else{
      setArchive(false);
      dispatch(fetchClasses({page:page}))
    }
  }

  return (
    <div className="col-12">
      {loader ? <div className="lesson-loader"><Loader/></div> :
          <Fragment>
          <div className="row">
            <div className="col-8 p-2">
                <p className="font-weight-bold mt-5 lessons-heading mb-0" >{t('Classes')}</p>
            </div>
            <div className="col-4 text-right mt-5">
              <div className="mt-3 btn-group archive-group" role="group"
                    aria-label="First group">
                <button
                  type="button"
                  className={archive ? "btn btn-sm btn-primary" : "btn btn-sm btn-primary active"}
                  onClick={() => {toggleArchive(false)}}
                >
                  {t('Active')}
                </button>
                <button
                  type="button"
                  className={archive ? "btn btn-sm btn-primary active" : "btn btn-sm btn-primary"}
                  onClick={() => {toggleArchive(true)}}
                >
                  {t('Archive')}
                </button>
              </div>
            </div>
          </div>
         <div className="row">
           {(Classes).map((_classes)=>(
             <div className="col-6 p-2 d-flex" key={_classes.id}>
               <div className="lessons w-100">
               <div className="lesson-card p-3">
               <p className="mb-3 lesson-name" >{_classes.name}</p>
               <p className="mb-2" >{formatDate(_classes.start_date)}{" - "}{formatDate(_classes.end_date)}</p>
               <div className="d-flex justify-content-end"><Link to={`/class-details/${_classes.id}`} className="btn btn-link">{t('view more')}</Link></div>
               </div>
               </div>
             </div>
           ))}
          </div>
            {totalItems?
          <div className="cardPagination d-flex justify-content-center mt-4">
            <Pagination
              activePage={page}
              itemClass="page-item"
              linkClass="page-link"
              itemsCountPerPage={10}
              totalItemsCount={totalItems}
              pageRangeDisplayed={3}
              onChange={(page) => dispatch(fetchClasses(page))}
              />
              </div>:
              <p className="noLessonFound mt-4">{t('No lessons found')}</p>  }
       </Fragment> }
    </div>
  );
}

export default ClassList;