import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";

const Errors = () => {
  const errors = useSelector(state => state.app.errors)
  const alertRef = useRef()

  useEffect(() => {
    if (errors && errors.length > 0 && alertRef) {
      alertRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [errors, alertRef])

  return (
    <>
      {errors && errors.length > 0 && (
        <div ref={alertRef} className="alert alert-danger">
            {errors.map((error) => (
              <div key={'error_' + error}>{error}</div>
            ))}
        </div>
      )}
    </>
  )
}

export default Errors;
