import styled from 'styled-components'

export const Icon = styled.div`
    width : 50px;
    display : inline-block;
    text-align : center;
    font-size : 30px;
    background : ${props => props.background ?? '#f47d44' };
    border-radius : 0.7rem;
    color : white;
    margin-right : 10px;
    cursor : pointer;
`