import { createAction, createSlice } from '@reduxjs/toolkit'
import { notify } from '../../utils/utility'
const initialState = {
    isLoading: false,
    // errors: [],
    successMessages: []
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        successMesagesCleared: (state) => {
            state.successMessages = []
        },
        successMessagesOccured: (state, action) => {
            state.successMessages = action.payload
        },
        errorsCleared: (state) => {
            if (state.errors?.length)
                state.errors = []
        },
        showNotification: (state, action) => {
            notify(action.payload[1], action.payload[0])
        },
        loadingStarted: (state) => {
            state.isLoading = true
        },
        loadingStopped: (state) => {
            state.isLoading = false
        },
    },
})

export const { loadingStarted, loadingStopped, showNotification, errorsCleared, successMesagesCleared, successMessagesOccured } = appSlice.actions

export const appReady = createAction('app/ready');
export const pageChanged = createAction('app/change');

export default appSlice.reducer