import {React, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faEdit, faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import {useSelector, useDispatch} from "react-redux";
import {deleteSchool, fetchSchools} from "../../redux/slices/schoolSlice";
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import {useTranslation} from "react-i18next";

const SchoolList = () => {
  const [t, ] = useTranslation();
  const dispatch = useDispatch();
  const trash = <FontAwesomeIcon icon={faTrash}/>;
  const edit = <FontAwesomeIcon icon={faEdit}/>;
  const plus = <FontAwesomeIcon icon={faPlus}/>;
  const search = <FontAwesomeIcon icon={faSearch}/>;

  const schools = useSelector(state => state.school.schools)
  const page = useSelector(state => state.school.page)
  const totalItems = useSelector(state => state.school.totalItems)

  const [searchBox, setSearchBox] = useState(0);
  const[searchData,setSearchData] = useState("")

  useEffect(() => {
    let data = {page}
    if(searchData) {
      data['searchContent'] = searchData;
    }
    dispatch(fetchSchools(data))
  }, [page, dispatch,searchData])

  const deleteSchoolClicked = (id) => {
    const doDelete = window.confirm(t("Are you sure that you want to delete this school?"));
    if (doDelete) {
      dispatch(deleteSchool(id))
    }
  };

  const searchHandler = () => {
    setSearchBox(searchBox === 0 ? 1 : 0);
    setSearchData("");
  };

  return (
    <div className="container padding-80">
      <div className="card">
        <div className="card-header text-center">
          <div className="row align-items-center">
            <div className="col-3"></div>
            <div className="col-6">
              <h4 className="mb-0">{t('School')}</h4>
            </div>
            <div className="col-3 text-right">
              <button type="button" className="btn btn-sm mr-2" onClick={searchHandler}>{search}</button>
              <Link to="/schools/create">
                <button className="btn btn-sm plus-button">{plus}</button>
              </Link>
            </div>
          </div>

        </div>
        { searchBox !== 0 &&
          <div className={`col-12 searchbox ${searchBox && "py-2"} fade-in-left`} style={{height: searchBox && "auto", transform: `scale(${searchBox})`, transition: "transform 0.5s ease"}}>
            <input type="text" className="w-100 background-white" value={searchData} onChange={(e) => setSearchData(e.target.value)} placeholder={t("Zoeken")} />
          </div>
        }
        <div className="card-body">
          <table className="table table-hover">
            <thead>
            <tr>{(['Name', 'Place', 'Actions']).map((h) => (
              <th key={h}>{t(h)}</th>
            ))}</tr>
            </thead>
            <tbody>
              {(schools || []).map((school) => (
                <tr key={'school-'+school.id}>
                  <td><Link to={'schools/'+school.id}>{school.name}</Link></td>
                  <td>{school.city}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger ml-auto btn-action"
                      onClick={() => deleteSchoolClicked(school.id)}
                    >
                      {trash}
                    </button>
                    <Link to={`/schools/edit/${school.id}`}>
                      <button
                        className="btn btn-sm btn-secondary ml-1 btn-action"
                      >
                        {edit}
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="cardPagination">
            <Pagination
              activePage={page}
              itemClass="page-item"
              linkClass="page-link"
              itemsCountPerPage={10}
              totalItemsCount={totalItems}
              pageRangeDisplayed={3}
              onChange={(page) => {dispatch(fetchSchools({page, searchContent: searchData}))}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolList;
