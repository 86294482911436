import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { publishLesson } from '../../redux/slices/lessonSlice';

function PublishLesson({ setIsVisible = () => {}, lessonId }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(publishLesson({ lessonId : lessonId}));
    setIsVisible(false);
  };

  return (
    <div className="p-3">
      <h6 className="modal-title d-flex justify-content-center">
        {t(`Are you sure you want to publish this lesson?`)}
      </h6>
      <div className="col-12 mt-3">
        <Button className="float-right bg-danger" onClick={() => setIsVisible(false)}>{t("No")}</Button>

        <Button className="float-right mr-2" onClick={() => handleSubmit()}>{t("Yes")}</Button>
      </div>
    </div>
  );
}
export default PublishLesson;
