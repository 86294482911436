export const componentName ={
    subject : "subject",
    targetGroup : "targetGroup",
    discipline : "discipline",
    coreDiscipline : "coreDiscipline",
    worldsOfEngineering : "worldsOfEngineering",
    betaMentalitie : "betaMentalitie",
    professionalOrientation : "professionalOrientation",
    wtCoreSubjective : "wtCoreSubjective",
    centurySkill : "centurySkill",
    theme : "theme",
    grade: "grade",
}

export const taxonomiesRoutes = {
    subject : "/taxonomies/subjects",
    targetGroup : "/taxonomies/targetGroups",
    discipline : "/taxonomies/disciplines",
    coreDiscipline : "/taxonomies/coreDisciplines",
    worldsOfEngineering : "/taxonomies/worldsOfEngineerings",
    betaMentalitie : "/taxonomies/betaMentalities",
    professionalOrientation : "/taxonomies/professionalOrinatations",
    wtCoreSubjective : "/taxonomies/wtCoreSubjectives",
    centurySkill : "/taxonomies/centurySkills",
    theme : "/taxonomies/themes",
    grade: "/taxonomies/grades"
}

export const endpointName = {
    subject : "subjects",
    targetGroup : "target-groups",
    discipline : "disciplines",
    coreDiscipline : "core-disciplines",
    worldsOfEngineering : "worlds-of-engineering",
    betaMentalitie : "beta-mentalities",
    professionalOrientation : "professional-orientations",
    wtCoreSubjective : "wt-core-subjectives",
    centurySkill : "21st-century-skills",
    theme : "themes",
    grade: "grades",
}

export const heading = {
    subject : "Subjects",
    targetGroup : "Target Groups",
    discipline : "Disciplines",
    coreDiscipline : "Core Disciplines",
    worldsOfEngineering : "Worlds Of Engineering",
    betaMentalitie : "Beta Mentalities",
    professionalOrientation : "Professional Orientations",
    wtCoreSubjective : "WT Core Subjectives",
    centurySkill : "Century Skills",
    theme : "Themes",
    grade: "Grades",
}

export const subHeading = {
    subject : "Subject",
    targetGroup : "Target Group",
    discipline : "Discipline",
    coreDiscipline : "Core Discipline",
    worldsOfEngineering : "World Of Engineering",
    betaMentalitie : "Beta Mentality",
    professionalOrientation : "Professional Orientation",
    wtCoreSubjective : "WT Core Subjective",
    centurySkill : "Century Skill",
    theme : "Theme",
    grade: "Grades",
}
