import React from "react";
import { useTranslation } from "react-i18next";
if(process.env.REACT_APP_STYLE === "style-1"){
  require("../../scss/footer.scss");
}else{
  require("../../scss2/footer.scss");
}

function Footer() {
  const [t] = useTranslation();
  return (
    <div className="container-fluid footer px-0">
      <div className="row">
        <div className="col-md-4 padding-25 first">
          <div className="footer-col-container">
            <h4 className="mb-4">{t("Meer informatie")}</h4>
            <p>{t("Footer.description")}</p>
            <a href="/" className="btn btn-primary sto-btn-lg btn-action">
              {t("Contact")}
            </a>
          </div>
        </div>
        <div className="col-md-4 padding-25 second">
          <div className="row">
            <div className="col-12">
              <h4 className="mb-4">{t("Creëren van ervaringen")}</h4>
              <p>{t("Footer.description1")}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 padding-25 third">
          <h4 className="mb-4">{t("Nieuwsbrief")}</h4>
          <p>{t("Footer.description2")}</p>
        </div>
        <div className="col-md-4 offset-md-4 copyright"></div>
      </div>
    </div>
  );
}

export default Footer;
