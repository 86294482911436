import i18n from "i18next";
import defaultLang from "./defaultLang";
import LanguageDetector from "i18next-browser-languagedetector";
import { nl, en } from "./index";
const options = {
  debug: false,
  resources: {
    nl: {
      general: nl,
    },
    en: {
      general: en,
    },
  },
  fallbackNS: "general",

  detection: {
    order: ["cookie", "localStorage", "defaultLang"],
  },
};
const languageDetector = new LanguageDetector();
languageDetector.addDetector(defaultLang);
i18n.use(languageDetector).init(options);

export default i18n;

export const formatDate = (date) => {
  try {
    const dateStr = date.length > 10 ? date : date + "T00:00:00";
    const dateObj = new Date(dateStr);
    return new Intl.DateTimeFormat(i18n.language).format(dateObj);
  } catch (e) {
    return date;
  }
};
