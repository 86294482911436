import React from 'react';
import List from '../component/list';
import {componentName} from '../component/Constant';

const ProfessionalOrientationList = () => {
    return (
        <List componentName={componentName.professionalOrientation}/>
    )
}

export default ProfessionalOrientationList
