import React from 'react';
import List from '../component/list';
import { componentName } from '../component/Constant';

const BetaMentalitiesList = () => {
    return (
        <List componentName={componentName.betaMentalitie}/>
    )
}

export default BetaMentalitiesList
