import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { finishGradeLesson, fetchGrades } from "../../redux/slices/lessonSlice";

function FinishGradeModal({ setIsVisible = () => {}, lessonId }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [grade, setGrade] = useState("");
  const [description, setDescription] = useState("");
  const grades = useSelector(state => state.lesson.grades);

  useEffect(() => {
    dispatch(fetchGrades());
  }, [dispatch]);

  const handleSubmit = () => {
    if(grade){
      const formData = { 
        grade : grade, 
        grade_comment : description
      }
      dispatch(finishGradeLesson({lessonId : lessonId, formData : formData }))
      resetStates();
    }
  };

  const resetStates = () => {
    setGrade("");
    setDescription("");
    setIsVisible(false);
  };

  return (
    <div className="p-3">
      <h4 className="modal-title d-flex justify-content-center text-capitalize">
        {t(`Finish lesson and give overall rating`)}
      </h4>
      <hr />
      <div className="row mt-3">
        <div className="col-8 offset-2 mb-4">
          <label htmlFor="grade" className="col-form-label">
            {t("Grade")}
          </label>
          <select
            id="grade"
            className="form-control "
            name="grade"
            value={grade}
            onChange={(e) => setGrade(e.target.value)}
          >
            <option value="">{t("Select Grade")}</option>
            {(grades || []).map(grade => {
              return(
                <option value={grade.id}>{grade.name}</option>
              )
            })}
          </select>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-8 offset-2 mb-2">
          <label htmlFor="description" className="col-form-label">
            {t("Grade Description")}
          </label>
          <textarea
            id="description"
            type="text"
            className="form-control "
            grade="description"
            required
            value={description}
            rows="4"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <Button onClick={resetStates}>{t("Close")}</Button>

        <Button onClick={() => handleSubmit()}>{t("Submit")}</Button>
      </div>
    </div>
  );
}
export default FinishGradeModal;
