import { all, call } from 'redux-saga/effects'
import authSaga from './authSaga'
import schoolSaga from "./schoolSaga";
import classSaga from "./classSaga";
import userSaga from "./userSaga";
import pupilSaga from './pupilSaga';
import teacherSaga from './teacherSaga';
import lessonSaga from './lessonSaga';
import taxonomieSaga from './taxonomieSaga';
import moderateSaga from './moderateSaga';
import lessonSubmissionSaga from "./lessonSubmissionSaga";

function* rootSagas() {
    yield all([
        call(authSaga),
        call(schoolSaga),
        call(classSaga),
        call(userSaga),
        call(pupilSaga),
        call(teacherSaga),
        call(lessonSaga),
        call(lessonSubmissionSaga),
        call(taxonomieSaga),
        call(moderateSaga)
    ])
}

export default rootSagas
