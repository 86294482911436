import React from 'react';
import List from '../component/list';
import {componentName} from '../component/Constant';

const CoreDisciplinesList = () => {
    return (
        <List componentName={componentName.coreDiscipline}/>
    )
}

export default CoreDisciplinesList
